import {connect} from 'react-redux'
import MainMenu from '../components/MainMenu'


export const NewMainMenu = connect (
  //null,
  state=>({
    SchoolName : state.SchoolName,
    ClassName : state.ClassName,
    UserName : state.UserName,
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    Answers : state.Answers,
    CurrentZIndex : state.CurrentZIndex,
    Tasks: state.Tasks,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    RegFlag: state.RegFlag,
    Tests: state.Tests,
    User: state.User,
  }),
  dispatch => ({
   
  })
) (MainMenu)