import {connect} from 'react-redux'
import Stat from '../components/Stat'


export const NewStat = connect (
  
  state=>({
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    CurrentZIndex : state.CurrentZIndex,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StartFlag: state.StartFlag,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    User: state.User,
    Tests: state.Tests,
    PersonsRoles: state.PersonsRoles,
    PersonsChar: state.PersonsChar,
    ActivePagination: state.ActivePagination,
    ActiveTabPane: state.ActiveTabPane,
    CharComplete: state.CharComplete,
    PersonsTab: state.PersonsTab,
    Words: state.Words,
    ShowModalFormsAdmin: state.ShowModalFormsAdmin,
    CityTypes: state.CityTypes,
    Districts: state.Districts,
    SchoolTypes: state.SchoolTypes,
    Ages: state.Ages,
    FiltersDS: state.FiltersDS,
    BuildFilterDS: state.BuildFilterDS,
    FiltersCS: state.FiltersCS,
    BuildFilterCS: state.BuildFilterCS,
    FiltersTS: state.FiltersTS,
    BuildFilterTS: state.BuildFilterTS,
  }),
  dispatch => ({

    async onGetFetchPVG(url) {
      let newPVG, i, json, pvg
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        pvg=await response.json()
        newPVG=[]
        for (i=0;i<pvg.length;i++)
          newPVG.push(pvg[i])
        dispatch({type : 'GET_FETCH_PVG', PVG: newPVG})
      }
    },

    async onGetFetchFiltersDS(url) {
      let json, newFiltersDS=[], i, j, filtersDS, filter, stat, tempRPVG=[], tempRV=[]
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        filtersDS= await response.json()
        //console.log(filtersDS)
        for(i=0;i<filtersDS.length;i++){
          tempRPVG=[]
          if(filtersDS[i].status!==null){
            if (filtersDS[i].body===null) stat=filtersDS[i].body; 
            else {stat=JSON.parse(filtersDS[i].body)
              //console.log(stat)
              //stat_by_role_and_personal_value_group сделать двумерный массив
              //ценностных категорий 14 штук
              for(j=0;j<stat.stat_by_role_and_personal_value_group.length;j+=this.PVG.length) {
                tempRPVG.push(stat.stat_by_role_and_personal_value_group.filter(t => 
                  t.role ===stat.stat_by_role_and_personal_value_group[j].role))
              }
              stat.stat_by_role_and_personal_value_group=tempRPVG
              //stat_by_role_and_voluation сделать двумерный массив
              //портретов 6 штук
              for(j=0;j<stat.stat_by_role_and_voluation.length;j+=6) {
                tempRV.push(stat.stat_by_role_and_voluation.filter(t => 
                  t.role_name===stat.stat_by_role_and_voluation[j].role_name))
              }
              stat.stat_by_role_and_voluation=tempRV
            }
            if (filtersDS[i].params==="{}") filter=filtersDS[i].params; 
            else filter=JSON.parse(filtersDS[i].params)
            newFiltersDS.push({code:filtersDS[i].code,filter:filter,
              status:filtersDS[i].status,stat:stat,
              err:filtersDS[i].err,collapse:false})
          }
        }
        dispatch({type : 'CRUD_FILTERS_DS', FiltersDS: newFiltersDS})
      }
    },

    async onGetFetchFiltersTS(url) {
      let json, newFiltersTS=[], i, j, filtersTS, filter, stat, tempArrOfObj=[], str=""
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        filtersTS= await response.json()
        //console.log(filtersTS)
        for(i=0;i<filtersTS.length;i++){
          if(filtersTS[i].status!==null){
            if (filtersTS[i].body===null) stat=filtersTS[i].body; 
            else {stat=JSON.parse(filtersTS[i].body)
              //console.log(stat)
              //преобразование данных в массив объектов
              for(let key in stat){
                tempArrOfObj=[]
                if(key==="Ценностная оценка в восприятии социальных категорий"||
                   key==="Частота каждой категории ценностной оценки"||
                   key==="Частота каждой категории эмоционайльной направленности"||
                   key==="Частота каждой социальной категории"||
                   key==="Эмоциональная направленность в восприятии социальных категорий") {
                  //console.log(key)
                  //stat[key]
                  for(let key2 in stat[key]){
                    str=""
                    //console.log(key2)
                    //console.log(stat[key][key2])
                    for(j=0;j<stat[key][key2].length;j++){
                      str+=stat[key][key2][j].join(" - ")+"; "
                    }
                    //console.log(str)
                    tempArrOfObj.push({name:key2,list:str})
                  }
                  //console.log(tempArrOfObj)
                  //stat[key]=tempArrOfObj
                }else {
                  //console.log(key)
                  str=""
                  for(j=0;j<stat[key].length;j++){
                    str=stat[key][j].join(" - ")+"; "
                    tempArrOfObj.push({list:str})
                  }
                }
                stat[key]=tempArrOfObj
              }
            }
            if (filtersTS[i].params==="{}") filter=filtersTS[i].params; 
            else filter=JSON.parse(filtersTS[i].params)
            newFiltersTS.push({code:filtersTS[i].code,filter:filter,
              status:filtersTS[i].status,stat:stat,
              err:filtersTS[i].err,collapse:false})
          }
        }
        dispatch({type : 'CRUD_FILTERS_TS', FiltersTS: newFiltersTS})
      }
    },

    async onGetFetchFiltersCS(url) {
      let json, newFiltersCS=[], i, filtersCS, filter, stat
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        filtersCS= await response.json()
        //console.log(filtersCS)
        for(i=0;i<filtersCS.length;i++){
          //console.log(i)
          if(filtersCS[i].status!==null){
            if (filtersCS[i].body===null) stat=filtersCS[i].body; 
            else {stat=JSON.parse(filtersCS[i].body)
              //console.log(stat)
              stat.values.map((value)=>{
                value.corr_value=Number(value.corr_value.toFixed(2))
              })
              //console.log(stat)
            }
            if (filtersCS[i].params==="{}") filter=filtersCS[i].params; 
            else filter=JSON.parse(filtersCS[i].params)
            newFiltersCS.push({code:filtersCS[i].code,filter:filter,
              status:filtersCS[i].status,stat:stat,
              err:filtersCS[i].err,collapse:false})
          }
        }
        dispatch({type : 'CRUD_FILTERS_CS', FiltersCS: newFiltersCS})
      }
    },

    async onPostFetchFiltersDS(url,data) {
      //console.log(data)
      let json,code, newFiltersDS=[], i
      let response=await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        code= await response.json()
        for(i=0;i<this.FiltersDS.length;i++)
          newFiltersDS.push(this.FiltersDS[i])
        newFiltersDS.push({code:code.code,filter:data,status:"created",stat: null,err:null,collapse:false})
        dispatch({type : 'CRUD_FILTERS_DS', FiltersDS: newFiltersDS})
        //console.log(code)
      }
    },

    async onPostFetchFiltersTS(url,data) {
      //console.log(data)
      let json,code, newFiltersTS=[], i
      let response=await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        code= await response.json()
        for(i=0;i<this.FiltersTS.length;i++)
          newFiltersTS.push(this.FiltersTS[i])
        newFiltersTS.push({code:code.code,filter:data,status:"created",stat: null,err:null,collapse:false})
        dispatch({type : 'CRUD_FILTERS_TS', FiltersTS: newFiltersTS})
        //console.log(code)
      }
    },

    async onPostFetchFiltersCS(url,data) {
      //console.log(data)
      let json,code, newFiltersCS=[], i
      let response=await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        code= await response.json()
        console.log(code)
        for(i=0;i<this.FiltersCS.length;i++)
          newFiltersCS.push(this.FiltersCS[i])
        newFiltersCS.push({code:code.code,filter:data,status:"created",stat: null,err:null,collapse:false})
        dispatch({type : 'CRUD_FILTERS_CS', FiltersCS: newFiltersCS})
        //console.log(code)
      }
    },

    onUpdateFiltersDS(response){
      let newFiltersDS=[], i, j, stat, tempRPVG=[], tempRV=[]
      for(i=0;i<this.FiltersDS.length;i++){
        newFiltersDS.push(this.FiltersDS[i])
        if(newFiltersDS[i].code===response.stat_task.code){
          newFiltersDS[i].err=response.stat_task.err;
          newFiltersDS[i].status=response.stat_task.status;
          //newFiltersDS[i].stat=JSON.parse(response.stat_task.body);
          if(response.stat_task.err===""){
            stat=JSON.parse(response.stat_task.body);
            //console.log(stat)
            //stat_by_role_and_personal_value_group сделать двумерный массив
            //ценностных категорий 14 штук
            for(j=0;j<stat.stat_by_role_and_personal_value_group.length;j+=this.PVG.length) {
              tempRPVG.push(stat.stat_by_role_and_personal_value_group.filter(t => 
                t.role ===stat.stat_by_role_and_personal_value_group[j].role))
            }
            //console.log(tempRPVG)
            stat.stat_by_role_and_personal_value_group=tempRPVG
            //stat_by_role_and_voluation сделать двумерный массив
            //портретов 6 штук
            for(j=0;j<stat.stat_by_role_and_voluation.length;j+=6) {
              tempRV.push(stat.stat_by_role_and_voluation.filter(t => 
                t.role_name===stat.stat_by_role_and_voluation[j].role_name))
            }
            stat.stat_by_role_and_voluation=tempRV
            //console.log(stat)
            newFiltersDS[i].stat=stat
          }
        }
      }
      //console.log(newFiltersDS)
      dispatch({type : 'CRUD_FILTERS_DS', FiltersDS: newFiltersDS})
    },

    onUpdateFiltersTS(response){
      let newFiltersTS=[], i, j, stat, tempArrOfObj=[], str=""
      for(i=0;i<this.FiltersTS.length;i++){
        newFiltersTS.push(this.FiltersTS[i])
        if(newFiltersTS[i].code===response.stat_task.code){
          newFiltersTS[i].err=response.stat_task.err;
          newFiltersTS[i].status=response.stat_task.status;
          if(response.stat_task.err===""){
            stat=JSON.parse(response.stat_task.body);
             //преобразование данных в массив объектов
            for(let key in stat){
              //console.log(key)
              tempArrOfObj=[]
              if(key==="Ценностная оценка в восприятии социальных категорий"||
                 key==="Частота каждой категории ценностной оценки"||
                 key==="Частота каждой категории эмоционайльной направленности"||
                 key==="Частота каждой социальной категории"||
                 key==="Эмоциональная направленность в восприятии социальных категорий") {
            
                for(let key2 in stat[key]){
                  str=""
                  for(j=0;j<stat[key][key2].length;j++){
                    str+=stat[key][key2][j].join(" - ")+"; "
                  }
                  tempArrOfObj.push({name:key2,list:str})
                }
                //stat[key]=tempArrOfObj
              } else {
                console.log(key)
                str=""
                for(j=0;j<stat[key].length;j++){
                  str=stat[key][j].join(" - ")+"; "
                  tempArrOfObj.push({list:str})
                }
              }
              stat[key]=tempArrOfObj
            }
            console.log(stat)
            newFiltersTS[i].stat=stat
          }
        }
      }
      //console.log(newFiltersDS)
      dispatch({type : 'CRUD_FILTERS_TS', FiltersTS: newFiltersTS})
    },

    onUpdateFiltersCS(response){
      let newFiltersCS=[], i, stat
      //console.log(this.FiltersCS)
      for(i=0;i<this.FiltersCS.length;i++){
        newFiltersCS.push(this.FiltersCS[i])
        if(newFiltersCS[i].code===response.stat_task.code){
          newFiltersCS[i].err=response.stat_task.err;
          newFiltersCS[i].status=response.stat_task.status;
          if(response.stat_task.err===""){
            stat=JSON.parse(response.stat_task.body);
            console.log(stat)
            stat.values.map((value)=>{
              value.corr_value=Number(value.corr_value.toFixed(2))
            })
            newFiltersCS[i].stat=stat
          }
        }
      }
      //console.log(newFiltersCS)
      //CRUD_FILTERS_CS
      dispatch({type : 'CRUD_FILTERS_CS', FiltersCS: newFiltersCS})
    },

    async onGetFetchCityTypes(url) {
      let json,cityTypes
      cityTypes= []
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        cityTypes= await response.json()
        dispatch({type : 'CRUD_CITYTYPES', CityTypes: cityTypes})
      }
    },

    async onGetFetchDistricts(url) {
      let json,districts
      districts= []
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        districts= await response.json()
        dispatch({type : 'CRUD_DISTRICTS', Districts: districts})
      }
    },

    async onGetFetchSchoolTypes(url) {
      let json,schoolTypes
      schoolTypes= []
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        schoolTypes= await response.json()
        dispatch({type : 'CRUD_SCHOOLTYPES', SchoolTypes: schoolTypes})
      }
    },

    async onGetFetchSchools(url) {
      let newSchools, i, schools, json
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        schools= await response.json()
        newSchools=[]
        
        for(i=0;i<schools.length;i++) {
          newSchools.push(schools[i])
        }
        dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:[]})
      }
    },


    async onGetFetchStudentGroups(url) {
      let newStudentGroups, i, json, studentGroups
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        studentGroups= await response.json()
        newStudentGroups=[]
          for (i=0;i<studentGroups.length;i++) {
            newStudentGroups.push(studentGroups[i]);
          }
        //if(studentGroups.length>0) return studentGroups[0].id
        dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
          tempStudentGroups:[]})
        //if(newStudentGroups.length>0) return newStudentGroups[0].id
      }
    },

    async onGetFetchCities(url) {
      let newCities, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(cities=> {
        newCities=new Array(cities.length)
        for (i=0;i<cities.length;i++)
        newCities[i]=cities[i]
        dispatch({type : 'GET_FETCH_CITIES', Cities: newCities})
        //this.onGetFetchSchools(process.env.REACT_APP_URL+"/schools",newCities);
      })
      }
      })
    },

    async onUpdateBuildFilter(filter) {
      //console.log(this.StudentGroups)
      dispatch({type : 'BUILD_FILTER_DS', BuildFilterDS:filter})
    },

    async onUpdateBuildFilter2(filter) {
      dispatch({type : 'BUILD_FILTER_TS', BuildFilterTS:filter})
    },

    async onUpdateBuildFilter3(filter) {
      dispatch({type : 'BUILD_FILTER_CS', BuildFilterCS:filter})
    },

    onChangeCollapseFilterDS(code,collapse) {
      let newFiltersDS=[], i
      for(i=0;i<this.FiltersDS.length;i++){
        newFiltersDS.push(this.FiltersDS[i])
        if(newFiltersDS[i].code===code){
          newFiltersDS[i].collapse=collapse;
        }
      }
      dispatch({type : 'CRUD_FILTERS_DS', FiltersDS: newFiltersDS})
    },

    onChangeCollapseFilterTS(code,collapse) {
      let newFiltersTS=[], i
      for(i=0;i<this.FiltersTS.length;i++){
        newFiltersTS.push(this.FiltersTS[i])
        if(newFiltersTS[i].code===code){
          newFiltersTS[i].collapse=collapse;
        }
      }
      dispatch({type : 'CRUD_FILTERS_TS', FiltersTS: newFiltersTS})
    },

    onChangeCollapseFilterCS(code,collapse) {
      let newFiltersCS=[], i
      for(i=0;i<this.FiltersCS.length;i++){
        newFiltersCS.push(this.FiltersCS[i])
        if(newFiltersCS[i].code===code){
          newFiltersCS[i].collapse=collapse;
        }
      }
      dispatch({type : 'CRUD_FILTERS_CS', FiltersCS: newFiltersCS})
    },
   
  })
) (Stat)
