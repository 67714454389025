import {connect} from 'react-redux'
import Psychologist from '../components/Psychologist'


export const NewPsychologist = connect (
  state=>({
    SchoolName : state.SchoolName,
    ClassName : state.ClassName,
    UserName : state.UserName,
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    Answers : state.Answers,
    CurrentZIndex : state.CurrentZIndex,
    Tasks: state.Tasks,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    RegFlag: state.RegFlag,
    Tests: state.Tests,
    User: state.User,

  }),
  dispatch => ({

    async onGetFetchSchools(url) {
      let newSchools, i, schools, json
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })

      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
      } else {
        schools=await response.json()
        newSchools=[]
        for(i=0;i<schools.length;i++) newSchools.push(schools[i])
        dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:[]})
      }
      return 1
    },
   
    onGetFetchStudentGroups(url) {
      let newStudentGroups, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
          response.json().then(studentGroups=> {
          newStudentGroups=[]
          for (i=0;i<studentGroups.length;i++) {
            newStudentGroups.push(studentGroups[i]);
          }
          dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
          tempStudentGroups:[]})
          this.onGetFetchTests(process.env.REACT_APP_URL+"/tests",newStudentGroups);
          })
        }
      })
    },

    /*onGetFetchStudentGroups(url) {
      var newStudentGroups
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          return -1//Promise.resolve(-1)
        } else return response;
      })
      .then(response=>response.json())
      .then(studentGroups=> {
        newStudentGroups=[]
        for (var i=0;i<studentGroups.length;i++) {
          newStudentGroups.push(studentGroups[i]);
        }
        dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
          tempStudentGroups:[]})
        this.onGetFetchTests(process.env.REACT_APP_URL+"/tests",newStudentGroups);
        return 1
      })
    },*/

    onGetFetchTests(url,studentGroups) {
      let newTests,i, start,finish
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
          response.json().then(tests=>{
          newTests=[]
          for(i=0;i<tests.length;i++) {
            start=new Date(tests[i].start)
            finish=new Date(tests[i].finish)
            newTests.push({id:tests[i].id,
              student_group_id:tests[i].student_group_id,
              start:start.toLocaleDateString().substring(6,10)+"-"+
                start.toLocaleDateString().substring(3,5)+"-"+
                start.toLocaleDateString().substring(0,2)+"T"+
                start.toLocaleTimeString().substring(0,2)+":"+
                start.toLocaleTimeString().substring(3,5),
              finish:finish.toLocaleDateString().substring(6,10)+"-"+
                finish.toLocaleDateString().substring(3,5)+"-"+
                finish.toLocaleDateString().substring(0,2)+"T"+
                finish.toLocaleTimeString().substring(0,2)+":"+
                finish.toLocaleTimeString().substring(3,5),
              student_group:studentGroups.find(t=>t.id===tests[i].student_group_id).name,
              school:this.Schools.find(t=>t.id===studentGroups.find(t=>t.id===tests[i].student_group_id).school_id).name,
              collapse: false,
              result: null,
            })
          }
          dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
          })
        }
      })
    },

    /*onGetFetchTests(url,studentGroups) {
      let newTests,i, start,finish
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        return response;
      })
      .then(response=>response.json())
      .then(tests=> {
        newTests=[]
        for (i=0;i<tests.length;i++) {
          start=new Date(tests[i].start)
          finish=new Date(tests[i].finish)
          newTests.push({id:tests[i].id,
              student_group_id:tests[i].student_group_id,
              //start:tests[i].start.substring(0,16),
              start:start.toLocaleDateString().substring(6,10)+"-"+
                start.toLocaleDateString().substring(3,5)+"-"+
                start.toLocaleDateString().substring(0,2)+"T"+
                start.toLocaleTimeString().substring(0,2)+":"+
                start.toLocaleTimeString().substring(3,5),
              //finish:tests[i].finish.substring(0,16),
              finish:finish.toLocaleDateString().substring(6,10)+"-"+
                finish.toLocaleDateString().substring(3,5)+"-"+
                finish.toLocaleDateString().substring(0,2)+"T"+
                finish.toLocaleTimeString().substring(0,2)+":"+
                finish.toLocaleTimeString().substring(3,5),
              student_group:studentGroups.find(t=>t.id===tests[i].student_group_id).name,
              school:this.Schools.find(t=>t.id===studentGroups.find(t=>t.id===tests[i].student_group_id).school_id).name,
              collapse: false,
              result: null,
          })
        }
        dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
      })
  },*/

    async onChangeCollapseTest(test_id,collapse) {
      var newTests,tempHRG, tempAR
      let i,j, result=null
      newTests=[]
      for (i=0;i<this.Tests.length;i++)
        {
          newTests.push(this.Tests[i]);
          if(newTests[i].id===test_id) 
          {
            newTests[i].collapse=collapse;
            if(collapse) {
              result=await this.onGetFetchTestResult(process.env.REACT_APP_URL+"/tests/"+
                String(test_id)+"/result",test_id);
              tempHRG=[]
              tempAR=[]
              if(result!==null) {
                result.hist_roles.map((value)=>{
                  value.generally=Number((value.generally*100).toFixed(2))
                  value.indicate_procent=Number((value.indicate_procent*100).toFixed(2))
                  value.not_indicate_procent=Number((value.not_indicate_procent*100).toFixed(2))
                  return value
                })
                result.pair_roles.map((value)=>{
                  value.eq_percent=Number((value.eq_percent*100).toFixed(2))
                  value.lt_percent=Number((value.lt_percent*100).toFixed(2))
                  value.gt_percent=Number((value.gt_percent*100).toFixed(2))
                  return value
                })
                result.hist_personal_value_groups.map((value)=>{
                  value.value=Number((value.value*100).toFixed(2))
                  value.indicate_percent=Number((value.indicate_percent*100).toFixed(2))
                  value.not_indicate_percent=Number((value.not_indicate_percent*100).toFixed(2))
                  return value
                })
                result.corr_terminal_instrumental_values.map((value)=>{
                  value.frequency=Number((value.frequency*100).toFixed(2))
                  return value
                })
                result.hist_self_esteems.map((value)=>{
                  value.percent=Number((value.percent*100).toFixed(2))
                  return value
                })
                //ценностных категорий 14 штук
                for(j=0;j<result.hist_roles_groups.length;j+=this.PVG.length) {
                  tempHRG.push(result.hist_roles_groups.filter(t => 
                    t.role ===result.hist_roles_groups[j].role))
                  tempHRG[j/this.PVG.length].map((value)=>{value.value=Number((value.value*100).toFixed(2))})
                }
                result.hist_roles_groups=tempHRG
                // идеалы, лучше меня ...антиидеалы 6 штук
                for(j=0;j<result.authority_roles.length;j+=6) {
                  tempAR.push(result.authority_roles.filter(t => 
                    t.role_name ===result.authority_roles[j].role_name))
                  tempAR[j/6].map((value)=>{value.value=Number((value.value*100).toFixed(2))})
                }
                result.authority_roles=tempAR

              }
              //console.log(result)
              newTests[i].result=result;
            }
          }
        }
      dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
    },

    async onGetFetchTestResult(url/*,test_id*/) {
      let newResult, json
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        newResult=null
      }
      else {
        newResult=await response.json()
      }
      return newResult
    },

    onPostFetchTest(url,data) {
      let newTests,i,start,finish
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
          response.json().then(test=>{
          newTests=[]
          start=new Date(test.start)
          finish=new Date(test.finish)
          for (i=0;i<this.Tests.length;i++)
            newTests.push(this.Tests[i]);
          newTests.push({id:test.id,student_group_id:test.student_group_id,
            start:start.toLocaleDateString().substring(6,10)+"-"+
              start.toLocaleDateString().substring(3,5)+"-"+
              start.toLocaleDateString().substring(0,2)+"T"+
              start.toLocaleTimeString().substring(0,2)+":"+
              start.toLocaleTimeString().substring(3,5),
            finish:finish.toLocaleDateString().substring(6,10)+"-"+
              finish.toLocaleDateString().substring(3,5)+"-"+
              finish.toLocaleDateString().substring(0,2)+"T"+
              finish.toLocaleTimeString().substring(0,2)+":"+
              finish.toLocaleTimeString().substring(3,5),
            student_group:this.StudentGroups.find(t=>t.id===test.student_group_id).name,
            collapse: false, result: null
          })
          dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
        })
        }
      })
    },

    /*onPostFetchTest(url,data) {
      let newTests,i,start,finish
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        return response;
      })
      .then(response=>response.json())
      .then(test=> {
        newTests=[]
        start=new Date(test.start)
        finish=new Date(test.finish)
        for (i=0;i<this.Tests.length;i++)
          newTests.push(this.Tests[i]);
        newTests.push({id:test.id,student_group_id:test.student_group_id,
          start:start.toLocaleDateString().substring(6,10)+"-"+
            start.toLocaleDateString().substring(3,5)+"-"+
            start.toLocaleDateString().substring(0,2)+"T"+
            start.toLocaleTimeString().substring(0,2)+":"+
            start.toLocaleTimeString().substring(3,5),
          finish:finish.toLocaleDateString().substring(6,10)+"-"+
            finish.toLocaleDateString().substring(3,5)+"-"+
            finish.toLocaleDateString().substring(0,2)+"T"+
            finish.toLocaleTimeString().substring(0,2)+":"+
            finish.toLocaleTimeString().substring(3,5),
          student_group:this.StudentGroups.find(t=>t.id===test.student_group_id).name,
          collapse: false, result: null
        })
        dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
      
      })
    },*/

    onUpdateFetchTest(url,data) {
      let newTests//, test
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
          response.json().then(test=>{
          //test=response.json()
          newTests=[]
          for (var i=0;i<this.Tests.length;i++)
          {
            newTests.push(this.Tests[i]);
            if(newTests[i].id===test.id) 
            {newTests[i].student_group_id=test.student_group_id;
              newTests[i].start=test.start.substring(0,16);   
              newTests[i].finish=test.finish.substring(0,16);
              newTests[i].student_group=this.StudentGroups.find(t=>t.id===test.student_group_id).name;
            }
          }
          dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
        })
        }
      })
    },

    /*onUpdateFetchTest(url,data) {
      var newTests
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        return response;
      })
      .then(response=>response.json())
      .then(test=> {
        newTests=[]
        for (var i=0;i<this.Tests.length;i++)
          {
            newTests.push(this.Tests[i]);
            if(newTests[i].id===test.id) 
            {newTests[i].student_group_id=test.student_group_id;
              newTests[i].start=test.start.substring(0,16);   
              newTests[i].finish=test.finish.substring(0,16);
              newTests[i].student_group=this.StudentGroups.find(t=>t.id===test.student_group_id).name;
            }
          }
        dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
      
      })
    },*/

    onDeleteFetchTest(url,data) {
      let newTests, i
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
          newTests=[]
          for(i=0;i<this.Tests.length;i++)
          {
            newTests.push(this.Tests[i])
          }
          newTests=newTests.filter(t => t.id !==data.id)
          dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
          return response
        }
      })
    },

    /*onDeleteFetchTest(url,data) {
      var newTests
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        newTests=[]
        for (var i=0;i<this.Tests.length;i++)
          {
            newTests.push(this.Tests[i])
          }
        newTests=newTests.filter(t => t.id !==data.id)
        dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
        return response;
      })
    },*/

    onGetFetchPVG(url) {
      let newPVG, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(pvg=> {
        newPVG=[]
        for (i=0;i<pvg.length;i++)
          newPVG.push(pvg[i])
        dispatch({type : 'GET_FETCH_PVG', PVG: newPVG})
        })
        }
      })
    },

    /*onGetFetchCharteristics(url,pvg) {
      var newCharteristics
      //console.log(url);
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
        .then(response=> {
          //console.log(response);
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
            //throw new Error (response.statusText)
          }
          return response;
        })
        .then(response=>response.json())
        .then(charteristics=> {
          newCharteristics=[]//new Array()
          for (var i=0;i<charteristics.length;i++)
          {
            newCharteristics.push({id:charteristics[i].id, name:charteristics[i].name,
              positivity:charteristics[i].positivity,
              personal_value_group_id:charteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===charteristics[i].positivity).name,
              pvg:pvg.find(t=>t.id===charteristics[i].personal_value_group_id).name})
          }
          dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newCharteristics})
        })
    },*/

    /*onPostFetchСharacteristic(url,data) {
      var newСharacteristics
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        return response;
      })
      .then(response=>response.json())
      .then(charteristic=> {
        newСharacteristics=[]//new Array()
        for (var i=0;i<this.Сharacteristics.length;i++)
          {
            newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
              positivity:this.Сharacteristics[i].positivity,
              personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
              pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
          }
          newСharacteristics.push({id:charteristic.id, name:charteristic.name,
          positivity:charteristic.positivity,
          personal_value_group_id:charteristic.personal_value_group_id,
          positivity_str:this.Positivity.find(t=>t.value===charteristic.positivity).name,
          pvg:this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name});
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
    },*/

    /*onUpdateFetchСharacteristic(url,data) {
      var newСharacteristics
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        return response;
      })
      .then(response=>response.json())
      .then(charteristic=> {
        newСharacteristics=[]//new Array()
        
        for (var i=0;i<this.Сharacteristics.length;i++)
          {
            newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
              positivity:this.Сharacteristics[i].positivity,
              personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
              pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
            if(newСharacteristics[i].id===charteristic.id) 
            {newСharacteristics[i].name=charteristic.name;
              newСharacteristics[i].positivity=charteristic.positivity;
              newСharacteristics[i].personal_value_group_id=charteristic.personal_value_group_id;
              newСharacteristics[i].positivity_str=this.Positivity.find(t=>t.value===charteristic.positivity).name;
              newСharacteristics[i].pvg=this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name;                
            }
          }
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
    },*/

    /*onDeleteFetchСharacteristic(url,data) {
      var newСharacteristics
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        newСharacteristics=[]
        for (var i=0;i<this.Сharacteristics.length;i++)
        {
          newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
            positivity:this.Сharacteristics[i].positivity,
            personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
            positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
            pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
        }
        newСharacteristics=newСharacteristics.filter(t => t.id !==data.id)
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
        return response;
      })
    },*/

    onMarkedRole(role_name, not_indicate) {
      let listRoleName=["старше","не старше","родители","однокласники/студенты","работники школы(вуза)"]
      if(listRoleName.find(t=>t===role_name)!==undefined&&not_indicate!==0)
        return "*"
      return ""
    },

    onGetTextForMarkedRole(test_index){
      let str=[],i
      for(i=0;i<this.Tests[test_index].result.hist_roles.length;i++){
        if(this.Tests[test_index].result.hist_roles[i].role_name==="родители"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str.push("* Отсутствие упоминания родителей может быть признаком конфликтов с родителями у этих учащихся или отсутствие у них родителей")
        if(this.Tests[test_index].result.hist_roles[i].role_name==="однокласники/студенты"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str.push("* Отсутствие упоминания одноклассников может быть признаком неблагоприятного классного климата для этих учащихся")
        if(this.Tests[test_index].result.hist_roles[i].role_name==="работники школы(вуза)"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str.push("* Отсутствие упоминания работников школы (образовательного учреждения) может быть признаком неблагоприятной учебной среды для этих учащихся")
        if(this.Tests[test_index].result.hist_roles[i].role_name==="старше"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str.push("* Отсутствие упоминания старших по возрасту является признаком отчуждения этих учащихся от взрослого поколения")
        if(this.Tests[test_index].result.hist_roles[i].role_name==="не старше"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str.push("* Отсутствие упоминания ровесников или младших является признаком неблагоприятных отношений со сверстниками у этих учащихся")
      }
      if(str.length!==0) return str
      else return null
    },

    /*
    onGetTextForMarkedRole(test_index){
      let str="* Признаки: ",i
      for(i=0;i<this.Tests[test_index].result.hist_roles.length;i++){
        if(this.Tests[test_index].result.hist_roles[i].role_name==="родители"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str+="конфликтов с родителями у этих учащихся или отсутствие родителей, "
        if(this.Tests[test_index].result.hist_roles[i].role_name==="однокласники/студенты"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str+="неблагоприятного классного климата для этих учащихся, "
        if(this.Tests[test_index].result.hist_roles[i].role_name==="работники школы(вуза)"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str+="неблагоприятной школьной среды для этих учащихся, "
        if(this.Tests[test_index].result.hist_roles[i].role_name==="старше"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str+="отчуждения этих учащихся от взрослого поколения, "
        if(this.Tests[test_index].result.hist_roles[i].role_name==="не старше"&&
          this.Tests[test_index].result.hist_roles[i].not_indicate!==0)
          str+="неблагоприятных отношений со сверстниками у этих учащихся, "
      }
      if(str!=="* Признаки: ") {
        return str.substring(0,str.length-2)+"."
      }
      else return null
    },
     */

    onGetTextForRole(test_index) {
      let result={max:" ",middle:" ",min:" "}
      let i, count=0,max_gen
      let temp=[]
      temp=this.Tests[test_index].result.hist_roles.filter(t=>t.generally>=10)
      temp=temp.sort((a,b)=>b.generally-a.generally)
      //console.log(temp)
      if(temp.length>0) {
        max_gen=temp[0].generally
        result.max+=temp[0].role_name+" ("+temp[0].generally+"%), "
        count+=1
      }
      for(i=1;i<temp.length;i++) {
        if(count<3) {
          result.max+=temp[i].role_name+" ("+temp[i].generally+"%), "
          if(temp[i].generally!==max_gen) count+=1
        }
      }
      for(i=0;i<this.Tests[test_index].result.hist_roles.length;i++){
        if(this.Tests[test_index].result.hist_roles[i].generally===0)
          result.min+=this.Tests[test_index].result.hist_roles[i].role_name+", "
        if(this.Tests[test_index].result.hist_roles[i].generally>0&&
          this.Tests[test_index].result.hist_roles[i].generally<10&&
          this.Tests[test_index].result.hist_roles[i].role_name!=="родители")
            result.middle+=this.Tests[test_index].result.hist_roles[i].role_name+" ("+
              this.Tests[test_index].result.hist_roles[i].generally+"%), "
      }
      if(result.max===" ")result.max=" не определены"
      else result.max=result.max.substring(0,result.max.length-2)
      if(result.min===" ")result.min=" не определены"
      else result.min=result.min.substring(0,result.min.length-2)
      if(result.middle===" ")result.middle=" не определены"
      else result.middle=result.middle.substring(0,result.middle.length-2)
      return result
    },

    onGetTextForTable6(test_index){
      let result={max:"",less5:"",greater_equal75:"не определены",less50:"не определены"}
      let temp=[], i, temp2=[]
      temp=this.Tests[test_index].result.hist_personal_value_groups.slice()
      temp.sort((a,b)=>b.value-a.value)
      //console.log(temp)
      result.max+=temp[0].group_human_name+" ("+String(temp[0].value)+" %), "
      result.max+=temp[1].group_human_name+" ("+String(temp[1].value)+" %), "
      result.max+=temp[2].group_human_name+" ("+String(temp[2].value)+" %). "
      temp2=temp.filter(t=>t.indicate_percent>=75)
      if(temp2.length>0) {
        result.greater_equal75=""
        for(i=0;i<temp2.length;i++) {
          if(temp2[i].group_human_name!=="Неосознанные ценности")
            result.greater_equal75+=temp2[i].group_human_name+" ("+String(temp2[i].indicate_percent)+" %)"
          if(i!==temp2.length-1&&temp2[i].group_human_name!=="Неосознанные ценности") 
            result.greater_equal75+=", "
          else result.greater_equal75+=" "
        }
      }
      temp2=temp.filter(t=>t.indicate_percent<50)
      if(temp2.length>0) {
        result.less50=""
        for(i=0;i<temp2.length;i++) {
          result.less50+=temp2[i].group_human_name+" ("+String(temp2[i].indicate_percent)+" %)"
          if(i!==temp2.length-1) result.less50+=", "
        }
      }
      temp2=temp.filter(t=>t.value<5)
      if(temp2.length>0) {
        result.less5="Практически не актуальны в данной учебной группе ценности: "
        for(i=0;i<temp2.length;i++) {
          result.less5+=temp2[i].group_human_name//+" ("+String(temp2[i].value)+" %)"
          if(temp2[i].value>0)result.less5+=" ("+String(temp2[i].value)+" %)"
          if(i===temp2.length-1)result.less5+="."
          else result.less5+=", "
        }
      }
      return result
    },

    onGetTextForTable3(test_index){
      let i, j, hr=[], hrg=[], phrase, base_role, count=0
      let result={max:[],base:[]}
      let str_temp=""
      hr=this.Tests[test_index].result.hist_roles.slice()
      hr.sort((a,b)=>{return b.generally-a.generally})
      hrg=this.Tests[test_index].result.hist_roles_groups.slice()
      phrase=["являются образцом ценностей:","описываются как носители ценностей:",
        "воплощают ценности:","имеют атрибуты ценностей:"]
      base_role=["однокласники/студенты","работники школы(вуза)","старше","не старше","родители"]
      for(i=0;i<hrg.length;i++) {
        hrg[i]=hrg[i].slice()
        hrg[i].sort((a,b)=>{return b.value-a.value})
      }
      for(i=0;i<3;i++){
        str_temp=""
        if(hr[i].generally>0){
          str_temp+=hr[i].role_name/*+" "+phrase[((Math.random()*100).toFixed(0)%4)]*/+": "
          hrg.map((hrgi)=>{
            if(hrgi[0].role===hr[i].role_name)
            for(j=0;j<2;j++) {
              if(hrgi[j].value>0) {
                str_temp+=hrgi[j].personal_value_group+" ("+hrgi[j].value+" %), "
              }
            }
          })
          str_temp=str_temp.substring(0,str_temp.length-2)+"; "
          result.max.push(str_temp)
        }
      }
      base_role=base_role.filter(tt => {
        count=0
        for(i=0;i<result.max.length;i++)
         if(result.max[i].indexOf(tt)===-1) count++;
        if(count===result.max.length) return true
        else return false
      })
      base_role.map((br)=>{
        str_temp=""
        for(i=0;i<hr.length;i++){
         
          if(br===hr[i].role_name&&hr[i].generally>0){
            str_temp+=hr[i].role_name/*+" "+phrase[((Math.random()*100).toFixed(0)%4)]*/+": "
            hrg.map((hrgi)=>{
              if(hrgi[0].role===hr[i].role_name)
              for(j=0;j<2;j++) {
                if(hrgi[j].value>0&&hrgi[j].personal_value_group!=="недифференцированная оценка") {
                  str_temp+=hrgi[j].personal_value_group+" ("+hrgi[j].value+" %), "
                }
              }
            })
            str_temp=str_temp.substring(0,str_temp.length-2)+"; "
            result.base.push(str_temp)
          }
        }
      })
      return result
    },

    /*
    onGetTextForTable3(test_index){
      let i, j, hr=[], hrg=[], phrase, base_role
      let result={max:"",base:""}
      hr=this.Tests[test_index].result.hist_roles.slice()
      hr.sort((a,b)=>{return b.generally-a.generally})
      hrg=this.Tests[test_index].result.hist_roles_groups.slice()
      phrase=["являются образцом ценностей:","описываются как носители ценностей:",
        "воплощают ценности:","имеют атрибуты ценностей:"]
      base_role=["однокласники/студенты","работники школы(вуза)","старше","не старше","родители"]
      for(i=0;i<hrg.length;i++) {
        hrg[i]=hrg[i].slice()
        hrg[i].sort((a,b)=>{return b.value-a.value})
      }
      for(i=0;i<3;i++){
        if(hr[i].generally>0){
          result.max+=hr[i].role_name+" "+phrase[((Math.random()*100).toFixed(0)%4)]+" "
          hrg.map((hrgi)=>{
            if(hrgi[0].role===hr[i].role_name)
            for(j=0;j<2;j++) {
              if(hrgi[j].value>0) {
                result.max+=hrgi[j].personal_value_group+" ("+hrgi[j].value+" %), "
              }
            }
          })
          result.max=result.max.substring(0,result.max.length-2)+"; "
        }
      }
      base_role.map((br)=>{
        for(i=0;i<hr.length;i++){
          if(br===hr[i].role_name&&result.max.indexOf(br)===-1&&hr[i].generally>0){
            result.base+=hr[i].role_name+" "+phrase[((Math.random()*100).toFixed(0)%4)]+" "
            hrg.map((hrgi)=>{
              if(hrgi[0].role===hr[i].role_name)
              for(j=0;j<2;j++) {
                if(hrgi[j].value>0) {
                  result.base+=hrgi[j].personal_value_group+" ("+hrgi[j].value+" %), "
                }
              }
            })
            result.base=result.base.substring(0,result.base.length-2)+"; "
          }
        }
      })
      return result
    },
     */

    onGetTextForTable4(test_index){
      let ar=[]
      let result=[]
      result["идеалы"]=""
      result["лучше меня"]=""
      result["равноценные мне"]=""
      result["хуже меня"]=""
      result["антиидеалы"]=""
      result["не влияет"]=""
      ar=this.Tests[test_index].result.authority_roles.slice()
      ar.forEach((ari)=>{
        ari=ari.slice()
        ari.sort((a,b)=>{return b.value-a.value})
        if(ari[0].value>0&&ari[0].value>(ari[1].value+20)){
          result[ari[0].valuation_profile_name]+=ari[0].role_name+", "
        }
        if(ari[0].value>0&&(ari[0].value-ari[1].value)<10&&(ari[0].value-ari[1].value)>0){
          result["не влияет"]+=ari[0].role_name+", "
        }
      })
      if(result["идеалы"]!=="") result["идеалы"]="Представители групп: "+
        result["идеалы"].substring(0,result["идеалы"].length-2)+
        ` значимо чаще воспринимаются как "идеалы".`
      if(result["лучше меня"]!=="") result["лучше меня"]="Представители групп: "+
        result["лучше меня"].substring(0,result["лучше меня"].length-2)+
        ` значимо чаще воспринимаются как "лучше меня".`
      if(result["равноценные мне"]!=="") 
        result["равноценные мне"]="Представители групп: "+
        result["равноценные мне"].substring(0,result["равноценные мне"].length-2)+
        ` значимо чаще воспринимаются как "равноценные мне".`
      if(result["хуже меня"]!=="") result["хуже меня"]="Представители групп: "+
        result["хуже меня"].substring(0,result["хуже меня"].length-2)+
        ` значимо чаще воспринимаются как "хуже меня".`
      if(result["антиидеалы"]!=="") result["антиидеалы"]="Представители групп: "+
        result["антиидеалы"].substring(0,result["антиидеалы"].length-2)+
        ` значимо чаще воспринимаются как "антиидеалы".`

      if(result["не влияет"]!=="") result["не влияет"]="Принадлежность к группам: "+
        result["не влияет"].substring(0,result["не влияет"].length-2)+
        " не влияет на их ценностную оценку."
      
      return result
    },

    onGetTextForTable6A(test_index){
      let temp=[], result=""
      temp=this.Tests[test_index].result.corr_terminal_instrumental_values.slice()
      temp.sort((a,b)=>{return b.frequency-a.frequency})
      result=`В данной группе преобладает категория "${temp[0].valuation_profile_name}"
        и минимальное значение имеет категория "${temp[temp.length-1].valuation_profile_name}"`
      return result
    },

  })
) (Psychologist)
