import {connect} from 'react-redux'
import Authorization from '../components/Authorization'


export const NewAuthorization = connect (
  //null,
  state=>({
    SchoolName : state.SchoolName,
    ClassName : state.ClassName,
    UserName : state.UserName,
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    Answers : state.Answers,
    CurrentZIndex : state.CurrentZIndex,
    Tasks: state.Tasks,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    RegFlag: state.RegFlag,
    Tests: state.Tests,
    User: state.User,
  }),
  dispatch => ({
   
    onDeleteFetchAuth(url) {
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        }
        //привести хранилище к initialstate
        dispatch({type : 'DELETE_FETCH_USER'})
        
        return response;
      })
    },

    async onPostFetchAuth(url,data) {
      var AccessToken, UID, Client, newUser
      let json,user
      let response=await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',},
        body: JSON.stringify(data),
      })

      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        AccessToken=response.headers.get("access-token")
        UID=response.headers.get("uid")
        Client=response.headers.get("client")
        user= await response.json()
        if(user.type==="Psychologist"||user.type==="SuperPsychologist")
          newUser={id:user.id,login: user.login, validateToken: true,
            type:user.type,school_id: user.school_id,accessToken:AccessToken,uid:UID,client:Client}
        if(user.type==="StudentUser")newUser={id:user.id,login: user.login,validateToken: true,
          type:user.type,student_id: user.student_id,accessToken:AccessToken,uid:UID,client:Client}
        if(user.type==="Admin")newUser={id:user.id,login: user.login,validateToken: true,
          type:user.type,accessToken:AccessToken,uid:UID,client:Client}
        if(user.type==="SchoolPsychologist"||user.type==="SchoolAdmin")
          newUser={id:user.id,login: user.login, validateToken: true, type:user.type,
            school_id: user.school_id,accessToken:AccessToken,uid:UID,client:Client}
        dispatch({type : 'GET_FETCH_USER', User: newUser})
        return 1
      }
    },
   
  })
) (Authorization)