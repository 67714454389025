import {connect} from 'react-redux'
//import React from 'react';
//import {Redirect} from 'react-router-dom'
import LoadTest from '../components/LoadTest'

export const NewLoadTest = connect (
  state=>({
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    CurrentZIndex : state.CurrentZIndex,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StartFlag: state.StartFlag,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    User: state.User,
    Tests: state.Tests,
    PersonsRoles: state.PersonsRoles,
    PersonsChar: state.PersonsChar,
    ActivePagination: state.ActivePagination,
    ActiveTabPane: state.ActiveTabPane,
    CharComplete: state.CharComplete,
    PersonsTab: state.PersonsTab,
    Words: state.Words,
  }),
  dispatch => ({

    onGetFetchStudentGroups(url) {
      let newStudentGroups, i, newUser={}
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          if(response.status===403) {
            Object.assign(newUser,this.User)
            newUser.validateToken=false
            dispatch({type : 'GET_FETCH_USER', User: newUser})
          }
        } else {
        response.json().then(studentGroups=> {
        newStudentGroups=[]
        for(i=0;i<studentGroups.length;i++) {
          newStudentGroups.push(studentGroups[i]);
        }
        dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
          tempStudentGroups:[]})
        this.onGetFetchStudent(process.env.REACT_APP_URL+"/students/"+String(this.User.student_id),
          newStudentGroups);
        })
      }
      })
    },
   
    onGetFetchStudent(url,studentgroups) {
      let newUser={}
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          if(response.status===403) {
            Object.assign(newUser,this.User)
            newUser.validateToken=false
            dispatch({type : 'GET_FETCH_USER', User: newUser})
          }
        } else {
        response.json().then(student=> {
        this.onGetFetchTests(process.env.REACT_APP_URL+"/tests?filterrific[with_student_id]="+
          String(this.User.student_id)+"&filterrific[active]=1",studentgroups)
      })
      }
      })
    },

    onGetFetchTests(url,studentgroups) {
      let newTests, i, start,finish, newUser={}
        fetch(url,{
          headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
            if(response.status===403) {
              Object.assign(newUser,this.User)
              newUser.validateToken=false
              dispatch({type : 'GET_FETCH_USER', User: newUser})
            }
          } else {
         response.json().then(tests=> {
          newTests=[]
          for (i=0;i<tests.length;i++) {
            start=new Date(tests[i].start)
            finish=new Date(tests[i].finish)
            newTests.push({id:tests[i].id,
                student_group_id:tests[i].student_group_id,
                start:start.toLocaleDateString().substring(6,10)+"-"+
                  start.toLocaleDateString().substring(3,5)+"-"+
                  start.toLocaleDateString().substring(0,2)+"T"+
                  start.toLocaleTimeString().substring(0,2)+":"+
                  start.toLocaleTimeString().substring(3,5),
                finish:finish.toLocaleDateString().substring(6,10)+"-"+
                  finish.toLocaleDateString().substring(3,5)+"-"+
                  finish.toLocaleDateString().substring(0,2)+"T"+
                  finish.toLocaleTimeString().substring(0,2)+":"+
                  finish.toLocaleTimeString().substring(3,5),
                student_group:studentgroups.find(t=>t.id===tests[i].student_group_id).name,
            })
          }
          dispatch({type : 'GET_FETCH_TESTS', Tests: newTests})
        })
        }
        })
    },

    onGetFetchStudentTest(url) {
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(student_test=> {
        dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: student_test})
      })
      }
      })
    },

    /*onGetFetchStudentTestTemp(url) {
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(student_test=> {
        //dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: student_test})
        console.log(student_test)
      })
      }
      })
    },*/

    onPostFetchStudentTest(url,data) {
      let newStudentTest, newUser={}
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
            if(response.status===403) {
              Object.assign(newUser,this.User)
              newUser.validateToken=false
              dispatch({type : 'GET_FETCH_USER', User: newUser})
            }
        } else {
        response.json().then(student_test=> {
        newStudentTest=[]
        newStudentTest.push(student_test)
        dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: newStudentTest,StartFlag: true})
        })
        }
      })
    },

    async onUpdateFetchStudentTest(url,data) {
      let newStudentTest, newUser={}
      let json, student_test
      let response=await fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      if(!response.ok) {
        json= await response.json()
        if(response.status===403) {
          Object.assign(newUser,this.User)
          newUser.validateToken=false
          dispatch({type : 'GET_FETCH_USER', User: newUser})
        }
        alert(json.errors.join("\n"))
      }
      else
      {
        student_test= await response.json()
        //console.log("student_test")
        //console.log(student_test)
        newStudentTest=[]
        newStudentTest.push(student_test)
        dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: newStudentTest,StartFlag: this.StartFlag})
      }
      return Promise.resolve(1)
    },

   
    onSetCurrentPersonId(PersonId) {
     
      dispatch({type : 'SET_CURRENT_PERSON_ID', CurrentPersonId: PersonId})
     
    },

    async onPostFetchPerson(url,data,personID) {
      
      var tempPersonRole,tempPersonChar,i
      tempPersonRole=[]
      tempPersonChar=[]
      let json,person
      let response=await fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
             
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
      }
      else
      {
        person= await response.json()
        if(data.name!=="Я") {
          tempPersonRole=this.PersonsRoles.filter(t => t.person_id ===personID)
          tempPersonChar=this.PersonsChar.filter(t => t.person_id ===personID)
          for(i=0;i<tempPersonRole.length;i++)
          {
            //console.log(String("PersonRole №"+String(i+1)))
            await this.PostFetchPersonRole(process.env.REACT_APP_URL+"/person_roles",
              {role_id: tempPersonRole[i].role_id, person_id: person.id});
          }
          for(i=0;i<tempPersonChar.length;i++)
          {
            //console.log(String("PersonChar №"+String(i+1)))
            await this.PostFetchPersonChar(process.env.REACT_APP_URL+"/person_characteristics",
              {characteristic_id:tempPersonChar[i].characteristic_id,person_id:person.id});
          }
        }
      }
   
      return Promise.resolve(1)
    },

    async PostFetchPersonRole(url,data) {
     
      let json,person_role
      let response=await fetch(url, {
         method: 'POST',
         mode: 'cors',
         credentials: 'same-origin',
         headers: {'Content-Type': 'application/json',
           'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
         },
         body: JSON.stringify(data),
       })
      
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
      }else {
        person_role= await response.json()
      }
      return Promise.resolve(1)
    },

    async PostFetchPersonChar(url,data) {
    
    await fetch(url, {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
      },
      body: JSON.stringify(data),
    })
    .then(response=> {
      if(!response.ok) {
        response.json().then(response=>{alert(response.errors.join("\n"));})
      } else {
      response.json().then(person_char=> {
      return 1
      })
      }
    })
   },

    onPostPerson(data) {
      //console.log(data);
      let newPersons, idPerson, i, newPersonsTab
        newPersons=[]
        newPersonsTab=[]
        if(this.Persons.length===0) idPerson=1
        else idPerson=this.Persons[this.Persons.length-1].id+1 
        for (i=0;i<this.Persons.length;i++) {
          newPersons.push(this.Persons[i])
          newPersonsTab.push(this.PersonsTab[i])
        }
        newPersons.push({id:idPerson, name:data.name, x:data.x, y:data.y, 
          zIndex: this.CurrentZIndex, color:data.color})
        newPersonsTab.push({id:idPerson, name:data.name})
        newPersonsTab.sort((a,b)=>{return b.id-a.id})
        dispatch({type : 'GET_FETCH_PERSON', Persons: newPersons,CurrentZIndex: this.CurrentZIndex+1,
          PersonsTab: newPersonsTab})
        this.onChangeTabPane("#testing/link"+String(idPerson-1))
    },

    onUpdatePerson(data) {
      let i,newPersons,newPersonsChar,newPersonsRoles
      newPersons=[]
      newPersonsChar=[]
      newPersonsRoles=[]
      for (i=0;i<this.Persons.length;i++) {
        newPersons.push(this.Persons[i])
        if(newPersons[i].id===data.id) 
          newPersons[i].name=data.name;
      }
      for (i=0;i<this.PersonsRoles.length;i++) {
        newPersonsRoles.push(this.PersonsRoles[i])
        if(newPersonsRoles[i].person_id===data.id) 
          newPersonsRoles[i].person=data.name;
      }
      for (i=0;i<this.PersonsChar.length;i++) {
        newPersonsChar.push(this.PersonsChar[i])
        if(newPersonsChar[i].person_id===data.id) 
          newPersonsChar[i].person=data.name;
      }
      dispatch({type : 'DELETE_FETCH_PERSON', Persons: newPersons,CurrentZIndex: this.CurrentZIndex,
        PersonsRoles:newPersonsRoles,PersonsChar:newPersonsChar,CurrentPersonId:this.CurrentPersonId}) 
    },

    onDeletePerson(data) {
      let newPersons,newPersonsChar,newPersonsRoles,currentPersonId, i
      newPersons=[]
      newPersonsChar=[]
      newPersonsRoles=[]
      for (i=0;i<this.Persons.length;i++) {
        newPersons.push(this.Persons[i])
        if (newPersons[i].zIndex>data.zIndex) {newPersons[i].zIndex=newPersons[i].zIndex-1}
      }
      newPersons=newPersons.filter(t => t.id !==data.id)
      newPersonsRoles=this.PersonsRoles.filter(t => t.person_id !==data.id)
      newPersonsChar=this.PersonsChar.filter(t => t.person_id !==data.id)
      if(this.CurrentPersonId===data.id)currentPersonId=-1
      dispatch({type : 'DELETE_FETCH_PERSON', Persons: newPersons,CurrentZIndex: this.CurrentZIndex-1,
        PersonsRoles:newPersonsRoles,PersonsChar:newPersonsChar,CurrentPersonId:currentPersonId})
    },

    onChangeZIndexPerson(zIndex) {
      //console.log("curzindex="+this.CurrentZIndex.toString(10))
      //console.log("zIndexAnswer="+zIndex.toString(10))
      var newPersons, tempIndex
      newPersons=[]
      for (var i=0;i<this.Persons.length;i++) {
        newPersons.push(this.Persons[i])
        if (newPersons[i].zIndex==zIndex) {tempIndex=i;newPersons[i].zIndex=-1;}
        if (newPersons[i].zIndex>zIndex) {newPersons[i].zIndex=newPersons[i].zIndex-1}
      }
      //console.log(newPersons[tempIndex])
      newPersons[tempIndex].zIndex=this.CurrentZIndex-1
      dispatch({type : 'CHANGE_ZINDEX_PERSON', Persons: newPersons})
    },

    onChangeColor(x) {
      let newPersons, i
      newPersons=[]
      for (i=0;i<this.Persons.length;i++) {
        newPersons.push(this.Persons[i])
        if (newPersons[i].zIndex===(this.CurrentZIndex-1)) {
          if(i===0) {
            if(x<=1) newPersons[i].color="red";
            if(x>1) newPersons[i].color="firebrick";
          } else {
            if(x<=1) newPersons[i].color="lime";
            if(x>1) newPersons[i].color="gray";
          } 
        }
      }
      dispatch({type : 'CHANGE_POSITION', Persons: newPersons})
    },

    onChangePosition(x,y) {
      let newPersons, i
      newPersons=[]
      for (i=0;i<this.Persons.length;i++) {
        newPersons.push(this.Persons[i])
        if (newPersons[i].zIndex===(this.CurrentZIndex-1)) {
          if(x>0.85&&x<=1)newPersons[i].x=0.85
          else if(x>1&&x<1.15)newPersons[i].x=1.15
          else newPersons[i].x=x;
          //if(i===0&&x>0.85)newPersons[i].x=0.85
          newPersons[i].y=y;
        }
      }
      dispatch({type : 'CHANGE_POSITION', Persons: newPersons})
    },

    async onGetFetchRoles(url) {
    
      let newRoles, i, newUser={}
      let json, roles
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        if(response.status===403) {
          Object.assign(newUser,this.User)
          newUser.validateToken=false
          dispatch({type : 'GET_FETCH_USER', User: newUser})
        }
        alert(json.errors.join("\n"))
        return -1
      } else {
        roles=await response.json()
        newRoles=[]
        for (i=0;i<roles.length;i++) 
          newRoles.push(roles[i])
        dispatch({type : 'GET_FETCH_ROLES', Roles: newRoles})
      }
    },

    /*
     onGetFetchRoles(url) {
      //console.log("--1")
      let newRoles, i, newUser
      //let status=1
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          //console.log("--2",response)
          if(response.status===403) {
            //console.log("--22",response.status);
            //status=response.status
            newUser=this.User
            newUser.validateToken=false
            console.log("--newUser",newUser)
            dispatch({type : 'GET_FETCH_USER', User: newUser})
          }
          response.json().then(response=>{
            //console.log("--3",response)
            alert(response.errors.join("\n"));
          })
          //return status
        } 
        else {
        response.json().then(roles=> {
        newRoles=[]
        for (i=0;i<roles.length;i++) 
          newRoles.push(roles[i])
        dispatch({type : 'GET_FETCH_ROLES', Roles: newRoles})
      })
      }
      })
      //return status
    },
     */

    async onGetFetchСharacteristics(url) {
      let newСharacteristics, i, newUser={}
      let json, characteristics
      let response=await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        if(response.status===403) {
          Object.assign(newUser,this.User)
          newUser.validateToken=false
          dispatch({type : 'GET_FETCH_USER', User: newUser})
        }
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        characteristics= await response.json()
        newСharacteristics=[]
        for(i=0;i<characteristics.length;i++) 
          newСharacteristics.push(characteristics[i])
        for(i=0;i<this.Words.length;i++)
          newСharacteristics.push({id:this.Words[i].id*(-1),name:this.Words[i].name,
            personal_value_group_id:null,positivity:null,rejected:null,resources:null,value:null})
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      }
    },

    /*onGetFetchСharacteristics(url) {
      let newСharacteristics, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
       response.json().then(characteristics=> {
        newСharacteristics=[]
        for(i=0;i<characteristics.length;i++) 
          newСharacteristics.push(characteristics[i])
        for(i=0;i<this.Words.length;i++)
          newСharacteristics.push({id:this.Words[i].id*(-1),name:this.Words[i].name,
            personal_value_group_id:null,positivity:null,rejected:null,resources:null,value:null})
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
       })
      }
      })
    },*/

    onPostPersonRole(data) {
      //console.log(data);
      let newPersonsRoles, idPersonsRoles, i
      newPersonsRoles=[]
      if(this.PersonsRoles.length===0) idPersonsRoles=1
      else idPersonsRoles=this.PersonsRoles[this.PersonsRoles.length-1].id+1 
      for (i=0;i<this.PersonsRoles.length;i++) newPersonsRoles.push(this.PersonsRoles[i])
      newPersonsRoles.push({id:idPersonsRoles, role_id:data.role_id,person_id:data.person_id,
        role:data.role,person:data.person})
      dispatch({type : 'GET_FETCH_PERSON_ROLE', PersonsRoles: newPersonsRoles})
    },

    onDeletePersonsRoles(data) {
      let newPersonsRoles, i
      //console.log(data)
      newPersonsRoles=[]
      for (i=0;i<this.PersonsRoles.length;i++) {
        newPersonsRoles.push(this.PersonsRoles[i])
      }
      newPersonsRoles=newPersonsRoles.filter(t => t.id !==data.id)
      dispatch({type : 'GET_FETCH_PERSON_ROLE', PersonsRoles: newPersonsRoles})
    },

    onPostPersonChar(data) {
      //console.log(data);
      let i, newPersonsChar, idPersonsChar
      newPersonsChar=[]
      if(this.PersonsChar.length===0) idPersonsChar=1
      else {//idPersonsChar=this.PersonsChar[this.PersonsChar.length-1].id+1
        idPersonsChar=this.PersonsChar[0].id+1
      } 
      for (i=0;i<this.PersonsChar.length;i++) newPersonsChar.push(this.PersonsChar[i])
      newPersonsChar.push({id:idPersonsChar, characteristic_id:data.characteristic_id,
        person_id:data.person_id,characteristic:data.characteristic,person:data.person})
      newPersonsChar.sort((a,b)=>{return b.id-a.id})
      dispatch({type : 'GET_FETCH_PERSON_CHAR', PersonsChar: newPersonsChar})
    },

    onDeletePersonsChar(data) {
     let newPersonsChar
     newPersonsChar=[]
      for (var i=0;i<this.PersonsChar.length;i++) {
        newPersonsChar.push(this.PersonsChar[i])
      }
      newPersonsChar=newPersonsChar.filter(t => t.id !==data.id)
      dispatch({type : 'GET_FETCH_PERSON_CHAR', PersonsChar: newPersonsChar})
    },

    async onGetStudentTestResult(url) {
      let newStudentTest,newUser={}
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          if(response.status===403) {
            Object.assign(newUser,this.User)
            newUser.validateToken=false
            dispatch({type : 'GET_FETCH_USER', User: newUser})
          }
        } else {
        response.json().then(result=> {
        newStudentTest=[]
        newStudentTest.push({id:this.StudentTest[0].id,test_id:this.StudentTest[0].test_id,
          end_dt:this.StudentTest[0].end_dt,
          start_dt:this.StudentTest[0].start_dt,
          student_id:this.StudentTest[0].student_id,student_test_result_id:result.id,test_result:result})
        //console.log(newStudentTest);
        dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: newStudentTest,StartFlag:this.StartFlag})
        return Promise.resolve(1)
      })
      }
      })
    },

    /*onGetStudentTestResult(url) {
      let newStudentTest
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        //console.log(response);
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(result=> {
        console.log(this.StudentTest);
        newStudentTest=[]
        newStudentTest.push({id:this.StudentTest[0].id,test_id:this.StudentTest[0].test_id,
          end_dt:this.StudentTest[0].end_dt,
          start_dt:this.StudentTest[0].start_dt,
          student_id:this.StudentTest[0].student_id,student_test_result_id:result.id,test_result:result})
        //console.log(newStudentTest);
        dispatch({type : 'GET_FETCH_STUDENT_TEST', StudentTest: newStudentTest,StartFlag:this.StartFlag})
        return Promise.resolve(1)
      })
      }
      })
    },*/


    onCheckPersons() {
      let i,j,count, messageErrors="",countInactivePersons=this.Persons.length
      let tempPersonChar=[]
      //console.log("-1-"+countInactivePersons)
      if(this.Persons.length===1)messageErrors+="Нет ни одного персонажа\n"
      for(i=0;i<this.Persons.length;i++) {
        count=0;tempPersonChar=[]
        if(i===0&&this.Persons[i].x>1) messageErrors+="Персонаж 'Я' необходимо разместить на шкале\n"
        if(this.Persons[i].x<1&&i!==0) {
          if(this.PersonsRoles.find(t=>t.person_id===this.Persons[i].id)===undefined) {
            messageErrors+=String("Для персонажа "+this.Persons[i].name+" нет ни одной роли\n")
          }
          if(this.PersonsChar.find(t=>t.person_id===this.Persons[i].id)===undefined) {
            messageErrors+=String("Для персонажа "+this.Persons[i].name+" нет ни одной характеристики\n")
          }
          //проверка на наличие  хотя бы одной "готовой" характеристики
          if(this.PersonsChar.find(t=>t.person_id===this.Persons[i].id)!==undefined) {
            tempPersonChar=this.PersonsChar.filter(t=>t.person_id===this.Persons[i].id)
            for(j=0;j<tempPersonChar.length;j++) {
              if(tempPersonChar[j].characteristic_id<0)count++
            }
            //console.log(this.Persons[i].id)
            //console.log(count)
            if(count===tempPersonChar.length)
              messageErrors+=String("Персонаж "+this.Persons[i].name+" содержит только характеристики, придуманные вами\n")
          }
        } else countInactivePersons-=1
      }
      //console.log("-2-"+countInactivePersons)
      if(countInactivePersons===0)messageErrors+="Нет ни одного активного персонажа\n"
      if(messageErrors!=="") {alert(messageErrors);return 0}
      return 1
    },

    /*async onEndTest(url1,url2) {
      let i
      //проверить персонажи на наличие хотя бы одной роли и одной характеристики
      if(this.onCheckPersons())
      {
        for(i=0;i<this.Persons.length;i++) {
          if(this.Persons[i].x<1)
            await this.onPostFetchPerson(url1,
              {name:this.Persons[i].name,x:this.Persons[i].x,
              y:this.Persons[i].y,student_test_id:this.StudentTest[0].id//проверить является ли [] 
              },this.Persons[i].id)
        }
        this.onGetStudentTestResult(url2)
      }
      return 1
    },*/

    async onEndTest2(url1/*,url2*/) {
      let i,r,c, PA=[], PRA=[], PCA=[],tempPRA=[], tempPCA=[]
      //проверить персонажи на наличие хотя бы одной роли и одной характеристики
      if(this.onCheckPersons())
      {
        for(i=0;i<this.Persons.length;i++) {
          if(this.Persons[i].x<1) {
            if(this.Persons[i].name!=="Я") {
              tempPRA=this.PersonsRoles.filter(t => t.person_id ===this.Persons[i].id)
              tempPCA=this.PersonsChar.filter(t => t.person_id ===this.Persons[i].id&&t.characteristic_id>0)
            }
            PRA=[]
            PCA=[]
            for(r=0;r<tempPRA.length;r++) PRA.push({role_id:tempPRA[r].role_id})
            for(c=0;c<tempPCA.length;c++) PCA.push({characteristic_id:tempPCA[c].characteristic_id})
            PA.push({name:this.Persons[i].name,x:this.Persons[i].x,y:this.Persons[i].y,
              person_roles_attributes:PRA,person_characteristics_attributes:PCA})
          }
        }
        //console.log(PA)
        await this.onUpdateFetchStudentTest(url1,{test_id:this.StudentTest[0].test_id,
          student_id:this.StudentTest[0].student_id,
          end_dt:new Date().toISOString().substring(0,11)+new Date().toLocaleTimeString().substring(0,5),
          persons_attributes:PA})
        //this.onGetStudentTestResult(url2)
        return 1
      }else return -1
    },

    async onStartNewTest() {
      await this.onGetFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups")
      dispatch({type : 'NEW_START_TEST'})
    },

    onChangePagination(newActivePagination) {
      let activeTabPane=""
      //activeTabPane=String("#testing/link"+String((newActivePagination-1)*9+newActivePagination))
      activeTabPane=String("#testing/link"+String((this.PersonsTab.length-1)-(newActivePagination-1)*10))
      dispatch({type : 'CHANGE_PAGINATION', ActivePagination:newActivePagination,
        ActiveTabPane:activeTabPane})
    },

    onChangeTabPane(activeTabPane) {
      dispatch({type : 'CHANGE_PAGINATION', ActivePagination:this.ActivePagination,
        ActiveTabPane:activeTabPane})
    },

    onChangeCheckedRole(checked,person_id,role_id) {
      let personRole
      //если checked, то хотим поставить галочку
      //если не checked, то хотим снять галочку
      //if(this.props.PersonsRoles.find(t=>t.person_id===person.id&&t.role_id===role.id)!==undefined)
      if(!checked) {
        personRole=this.PersonsRoles.find(t=>t.person_id===person_id&&t.role_id===role_id)
        this.onDeletePersonsRoles(personRole)
      }
      if(checked) {
        this.onPostPersonRole({
          role_id: role_id,
          person_id: person_id,
          role: this.Roles.find(t=>t.id===role_id).name,
          person: this.Persons.find(t=>t.id===person_id).name,
        })
      }
    },

    onChangeCheckedChar(checked,person_id,characteristic_id) {
      let personChar
      //если checked, то хотим поставить галочку
      //если не checked, то хотим снять галочку
      if(!checked) {
        personChar=this.PersonsChar.find(t=>t.person_id===person_id&&t.characteristic_id===characteristic_id)
        this.onDeletePersonsChar(personChar)
      }
      if(checked) {
        this.onPostPersonChar({
          characteristic_id: characteristic_id,
          person_id: person_id,
          characteristic: this.Сharacteristics.find(t=>t.id===characteristic_id).name,
          person: this.Persons.find(t=>t.id===person_id).name,
        })
      }
    },

    onChangeCharComplete(charComplete) {
      dispatch({type : 'CHAR_COMPLETE', CharComplete:charComplete})
    },

    onGetTextForResult1() {
      let result={_1_1:" ",_1_2:" ",_1_3:" ",_1_4:" "}
      if(this.StudentTest[0].test_result.first_part_result.older_people!==0) 
        result._1_1+="с более старшими ("+
          String(this.StudentTest[0].test_result.first_part_result.older_people)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.peers!==0)
        result._1_1+="с ровесниками ("+
          String(this.StudentTest[0].test_result.first_part_result.peers)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.male!==0)
        result._1_1+="с мужским полом ("+
          String(this.StudentTest[0].test_result.first_part_result.male)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.female!==0)
        result._1_1+="с женским полом ("+
          String(this.StudentTest[0].test_result.first_part_result.female)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.relatives!==0)
        result._1_1+="с семьей и родственниками ("+
          String(this.StudentTest[0].test_result.first_part_result.relatives)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.study_place!==0)
        result._1_1+="с местом учебы ("+
          String(this.StudentTest[0].test_result.first_part_result.study_place)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.animals!==0)
        result._1_1+="с животными ("+
          String(this.StudentTest[0].test_result.first_part_result.animals)+"%) /"
      
      if(this.StudentTest[0].test_result.first_part_result.real_comm!==0)
        result._1_2+="реальным общением ("+
          String(this.StudentTest[0].test_result.first_part_result.real_comm)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.inet_com!==0)
        result._1_2+="интернет-общением ("+
          String(this.StudentTest[0].test_result.first_part_result.inet_com)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.fict_chars!==0)
        result._1_2+="вымышленными героями ("+
          String(this.StudentTest[0].test_result.first_part_result.fict_chars)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.famous!==0)
        result._1_2+="известными людьми ("+
          String(this.StudentTest[0].test_result.first_part_result.famous)+"%) /"
      
      if(this.StudentTest[0].test_result.first_part_result.relatives!==0)
        result._1_3+="семья ("+
          String(this.StudentTest[0].test_result.first_part_result.relatives)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.friends!==0)
        result._1_3+="общение с друзьями ("+
          String(this.StudentTest[0].test_result.first_part_result.friends)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.study!==0)
        result._1_3+="учеба или профессиональное образование ("+
          String(this.StudentTest[0].test_result.first_part_result.study)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.sport!==0)    
        result._1_3+="спорт ("+
          String(this.StudentTest[0].test_result.first_part_result.sport)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.science!==0)    
        result._1_3+="наука ("+
          String(this.StudentTest[0].test_result.first_part_result.science)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.politics)
        result._1_3+="политика ("+
          String(this.StudentTest[0].test_result.first_part_result.politics)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.army!==0)
        result._1_3+="армия ("+
          String(this.StudentTest[0].test_result.first_part_result.army)+"%) /"
      if(this.StudentTest[0].test_result.first_part_result.art!==0)
        result._1_3+="искусство и литература ("+
          String(this.StudentTest[0].test_result.first_part_result.art)+"%) /"

      if(result._1_1===" ")result._1_1=" информация отсутствует"
      else result._1_1=result._1_1.substring(0,result._1_1.length-2)
      if(result._1_2===" ")result._1_2=" информация отсутствует"
      else result._1_2=result._1_2.substring(0,result._1_2.length-2)
      if(result._1_3===" ")result._1_3=" информация отсутствует"
      else result._1_3=result._1_3.substring(0,result._1_3.length-2)

      return result
    },
   
    async onPostFetchWords(url,data,person_id) {
      let newWords,i
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(word=> {
          newWords=[]
          for(i=0;i<this.Words.length;i++)newWords.push(this.Words[i])
          if(newWords.find(t=>t.id===word.id)!==undefined) {
            newWords=newWords.filter(t => t.id !==word.id)
          }
          newWords.push(word)
          dispatch({type : 'CRUD_WORDS', Words: newWords})
          this.onPostPersonChar({
            characteristic_id: word.id*(-1),
            person_id: person_id,
            characteristic: word.name,
            person: this.PersonsTab.find(t=>t.id===person_id).name,
          })
          this.onAddСharacteristics(word)
        })
        }
      })
    },

    onAddСharacteristics(word) {
      let newСharacteristics, i
      newСharacteristics=[]
      for(i=0;i<this.Сharacteristics.length;i++) 
        newСharacteristics.push(this.Сharacteristics[i])
      newСharacteristics.push({id:word.id*(-1),name:word.name,personal_value_group_id:null,
        positivity:null,rejected:null,resources:null,value:null})
      dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics}) 
    },

  })
) (LoadTest)
