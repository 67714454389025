import React from 'react';
import {/*Link,*/ NavLink/*, Route*/} from 'react-router-dom';
//import {FaBeer} from 'react-icons/fa';
//import 'bootstrap/dist/css/bootstrap.min.css';
import {MDBIcon} from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 


const selectedStyle = {
    backgroundColor: "white",
    color: "slategray"
}

export default class MainMenu extends React.Component {

  navLinkLogin() {
    let navlink
    if(this.props.User.length===0) 
      navlink=<NavLink to="/login" activeStyle={selectedStyle}>[Войти]</NavLink>
    else navlink=<NavLink to="/login" activeStyle={selectedStyle}>[Выйти]</NavLink>
    return (navlink);
  }

  navLinkAdmin(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(this.props.User.type==="Admin")
        navlink=<NavLink to="/admin" activeStyle={selectedStyle}>[Пользователи]</NavLink>
    }
    return (navlink);
  }

  navLinkDirectories(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(this.props.User.type==="SchoolAdmin")
        navlink=<NavLink to="/directories" activeStyle={selectedStyle}>[Классы]</NavLink>
      if(this.props.User.type==="Admin")
        navlink=<NavLink to="/directories" activeStyle={selectedStyle}>[Школы]</NavLink>
    }
    return (navlink);
  }

  navLinkExplorer(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(/*this.props.User.type==="Admin"||*/this.props.User.type==="SuperPsychologist")
          navlink=<NavLink to="/explorer" activeStyle={selectedStyle}>[Характеристики]</NavLink>
    }
    return (navlink);
  }

  navLinkExplorer2(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(/*this.props.User.type==="Admin"||*/this.props.User.type==="Psychologist")
        navlink=<NavLink to="/classification" activeStyle={selectedStyle}>[Новые слова]</NavLink>
    }
    return (navlink);
  }

  navLinkExplorer3(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(/*this.props.User.type==="Admin"||*/this.props.User.type==="SuperPsychologist")
        navlink=<NavLink to="/newchars" activeStyle={selectedStyle}>[Утверждение]</NavLink>
    }
    return (navlink);
  }

  navLinkPsychologist(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(/*this.props.User.type==="Admin"||*/this.props.User.type==="SchoolPsychologist"||
        this.props.User.type==="Psychologist"||this.props.User.type==="SuperPsychologist")
          navlink=<NavLink to="/psychologist" activeStyle={selectedStyle}>[Тесты]</NavLink>
    }
    return (navlink);
  }

  navLinkTesting(){
    let navlink=null
    if(this.props.User.length!==0) { 
      if(this.props.User.type==="StudentUser")
          navlink=<NavLink to="/testing" activeStyle={selectedStyle}>[Тестирование]</NavLink>
    }
    return (navlink);
  }

  navLinkRegistration(){
    let navlink=null
    if(this.props.User.length===0) { 
      navlink=<NavLink to="/registration" activeStyle={selectedStyle}>[Регистрация]</NavLink>
    }
    return (navlink);
  }

  navLinkStat(){
    let navlink=null
    if(this.props.User.length!==0) {
      if(/*this.props.User.type==="Admin"||this.props.User.type==="SuperPsychologist"||*/this.props.User.type==="Psychologist")
        navlink=<NavLink to="/stat" activeStyle={selectedStyle}>[Статистика]</NavLink>
    }
    return (navlink);
  }

  render() { return (
    <nav className="main-menu">
      <NavLink to="/"><MDBIcon icon="home"/></NavLink>
      {this.navLinkAdmin()}
      {this.navLinkDirectories()}
      {this.navLinkExplorer()}
      {this.navLinkExplorer2()}
      {this.navLinkExplorer3()}
      {this.navLinkPsychologist()}
      {this.navLinkTesting()}
      {this.navLinkStat()}
      <NavLink to="/contact" activeStyle={selectedStyle}>[Контакты]</NavLink>
      {this.navLinkLogin()}
      {this.navLinkRegistration()}
    </nav>
  )}
}