import {connect} from 'react-redux'
import Explorer from '../components/Explorer'


export const NewExplorer = connect (
  //null,
  state=>({
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    CurrentZIndex : state.CurrentZIndex,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StartFlag: state.StartFlag,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    User: state.User,
    Tests: state.Tests,
    PersonsRoles: state.PersonsRoles,
    PersonsChar: state.PersonsChar,
    ActivePagination: state.ActivePagination,
    ActiveTabPane: state.ActiveTabPane,

  }),
  dispatch => ({
       
    onGetFetchPVG(url) {
      let newPVG, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(pvg=> {
        newPVG=[]
        for (i=0;i<pvg.length;i++)
          newPVG.push(pvg[i])
        dispatch({type : 'GET_FETCH_PVG', PVG: newPVG})//personal_value_group
        this.onGetFetchCharteristics(process.env.REACT_APP_URL+"/characteristics",newPVG);
        })
        }
      })
    },

    /*onGetFetchPVG(url) {
      var newPVG
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        //console.log(response);
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        return response;
      })
      .then(response=>response.json())
      .then(pvg=> {
        //console.log(pvg);
        newPVG=[]//new Array()
        for (var i=0;i<pvg.length;i++)
          newPVG.push(pvg[i])
        dispatch({type : 'GET_FETCH_PVG', PVG: newPVG})//personal_value_group
        this.onGetFetchCharteristics(process.env.REACT_APP_URL+"/characteristics",newPVG);
      })
     
    },*/

    onGetFetchCharteristics(url,pvg) {
      let newCharteristics, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
         response.json().then(charteristics=> {
          newCharteristics=[]
          for (i=0;i<charteristics.length;i++)  {
            newCharteristics.push({id:charteristics[i].id, name:charteristics[i].name,
              positivity:charteristics[i].positivity,
              personal_value_group_id:charteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===charteristics[i].positivity).name,
              pvg:pvg.find(t=>t.id===charteristics[i].personal_value_group_id).name})
          }
          dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newCharteristics})
        })
        }
        })
    },

    /*onGetFetchCharteristics(url,pvg) {
      var newCharteristics
      //console.log(url);
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
        .then(response=> {
          //console.log(response);
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
            //throw new Error (response.statusText)
          }
          return response;
        })
        .then(response=>response.json())
        .then(charteristics=> {
          newCharteristics=[]//new Array()
          for (var i=0;i<charteristics.length;i++)
          {
            newCharteristics.push({id:charteristics[i].id, name:charteristics[i].name,
              positivity:charteristics[i].positivity,
              personal_value_group_id:charteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===charteristics[i].positivity).name,
              pvg:pvg.find(t=>t.id===charteristics[i].personal_value_group_id).name})
          }
          dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newCharteristics})
        })
    },*/

    onPostFetchСharacteristic(url,data) {
      let newСharacteristics, i
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(charteristic=> {
        newСharacteristics=[]
        for (i=0;i<this.Сharacteristics.length;i++) {
          newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
            positivity:this.Сharacteristics[i].positivity,
            personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
            positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
            pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
        }
          newСharacteristics.push({id:charteristic.id, name:charteristic.name,
          positivity:charteristic.positivity,
          personal_value_group_id:charteristic.personal_value_group_id,
          positivity_str:this.Positivity.find(t=>t.value===charteristic.positivity).name,
          pvg:this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name});
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
      }
      })
    },

    /*onPostFetchСharacteristic(url,data) {
      var newСharacteristics
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        return response;
      })
      .then(response=>response.json())
      .then(charteristic=> {
        newСharacteristics=[]//new Array()
        for (var i=0;i<this.Сharacteristics.length;i++)
          {
            newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
              positivity:this.Сharacteristics[i].positivity,
              personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
              pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
          }
          newСharacteristics.push({id:charteristic.id, name:charteristic.name,
          positivity:charteristic.positivity,
          personal_value_group_id:charteristic.personal_value_group_id,
          positivity_str:this.Positivity.find(t=>t.value===charteristic.positivity).name,
          pvg:this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name});
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
    },*/

    onUpdateFetchСharacteristic(url,data) {
      let newСharacteristics, i
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(charteristic=> {
        newСharacteristics=[]
        for (i=0;i<this.Сharacteristics.length;i++) {
            newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
              positivity:this.Сharacteristics[i].positivity,
              personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
              pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
            if(newСharacteristics[i].id===charteristic.id) 
            {newСharacteristics[i].name=charteristic.name;
              newСharacteristics[i].positivity=charteristic.positivity;
              newСharacteristics[i].personal_value_group_id=charteristic.personal_value_group_id;
              newСharacteristics[i].positivity_str=this.Positivity.find(t=>t.value===charteristic.positivity).name;
              newСharacteristics[i].pvg=this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name;                
            }
          }
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
      }
      })
    },

    /*onUpdateFetchСharacteristic(url,data) {
      var newСharacteristics
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        return response;
      })
      .then(response=>response.json())
      .then(charteristic=> {
        newСharacteristics=[]//new Array()
        
        for (var i=0;i<this.Сharacteristics.length;i++)
          {
            newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
              positivity:this.Сharacteristics[i].positivity,
              personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
              positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
              pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
            if(newСharacteristics[i].id===charteristic.id) 
            {newСharacteristics[i].name=charteristic.name;
              newСharacteristics[i].positivity=charteristic.positivity;
              newСharacteristics[i].personal_value_group_id=charteristic.personal_value_group_id;
              newСharacteristics[i].positivity_str=this.Positivity.find(t=>t.value===charteristic.positivity).name;
              newСharacteristics[i].pvg=this.PVG.find(t=>t.id===charteristic.personal_value_group_id).name;                
            }
          }
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
      })
    },*/

    onDeleteFetchСharacteristic(url,data) {
      let newСharacteristics,i
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        newСharacteristics=[]
        for (i=0;i<this.Сharacteristics.length;i++) {
          newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
            positivity:this.Сharacteristics[i].positivity,
            personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
            positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
            pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
        }
        newСharacteristics=newСharacteristics.filter(t => t.id !==data.id)
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
        return response;
        }
      })
    },

    /*onDeleteFetchСharacteristic(url,data) {
      //console.log(url);
      var newСharacteristics
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        //console.log(response);
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          //throw new Error (response.statusText)
        }
        //console.log(response);
        newСharacteristics=[]//new Array()
        for (var i=0;i<this.Сharacteristics.length;i++)
        {
          newСharacteristics.push({id:this.Сharacteristics[i].id, name:this.Сharacteristics[i].name,
            positivity:this.Сharacteristics[i].positivity,
            personal_value_group_id:this.Сharacteristics[i].personal_value_group_id,
            positivity_str:this.Positivity.find(t=>t.value===this.Сharacteristics[i].positivity).name,
            pvg:this.PVG.find(t=>t.id===this.Сharacteristics[i].personal_value_group_id).name});
        }
        newСharacteristics=newСharacteristics.filter(t => t.id !==data.id)
        //tempStudents=tempStudents.filter(t => t.id !==data.id)
        
        //dispatch({type : 'GET_FETCH_STUDENTS', Students: newStudents,tempStudents:tempStudents})
        dispatch({type : 'GET_FETCH_СHARACTERISTICS', Сharacteristics: newСharacteristics})
        return response;
      })
    },*/
   
  })
) (Explorer)
