import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import {Modal} from 'react-bootstrap';
import {MDBIcon} from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
//import 'bootstrap-css-only/css/bootstrap.min.css'; 
//import 'mdbreact/dist/css/mdb.css';
    

class Home extends React.Component {
  constructor(props) {
      super(props)
     // this.onMouseDown=this.onMouseDown.bind(this)
     // this.onStop=this.onStop.bind(this)
  }
  
  componentDidMount() {
    //this.props.onShowModalFormsAdmin(false,false,false,"Admin");
    //this.props.onGetFetchUsers(process.env.REACT_APP_URL+"/users");
    //this.props.onInitialData(process.env.REACT_APP_URL+"/cities",process.env.REACT_APP_URL+"/schools");
  }

  /*getTDAction(user_id) {
    if (this.props.User.type==="Admin")
      return (
        <td>
        <Container>
          <Row>
            <Col><Button variant="primary" type="submit" id={user_id} onClick={(e)=>{
              this.props.onShowModalFormsAdmin(false,true,false,
                this.props.Users.find(t=>t.id===Number(e.target.id)).type,Number(e.target.id))
              }}> 
              <MDBIcon id={user_id} far icon="edit"/>
            </Button></Col>
            <Col><Button variant="danger"type="submit" id={user_id} onClick={(e)=>{
              this.props.onDeleteFetchUsers(process.env.REACT_APP_URL+"/users/"+String(e.target.id),
                Number(e.target.id))
              }}> <MDBIcon id={user_id} far icon="trash-alt"/></Button></Col>
          </Row>
        </Container>
        </td>
      );
      else return null;
  }*/

  /*addFormAdmin() {
    let _Login,_Password,_ConfirmPassword,_Type,_School,listSchools=null,ErrMessage=""

    if(this.props.ShowModalFormsAdmin.type==="SchoolAdmin"||
      this.props.ShowModalFormsAdmin.type==="SchoolPsychologist")
    {listSchools=<Form.Group controlId="formListSchools">
      <Form.Label>Выберите школу</Form.Label>
      <Form.Control as="select" ref={input=>_School=input}>
      {
        this.props.tempSchools.map((school,idx)=>{
          return <option key={idx} value={school.id}>{String(school.city)+": "+String(school.name)}
          </option>
        })
      }
      </Form.Control>
      </Form.Group>
    }
    
    return (
      <Modal size="sm" show={this.props.ShowModalFormsAdmin.add} 
        onHide={()=>this.props.onShowModalFormsAdmin(false,false,false,"Admin")}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formType">
            <Form.Label>Выберите роль пользователя</Form.Label>
            <Form.Control as="select" ref={input=>_Type=input} onChange={(e)=>{
              this.props.onShowModalFormsAdmin(this.props.ShowModalFormsAdmin.add,
                this.props.ShowModalFormsAdmin.edit,this.props.ShowModalFormsAdmin.del, e.target.value)
            }}>
              <option value="Admin">Главный администратор</option>
              <option value="Psychologist">Главный психолог</option>
              <option value="SchoolPsychologist">Школьный психолог</option>
              <option value="SchoolAdmin">Школьный администратор</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formBasicLogin">
            <Form.Label>Введите свой псевдоним</Form.Label>
            <Form.Control ref={input=>_Login=input} type="text" placeholder="Псевдоним" />
            <Form.Text className="text-muted">
              Никому не сообщайте свой псевдоним
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Введите пароль</Form.Label>
            <Form.Control ref={input=>_Password=input} type="password" placeholder="Пароль" />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Подтвердите пароль</Form.Label>
            <Form.Control ref={input=>_ConfirmPassword=input} type="password"
              placeholder="Подтверждение пароля"/>
          </Form.Group>
          {listSchools}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            onClick={()=>this.props.onShowModalFormsAdmin(false,false,false,"Admin")}>
            Отмена
          </Button>
          <Button variant="primary" onClick={()=>{
            //проверки на корректность данных
            if(_Login.value==="") ErrMessage+="Необходимо ввести псевдоним\n"
            if(_Password.value==="") ErrMessage+="Необходимо ввести пароль\n"
            if(_ConfirmPassword.value==="") ErrMessage+="Необходимо ввести подтверждение пароля\n"
            if(_Password.value!==""&&_ConfirmPassword.value!=="")
              if(_Password.value!==_ConfirmPassword.value) ErrMessage+="Пароли не сопадают\n"
            if(ErrMessage!=="") {alert(ErrMessage);ErrMessage=""}
            else {  
              if(this.props.ShowModalFormsAdmin.type==="SchoolAdmin"||
                this.props.ShowModalFormsAdmin.type==="SchoolPsychologist")
                  this.props.onPostFetchUsers(process.env.REACT_APP_URL+"/users",{login:_Login.value,
                    password:_Password.value,type:_Type.value,school_id:Number(_School.value)})
              else this.props.onPostFetchUsers(process.env.REACT_APP_URL+"/users",{login:_Login.value,
                password:_Password.value,type:_Type.value})
              ErrMessage=""
              this.props.onShowModalFormsAdmin(false,false,false,"Admin")
            }
            }}
          > Добавить </Button>
        </Modal.Footer>
      </Modal>
    );
  }*/

  /*editFormAdmin() {
    let _Login,_Password,_ConfirmPassword,_Type,_School,listSchools=null,ErrMessage=""

    if(this.props.ShowModalFormsAdmin.edit) {
    if(this.props.ShowModalFormsAdmin.type==="SchoolAdmin"||
      this.props.ShowModalFormsAdmin.type==="SchoolPsychologist")
    {
      listSchools=<Form.Group controlId="formListSchools">
      <Form.Label>Выберите школу</Form.Label>
      <Form.Control as="select" ref={input=>_School=input}
        defaultValue={this.props.Users.find(t=>t.id===this.props.ShowModalFormsAdmin.id).school_id}
      >
      {
        this.props.tempSchools.map((school,idx)=>{
          return <option key={idx} value={school.id}>{String(school.city)+": "+String(school.name)}
          </option>
        })
      }
      </Form.Control>
      </Form.Group>
    }

    return (
      <Modal size="sm" show={this.props.ShowModalFormsAdmin.edit} 
        onHide={()=>this.props.onShowModalFormsAdmin(false,false,false,"Admin")}>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicLogin">
            <Form.Label>Введите свой псевдоним</Form.Label>
            <Form.Control ref={input=>_Login=input} type="text" placeholder="Псевдоним" 
              defaultValue={this.props.Users.find(t=>t.id===this.props.ShowModalFormsAdmin.id).login} />
            <Form.Text className="text-muted">
              Никому не сообщайте свой псевдоним
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Введите пароль</Form.Label>
            <Form.Control ref={input=>_Password=input} type="password" placeholder="Пароль" />
          </Form.Group>
          <Form.Group controlId="formConfirmPassword">
            <Form.Label>Подтвердите пароль</Form.Label>
            <Form.Control ref={input=>_ConfirmPassword=input} type="password"
              placeholder="Подтверждение пароля"/>
            </Form.Group>
            {listSchools}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            onClick={()=>this.props.onShowModalFormsAdmin(false,false,false,"Admin")}>
            Отмена
          </Button>
          <Button variant="primary" onClick={()=>{
            //проверки на корректность данных
            if(_Login.value==="") ErrMessage+="Необходимо ввести псевдоним\n"
            if(_Password.value===""&&_ConfirmPassword.value!=="")
              ErrMessage+="Необходимо ввести пароль\n"
            if(_ConfirmPassword.value===""&&_Password.value!=="") 
              ErrMessage+="Необходимо ввести подтверждение пароля\n"
            if(_Password.value!==""&&_ConfirmPassword.value!=="")
              if(_Password.value!==_ConfirmPassword.value) ErrMessage+="Пароли не сопадают\n"
            if(ErrMessage!=="") {alert(ErrMessage);ErrMessage=""}
            else {
              if(this.props.ShowModalFormsAdmin.type==="SchoolAdmin"||
                this.props.ShowModalFormsAdmin.type==="SchoolPsychologist")
                  this.props.onUpdateFetchUsers(process.env.REACT_APP_URL+"/users/"+
                    String(this.props.ShowModalFormsAdmin.id),{login:_Login.value,
                    password:_Password.value,type:this.props.ShowModalFormsAdmin.type,
                    school_id:Number(_School.value)})
              else this.props.onUpdateFetchUsers(process.env.REACT_APP_URL+"/users/"+
                String(this.props.ShowModalFormsAdmin.id),{login:_Login.value,
                password:_Password.value,type:this.props.ShowModalFormsAdmin.type})
              ErrMessage=""
              this.props.onShowModalFormsAdmin(false,false,false,"Admin")
            }
            }}
          > Изменить </Button>
        </Modal.Footer>
      </Modal>
      );
      }
      else return null;
  }*/

  /*showAdminInterface() {
    //if type==Admin then rendering button add, edit, delete
    let thAction, addAction, _User
    
    if(this.props.User.type==="Admin") {
      thAction=<th>Действия</th>
      addAction=<Button variant="primary" type="submit" onClick={()=>{
        this.props.onShowModalFormsAdmin(true,false,false,"Admin")
      }}><MDBIcon icon="plus"/></Button>
    } 
    else {thAction=null; addAction=null}

    return (
      <Container>
          <Row><Col>
            <Alert variant="primary">
              <Alert.Heading>Зарегистрированные пользователи</Alert.Heading>
            </Alert>
          </Col></Row>
          <Row><Col>
            {addAction}
            <br></br>
            <br></br>
          </Col></Row>
          <Row>
            <Col>
            <Table responsive striped  size="lg">
              <thead>
                <tr align="center">
                  <th>#</th>
                  <th>Имя учетной записи</th>
                  <th>Роль в системе</th>
                  {thAction}
                </tr>
              </thead>
              <tbody>
              {
                this.props.Users.map((user,idx)=>{
                  return (
                    <tr key={idx} id={user.id}>
                      <td>{idx+1}</td>
                      <td>{user.login}</td>
                      <td>{user.type}</td>
                      {
                        this.getTDAction(user.id)
                      }
                    </tr>
                  );
                })
              }
              </tbody>  
            </Table>
            {this.addFormAdmin()}
            {this.editFormAdmin()}
          </Col></Row>
      </Container>
    );
  }*/

  render() {
      
    return <div key="home">
        <Container>
          <Row><Col>
            <h1>Информация о проекте</h1>
            <br></br>
            <br></br>
            <Alert variant="success">
              <Alert.Heading>Видеообращение</Alert.Heading>
            </Alert>
          </Col></Row>
          <Row><Col>
            <iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'
            frameBorder={0}
            allow='autoplay; encrypted-media'
            allowFullScreen={true}
            width="100%" height="200%"
            title='video'
            />
          </Col></Row>
        </Container>
      </div>;
    }
  }
  
  
  export default Home