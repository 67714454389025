//import { ActionCableProvider } from "actioncable-client-react";
import ActionCable from "actioncable";
//import ChatRoom from "./chatRoom";
 
// With jwt authentication
//const WSS_URL = `wss://example.com/cable?${YOUR_TOKEN}`;
 
// Without authentication
const WSS_URL = "ws://"+process.env.REACT_APP_URL.substr(7)+"/cable";
 
const cable = ActionCable.createConsumer(WSS_URL)
//const cable = ActionCable.createConsumer(WSS_URL,`{'access-token':'3e-kEFEGrtMW5PAj7RVbLA'}`)
/*const cable = ActionCable.createConsumer(WSS_URL,{
    headers: {'Content-Type': 'application/json',
      'access-token':'3e-kEFEGrtMW5PAj7RVbLA','uid':'oleg_psi','client':'fhnvyjV_qSFk0fpVMOdmiQ',
    },});*/
//ActionCable.createConsumer()
//cable.
 
export default cable;