import React from 'react';
//import logo from '../logo.svg';
import './App.css';
//import ReactDOM from 'react-dom';
//import { ActionCableProvider } from 'actioncable-client-react';
import {HashRouter, Route,Switch} from 'react-router-dom'
import {Directories, About, Testing, Contact, W404, Home,Login,Registration, RegAbout, 
  Explorer, Psychologist, Admin, Classification, NewChars, Stat} from './pages'
import {Provider} from 'react-redux'
import storeFactory from './store'

const store=storeFactory()

function App() {
  return (
    <Provider store={store}>
    <HashRouter>
      <div key="div_main" className="main">
        <Switch>
          <Route exact path="/" component={Home}></Route>
          {/*<Route path="/about" component={About}></Route>*/}
          <Route path="/admin" component={Admin}></Route>
          <Route path="/directories" component={Directories}></Route>
          <Route path="/explorer" component={Explorer}></Route>
          <Route path="/classification" component={Classification}></Route>
          <Route path="/newchars" component={NewChars}></Route>
          <Route path="/psychologist" component={Psychologist}></Route>
          <Route path="/testing" component={Testing}></Route>
          <Route path="/contact" component={Contact}></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/registration" component={Registration}></Route>
          <Route path="/stat" component={Stat}></Route>
          {/*<Route path="/registration/about" component={RegAbout}></Route>*/}
          <Route component={W404}></Route>
        </Switch>
      </div>  
    </HashRouter>
    </Provider>
  );
}

export default App;
