const constants = {
  CHANGE_ZINDEX_PERSON:'CHANGE_ZINDEX_PERSON',
  CHANGE_POSITION:'CHANGE_POSITION',
  GET_FETCH_ROLES: 'GET_FETCH_ROLES',
  GET_FETCH_СHARACTERISTICS: 'GET_FETCH_СHARACTERISTICS',
  GET_FETCH_SCHOOLS: 'GET_FETCH_SCHOOLS',
  GET_FETCH_CITIES: 'GET_FETCH_CITIES',
  SET_CURRENT_CITY_ID: 'SET_CURRENT_CITY_ID',
  GET_FETCH_STUDENTGROUPS: 'GET_FETCH_STUDENTGROUPS',
  SET_CURRENT_SCHOOL_ID: 'SET_CURRENT_SCHOOL_ID',
  SET_CURRENT_STUDENTGROUP_ID: 'SET_CURRENT_STUDENTGROUP_ID',
  GET_FETCH_STUDENTS: 'GET_FETCH_STUDENTS',
  GET_FETCH_PVG: 'GET_FETCH_PVG',
  GET_FETCH_STUDENT_TEST: 'GET_FETCH_STUDENT_TEST',
  GET_FETCH_PERSON: 'GET_FETCH_PERSON',
  DELETE_FETCH_PERSON:'DELETE_FETCH_PERSON',
  SET_CURRENT_PERSON_ID: 'SET_CURRENT_PERSON_ID',
  SET_REGFLAG: 'SET_REGFLAG',
  GET_FETCH_TESTS:'GET_FETCH_TESTS',
  GET_FETCH_USER: 'GET_FETCH_USER',
  GET_FETCH_PERSON_ROLE: 'GET_FETCH_PERSON_ROLE',
  GET_FETCH_PERSON_CHAR: 'GET_FETCH_PERSON_CHAR',
  DELETE_FETCH_USER: 'DELETE_FETCH_USER',
  NEW_START_TEST: 'NEW_START_TEST',
  CHANGE_PAGINATION: 'CHANGE_PAGINATION',
  CRUD_FETCH_USERS: 'CRUD_FETCH_USERS',
  SHOW_MODAL_FORMS_ADMIN: 'SHOW_MODAL_FORMS_ADMIN',
  CHAR_COMPLETE: 'CHAR_COMPLETE',
  CHANGE_PAGINATION_TABLE:'CHANGE_PAGINATION_TABLE',
  CHANGE_PAGINATION_TABLE2:'CHANGE_PAGINATION_TABLE2',
  CHANGE_PAGINATION_TABLE3:'CHANGE_PAGINATION_TABLE3',
  CRUD_WORDS:'CRUD_WORDS',
  CRUD_CITYTYPES: 'CRUD_CITYTYPES',
  CRUD_DISTRICTS: 'CRUD_DISTRICTS',
  CRUD_SCHOOLTYPES: 'CRUD_SCHOOLTYPES',
  CRUD_FILTERS_DS: 'CRUD_FILTERS_DS',
  BUILD_FILTER_DS: 'BUILD_FILTER_DS',
  CRUD_FILTERS_CS: 'CRUD_FILTERS_CS',
  BUILD_FILTER_CS: 'BUILD_FILTER_CS',
  CRUD_FILTERS_TS: 'CRUD_FILTERS_TS',
  BUILD_FILTER_TS: 'BUILD_FILTER_TS',
}

export default constants
