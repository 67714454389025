import {connect} from 'react-redux'
import Classification from '../components/Classification'


export const NewClassification = connect (
  
  state=>({
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    CurrentZIndex : state.CurrentZIndex,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StartFlag: state.StartFlag,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    User: state.User,
    Tests: state.Tests,
    PersonsRoles: state.PersonsRoles,
    PersonsChar: state.PersonsChar,
    ActivePagination: state.ActivePagination,
    ActiveTabPane: state.ActiveTabPane,
    CharComplete: state.CharComplete,
    PersonsTab: state.PersonsTab,
    Words: state.Words,
    ShowModalFormsAdmin: state.ShowModalFormsAdmin,
  }),
  dispatch => ({

    async onGetFetchWords(url) {
      let json,words
      words= []
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        words= await response.json()
        words=words.sort((a,b)=>{return b.ref_number-a.ref_number})
        dispatch({type : 'CRUD_WORDS', Words: words})
      }
    },

    onDeleteWords(word_id) {
      let i, words
      words= []
      for(i=0;i<this.Words.length;i++) words.push(this.Words[i])
      words=words.filter(t => t.id !==word_id)
      dispatch({type : 'CRUD_WORDS', Words: words})
    },

    onPostFetchNewChars(url,data) {
      let newUsers
      newUsers= []
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(newChar=> {
          //console.log(newChar)
      })
      }
      })
    },

    onShowModalFormsAdmin(add,edit,del,type,id) {
      dispatch({type : 'SHOW_MODAL_FORMS_ADMIN', ShowModalFormsAdmin: 
        {add:add,edit:edit,del:del,type:type,id:id}})
    },

    onGetFetchPVG(url) {
      let newPVG, i
      fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
        } else {
        response.json().then(pvg=> {
        newPVG=[]
        for (i=0;i<pvg.length;i++)
          newPVG.push(pvg[i])
        dispatch({type : 'GET_FETCH_PVG', PVG: newPVG})//personal_value_group
        //this.onGetFetchCharteristics(process.env.REACT_APP_URL+"/characteristics",newPVG);
        })
        }
      })
    },
   
  })
) (Classification)
