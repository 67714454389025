//import React from 'react';
import React, { PureComponent } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Alert from 'react-bootstrap/Alert'
import CRUDTable, { Fields, Field, CreateForm, UpdateForm, DeleteForm,} from 'react-crud-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Label,
} from 'recharts';
import '../stylesheets/Psychologist.css';


class CustomizedAxisTick extends PureComponent {
  render() {
    const {
      x, y, stroke, payload,
    } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    );
  }
}

class Psychologist extends React.Component {

  componentDidMount() {
    this.props.onGetFetchPVG(process.env.REACT_APP_URL+"/personal_value_groups");
    this.props.onGetFetchSchools(process.env.REACT_APP_URL+"/schools").then(()=>{
    if(this.props.User.type==="SchoolPsychologist")
      this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+
        "/student_groups?filterrific[with_school_id]="+String(this.props.User.school_id));
    else this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups");
    })
  }
    
  getDateTime(datetime) {
    let newDateTime=""
    newDateTime=datetime.substring(8,10)+"."+datetime.substring(5,7)+"."+datetime.substring(0,4)+" "+
      datetime.substring(11,16)
    return newDateTime;
  }

  renderTextForMarkedRole(test_index){
    let str=this.props.onGetTextForMarkedRole(test_index)
    if(str===null) return null;
    else return (<div key="renderTextForMarkedRole">{str.map((st,idx)=>{
      return (
        <p key={idx}>{st}</p>
      );
    })}</div>)
  }

  renderForTable4(str){
    if(str==="") return null;
    else return <p>{str}</p>;
  }

  renderForTable3(type,text){
    if(type==="max"&&text.length!==0) return <div key="renderForTable3_1">
      <p style={{margin:0}}>Наиболее общие социальные категории имеют следующие атрибуты ценностей:</p> 
      {text.map((txt,idx)=>{
        return (<p key={idx} style={{margin:0}}>{txt}</p>
        );
      })
      }
      <p>{""}</p>
    </div>
    if(type==="base"&&text.length!==0)return <div key="renderForTable3_2">
      <p style={{margin:0}}>Наиболее важные в социализации учащихся социальные категории имеют 
        следующие атрибуты ценностей:</p> 
      {text.map((txt,idx)=>{
        return (<p key={idx} style={{margin:0}}>{txt}</p>
        );
      })
      }
      <p>{""}</p>
    </div>
    else return null
  }

  renderForTable6(type, resultTable6){
    if(type==="max"){
      if(resultTable6.max!=="")
        return <div key="resultTable6_max">
          <p>В данной учебной группе наиболее актуальны ценности: {resultTable6.max} </p>
        </div>
      else
        return <div key="resultTable6_max">
          <p>Наиболее актуальные ценности не определены в данной группе</p>
        </div>
    }
    if(type==="less5") {
      if(resultTable6.less5!=="")
        return <div key="resultTable6_less5"><p>{resultTable6.less5}</p></div>
      else 
        return <div key="resultTable6_less5">
          <p>Неактуальные ценности не определены в данной группе</p>
        </div>
    }
    if(type==="greater_equal75") {
      if(resultTable6.greater_equal75!=="не определены")
        return <div key="resultTable6_greater_equal75">
          <p /*style={{margin:0}}*/>
            Также мы видим общность и различия в эмоциональной индивидуальности учащихся. 
            Ценности общегрупповые характерны для большинства. Это {resultTable6.greater_equal75}, которые 
            являются общими для максимального большинства учащихся.
          </p>
        </div>
      else
        return <div key="resultTable6_greater_equal75">
          <p>Общегрупповые ценности не определены в данной группе</p>
        </div>
    }
    if(type==="less50"){
      if(resultTable6.less50!=="не определены")
        return <div key="resultTable6_less50"> 
          <p>Ценности: {resultTable6.less50} показывают на индивидуальные 
            предпочтения учащихся в восприятии окружающего мира.</p>
        </div>
      else
        return <div key="resultTable6_less50">
          <p>Ценности, показывающие индивидуальные предпочтения учащихся в восприятии окружающего мира,
            не определены в данной группе</p>
        </div>
    }
    return null
  }

  showTableResult(test_id,index) {
    let resultTable1, resultTable6, resultTable3, resultTable4
    if(this.props.Tests[index].result===null) return <p>Результаты для теста пока отсутствуют</p>;
    else {
      resultTable1=this.props.onGetTextForRole(index)
      resultTable6=this.props.onGetTextForTable6(index)
      resultTable3=this.props.onGetTextForTable3(index)
      resultTable4=this.props.onGetTextForTable4(index)
    return <div key={test_id}>
      <br></br>
      <Alert key={1.1} variant={'primary'}>
        <Alert.Heading>I. Какие социальные группы наиболее актуальны для учащихся?</Alert.Heading>
      </Alert>
      <h4>{"Таблица №1. Частота упоминания представителей социальных групп. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Наименование категории</th>
            {/*<th>Частота упоминания от кол-ва персонажей, %</th>*/}
            <th>Кол-во респондентов, указавших категорию (абс. (%))</th>
            <th>Кол-во респондентов, не указавших категорию (абс. (%))</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.Tests[index].result.hist_roles.map((hist_role,idx)=>{
              return (
                <tr key={idx}>
                  <td>{idx+1}</td>
                  <td>{hist_role.role_name}</td>
                  {/*<td>{hist_role.generally}</td>*/}
                  <td>{String(hist_role.indicate)+" ("+String(hist_role.indicate_procent)+" %)"}</td>
                  <td>{String(hist_role.not_indicate)+" ("+String(hist_role.not_indicate_procent)+
                    " %)"+this.props.onMarkedRole(hist_role.role_name, hist_role.not_indicate)}</td>
                </tr>
              );
            })
          }
        </tbody>  
      </Table>
     
      <br></br>
      <h4>{"Рисунок №1. Частота упоминания представителей социальных групп. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)}
      </h4>
      <BarChart
        width={1000}
        height={400}
        data={Array.from(this.props.Tests[index].result.hist_roles).sort((a,b)=>{
          return b.generally-a.generally})}
        margin={{
          top: 5, right: 30, left: 20, bottom: 120,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="role_name" 
          tick={<CustomizedAxisTick />}
          interval={0}
        >
          <Label value="Категории" offset={-110} position="insideBottom" />
        </XAxis>
        <YAxis label={{ value: 'Частота упоминания, в %', angle: -90, position: 'insideBottomLeft' }}> 
          <Label></Label>
        </YAxis>
        <Tooltip />
        <Bar dataKey="generally" fill="#8884d8" />
      </BarChart>
      <Alert key={1.2} variant={'primary'}>
        <Alert.Heading>Пояснения к таблице №1 и рисунку №1</Alert.Heading>
          <p>Высокая частота упоминания указывает на наиболее актуальные для учащихся социальные группы,
            а низкая частота – мало актуальные. Исключением являются родители, 
            т.к. родителей в списке не может быть более 2-х человек,
            тогда как количество представителей других групп может быть неограниченно.
          </p>
          <p style={{margin:0}}>В таблице №1 показано, что наиболее актуальными для учебной группы 
            являются социальные категории:
            {resultTable1.max}.</p>
          <p style={{margin:0}}>Наименее актуальными являются:{resultTable1.middle}.</p>
          <p>Полностью отсутствуют в зоне внимания учащихся:{resultTable1.min}.</p>
          {this.renderTextForMarkedRole(index)}
      </Alert>
      <br></br>
      {/*<Alert key={2.1} variant={'primary'}>
        <Alert.Heading>II. Каковы различия в актуальности социальных групп?</Alert.Heading>
        <p>Частота упоминания других людей говорит об их актуальности для респондентов. Высокая частота 
          упоминания соответствует высокой значимости и наоборот.
        </p>
      </Alert>*/}
      {/*<h4>{"Таблица №2. Различия основных социальных категорий по частоте упоминания. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr align="center">
          <th colSpan="2"><p>Социальные категории</p></th>
          <th colSpan="3"><p>Количество учащихся (абс. и %)</p></th>
          </tr>
          <tr>
            <th>Категория 1</th>
            <th>Категория 2</th>
            <th>упоминающие категрии 1 и 2 с равной частотой</th>
            <th>упоминающие чаще категорию 1</th>
            <th>упоминающие чаще категорию 2</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.Tests[index].result.pair_roles.map((pair_role,idx)=>{
              return (
                <tr key={idx}>
                  <td>{pair_role.first_role}</td>
                  <td>{pair_role.second_role}</td>
                  <td>{String(pair_role.eq_count)+" ("+String(pair_role.eq_percent)+" %)"}</td>
                  <td>{String(pair_role.lt_count)+" ("+String(pair_role.lt_percent)+" %)"}</td>
                  <td>{String(pair_role.gt_count)+" ("+String(pair_role.gt_percent)+" %)"}</td>
                </tr>
              );
            })
          }
        </tbody>  
        </Table>*/}
      <Alert key={2.2} variant={'primary'}>
        {/*<Alert.Heading>Пояснения к таблице №2</Alert.Heading>*/}
          <p>Те, кто чаще упоминает других людей «старше себя» ориентируются на взрослых – родителей, 
            возможно, родственников, известных личностей. Они невольно сравнивают себя с образами взрослых, 
            обдумывают более отдаленные цели, чаще стремятся к достижению, могут выглядеть более серьезными 
            и более «понятными» для старшего поколения. Эти учащиеся вызывают большую удовлетворенность у 
            родителей, педагогов. Однако отношения со сверстниками у них не всегда складываются легко.
          </p>
          <p>Те, кто чаще упоминает ровесников и младше себя, напротив, легко строит отношения с 
            ровесниками и ориентируется на интересы, типичные для своего возраста. У этих учащихся могут быть 
            трудности в общении с более старшими, в том числе с педагогами и родителями. На первый взгляд, 
            более «инфантильные», живущие интересами общения со сверстниками, эти юноши и девушки впоследствии 
            могут быстрее «впитывать» новое с меняющейся молодежной массовой  культурой. Ресурсом для их 
            взросления и самостоятельной жизни являются хорошие навыки общения и широкие дружеские связи.
          </p>
          <p>Учащиеся с преобладанием образов – мужчин – это, в первую очередь, юноши, которые во 
            множестве мужских образов уточняют свое представление о мужественности. Преобладание женских 
            образов более характерно для девушек.
          </p>
          <p style={{margin:0}}>Высокая частота упоминания родственников по сравнению с 
            другими социальными группами говорит о высокой значимости родственных отношений для молодого 
            человека. Эти юноши и девушки также более серьезны в романтических отношениях с 
            противоположным полом. Ценность семьи может способствовать вступлению в традиционный брак; 
            и их брак ориентирован на воспитание детей.</p>
          <p>Однако, если упоминание родственников не преобладает, в сравнении с другими группами, то это 
            не свидетельствует о малозначимости семейных отношений для респондента. 
          </p>
          <p>Преобладание реальных персонажей  по сравнению с вымышленными (герои книг, фильмов, 
            интернет-игр) и «далекими» (известные личности) указывает на вовлеченность юноши или девушки в 
            реальное общение и в реальные события.
          </p>
          <p>Значительное количество воображаемых» персонажей (герои книг, фильмов, интернет-игр) или 
            «далеких» (известные личности)  может указывать и на мечтательность, на поглощенность своим 
            внутренним миром, а также на желание перемен в своей жизни, на поиски новых целей и смыслов 
            жизни.
          </p>
          <p>Преобладание одноклассников по сравнению с другими группами персонажей указывает на то, что 
            именно в классе происходят главные события жизни респондента. Это характерно для 
            следующих случаев. Во-первых, это могут быть учащиеся с высоким статусом в своей 
            учебной группе. Отношения в учебной группе значимы для них, они вовлечены в текущие события 
            и чувствуют себя способными регулировать все, что происходит. Как правило, это свойственно 
            лидерам, экстравертам – позитивным, общительным и активным натурам. Также это могут быть 
            учащиеся с низким статусом – например, новички, которые хотели бы «дружить со всеми». 
          </p>
          <p>Если упоминание одноклассников невелико по сравнению с другими группами, то это указывает на 
            избирательность в общении. Избирательность в общении может быть следствием характера (например, 
            высокая требовательность к взаимопониманию), а может быть результатом сложившихся отношений в 
            классе, где сформировались микрогруппы по интересам.
          </p>
      </Alert>
      <br></br>
      <Alert key={3.1} variant={'primary'}>
        <Alert.Heading>II. Какие ценности связаны с социальными группами в восприятии респондентов? 
        </Alert.Heading>
        <p>Воспринимая других людей, мы испытываем эмоции, которые отражаются в кратких описаниях, 
          например: «красивый», «умный» и т.д. Каждая оценка отражает определенные личностные ценности 
          респондентов.
        </p>
        <p style={{margin:0}}>Далее указаны категории признаков и ценности, которые им соответствуют 
          (приведенные категории признаков соответствуют категориям эмоций по Б.И. Додонову и добавлены две 
          группы признаков: «манеры и нормы» и «физический облик»):</p>
        <p style={{margin:0}}>1. коммуникативные ─ ценность общения, дружелюбия</p>
        <p style={{margin:0}}>2. альтруистические ─ ценность заботы, помощи</p>
        <p style={{margin:0}}>3. практические ─ ценность деятельности, практики</p>
        <p style={{margin:0}}>4. гностические ─ ценность познания, новизны</p>
        <p style={{margin:0}}>5. пугнические ─ ценность борьбы, преодоления опасности</p>
        <p style={{margin:0}}>6. манеры и нормы ─ ценность порядка, нормативности</p>
        <p style={{margin:0}}>7. гедонические ─ ценность телесного и душевного комфорта</p>
        <p style={{margin:0}}>8. глорические ─ ценность успеха, славы</p>
        <p style={{margin:0}}>9. эстетические ─ ценность красоты, гармонии</p>
        <p style={{margin:0}}>10. романтические ─ ценность необычного, таинственного</p>
        <p style={{margin:0}}>11. акизитивные ─ ценность накопления, коллекции</p>
        <p style={{margin:0}}>12. физический облик ─ ценность физического совершенства </p>
        <p>13. эмоциональность ─ ценность интереса к внутреннему миру другого человека</p>
        <p>Рассмотрим структуру ценностей учащихся в соответствии с частотой характеристик (признаков), 
          которые они приписывают представителям социальных категорий.
        </p>
      </Alert>
      <h4>{"Таблица №2. Частотный спектр категорий эмоциональной направленности, данных представителям \
        социальных групп. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr align="center">
            <th rowSpan="2"><p>Социальные категории</p></th>
            <th colSpan={this.props.PVG.length}>Частота упоминания признаков эмоциональной направленности (в %)</th>
          </tr>
          <tr>
            {this.props.PVG.map((pvg,idx)=>{
              return (
                <th height="290px" key={idx}><p className="vertical">{pvg.name}</p></th>
              );
            })}
            {/* <th height="290px"><p className="vertical">коммуникативные</p></th>
            <th><p className="vertical">альтруистические</p></th>
            <th><p className="vertical">практические</p></th>
            <th><p className="vertical">гностические</p></th>
            <th><p className="vertical">пугнические</p></th>
            <th><p className="vertical">манеры и нормы</p></th>
            <th><p className="vertical">гедонические</p></th>
            <th><p className="vertical">глорические</p></th>
            <th><p className="vertical">эстетический</p></th>
            <th><p className="vertical">романтические</p></th>
            <th><p className="vertical">акизитивные</p></th>
            <th><p className="vertical">физический облик</p></th>
            <th><p className="vertical">недифференцированная оценка</p></th>
          <th><p className="vertical">эмоциональность</p></th>*/}
          </tr>
        </thead>
        <tbody>
          {
            this.props.Tests[index].result.hist_roles_groups.map((hrg,idx)=>{
              return (
                <tr key={idx}>
                  <td>{hrg[0].role}</td>
                  {this.props.PVG.map((pvg,idx2)=>{
                    return (
                      <td key={idx2}>{hrg[idx2].value}</td>
                    );
                  })}
                </tr>
              );
            })
          }
        </tbody>  
      </Table>
      <Alert key={3.2} variant={'primary'}>
        <Alert.Heading>Пояснения к таблице №2</Alert.Heading>
        {this.renderForTable3("max",resultTable3.max)}
        {this.renderForTable3("base",resultTable3.base)}
        <p>Отметим, что родители воспринимаются наиболее единодушно как образцы добра, ума и красоты
          у младших и средних подростков.</p>
        <p>* Недифференцированная оценка означает, что человек воспринимается в целом без выделения
          отдельных качеств: как "идеал", "самый лучший" или "плохой", "ужасный", "любимый" и т.д.
        </p>
        </Alert>
      <br></br>
      <Alert key={4.1} variant={'primary'}>
        <Alert.Heading>III. В какой степени учащиеся переоценивают (или недооценивают) представителей 
          различных социальных групп? 
        </Alert.Heading>
        <p>Оценки важности (значимости) других людей индивидом можно условно разделить на пять групп:</p>
        <p>- «идеалы». Это те, кто вызывает благодарность, любовь, восхищение или другие самые возвышенные 
          чувства. Это то, что респонденты считают желаемым, но не всегда возможным для них самих.</p>
        <p>- «те, кто лучше меня». Это люди, вызывающие уважение, поскольку для респондентов они обладают 
          наиболее важными для них качествами. Именно «те, кто лучше меня» являются основой регуляции 
          поведения на текущем этапе жизни человека. Эти образы составляют «зону ближайшего развития» для 
          личности ─ то, что респонденты считают желаемым и возможным.</p>
        <p>- «те, кто хуже меня». Это образы людей, поведение которых не вполне соответствует ценностям 
          респондентов. Данные образы вызывают разочарование или противоречивые чувства, воплощают 
          качества, которые респондент отвергает в себе и своем поведении. Они могут восприниматься как 
          нежелательное, но возможное и допустимое.</p>
        <p>- «антиидеалы».  Это те, кто вызывает резкое отвержение. То, что респонденты воспринимают как 
          недопустимое.</p>
        <p>- «равноценные мне». Такая ценностная оценка также существует. Наиболее характерна для 
          индивидов с потребностью в общности с другими людьми. Для респондентов с острым чувством 
          уникальности, индивидуальности такая оценка не характерна.</p>
      </Alert>
      <h4>{"Таблица №3. Ценностная оценка социальных групп. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr align="center">
            <th rowSpan="2"><p>Социальные категории</p></th>
            <th colSpan="6">Ценностные оценки (%)</th>
          </tr>
          <tr>
            <th>идеалы</th>
            <th>лучше меня</th>
            <th>равноценные мне</th>
            <th>хуже меня</th>
            <th>антиидеалы</th>
            <th>выше среднего</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.Tests[index].result.authority_roles.map((ar,idx)=>{
              return (
                <tr key={idx}>
                  <td>{ar[0].role_name}</td>
                  <td>{ar[0].value}</td>
                  <td>{ar[1].value}</td>
                  <td>{ar[2].value}</td>
                  <td>{ar[3].value}</td>
                  <td>{ar[4].value}</td>
                  <td>{ar[5].value}</td>
                </tr>
              );
            })
          }
        </tbody>  
        </Table>
        <Alert key={4.2} variant={'primary'}>
          <Alert.Heading>Пояснения к таблице №3</Alert.Heading>
          <p style={{margin:0}}>Как следует из таблицы:</p>
          <p style={{margin:0}}>{resultTable4["идеалы"]}</p>
          <p style={{margin:0}}>{resultTable4["лучше меня"]}</p>
          <p style={{margin:0}}>{resultTable4["равноценные мне"]}</p>
          <p style={{margin:0}}>{resultTable4["хуже меня"]}</p>
          <p style={{margin:0}}>{resultTable4["антиидеалы"]}</p>
          <p style={{margin:0}}>{resultTable4["не влияет"]}</p>
        </Alert>
      <br></br>
      <Alert key={5.1} variant={'primary'}>
      <Alert.Heading>IV. Какие ценности наиболее актуальны для учащихся? 
        </Alert.Heading>
        <p>Актуальность ценностей соответствует частоте соответствующих признаков в восприятии других 
          людей. Ценности, подобно прожекторам, в свете которых внимание обращено на те или другие 
          особенности окружающих людей. Переживание ценностей сопровождается эмоциями, которые заставляют 
          человека стремиться к чему-либо и находить наибольшее удовлетворение. </p>
      </Alert>
      <h4>{"Таблица №4. Частота упоминания признаков, относящихся к различным ценностным категориям и "+ 
        "особенности эмоциональной индивидуальности учащихся. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr align="center">
            <th rowSpan="2"><p style={{margin:0}}>Название ценностной категории и </p>
              <p>эмоциональной направленности</p>
            </th>
            <th rowSpan="2"><p style={{margin:0}}>Частота упоминания признаков</p> 
              <p>(в % от общего количества признаков)*</p>
            </th>
            <th colSpan="4">Количество учащихся</th>
          </tr>
          <tr>
            <th>указавших данный признак (абс., %)</th>
            <th>не указавших данный признак (абс., %)</th>
          </tr>
        </thead>
        <tbody>
          {
            this.props.Tests[index].result.hist_personal_value_groups.map((pvg,idx)=>{
              return (
                <tr key={idx}>
                  <td>{pvg.group_name}</td>
                  <td>{pvg.value}</td>
                  <td>{String(pvg.indicate)+" ("+String(pvg.indicate_percent)+" %)"}</td>
                  <td>{String(pvg.not_indicate)+" ("+String(pvg.not_indicate_percent)+" %)"}</td>
                </tr>
              );
            })
          }
        </tbody>  
        </Table>
      <Alert key={5.2} variant={'primary'}>
        <Alert.Heading>Пояснения к таблице №4 
        </Alert.Heading>
        {/*<p>* Процент признаков указан от общего количества персонажей. Если значение процентов 
          составляет 25% - то около четверти персонажей воспринимаются «в свете» данной ценности. Если 
          значение % приближается к 50% - значит, около половины персонажей могут иметь признаки 
          данной ценности, а если – к 100%, то в среднем, каждый персонаж может иметь подобную 
          характеристику. Поскольку каждый персонаж может иметь несколько признаков, то общая сумма 
          процентов должна превышать 100%. Теоретически доля наиболее высоко значимой ценности может 
          превысить 100%, если некоторым персонажам приписано несколько признаков одной и той же 
          ценности. Например: бабушка – любящая, заботливая, угощает; дедушка – самый добрый, помогает. 
          В данном случае на 2 персонажа приходится 5 альтруистических признаков. Т.е. частота признаков 
          альтруистической категории в данном случае составит 250 % для этих двух персонажей. Т.е. чем 
          выше частота упоминания ценностной категории, тем выше ее актуальность (значимость) для 
          восприятия учащихся.
        </p>*/}
        <p>В школьной социализации особенно важны ценности альтруизма, общения и познания.</p>
        {this.renderForTable6("max",resultTable6)}
        {this.renderForTable6("less5",resultTable6)}
        {this.renderForTable6("greater_equal75",resultTable6)}
        {this.renderForTable6("less50",resultTable6)}
        {/*<p>В данной учебной группе наиболее актуальны ценности: {resultTable6.max} </p>
        <p>{resultTable6.less5}</p>
        <p style={{margin:0}}>
          Также мы видим общность и различия в эмоциональной индивидуальности учащихся. 
          Ценности общегрупповые характерные для большинства: {resultTable6.greater_equal75} 
          являются общими для максимального большинства учащихся.
        </p>  
        <p>Ценности: {resultTable6.less50} показывают на индивидуальные 
      предпочтения учащихся в восприятии окружающего мира.</p>*/}
        <p>Эмоциональная индивидуальность – 
          важнейшая характеристика человека. Если удается связать учебную деятельность с эмоциональной 
          направленностью ученика, то учебный процесс протекает с высокой вовлеченностью и 
          удовлетворением ученика и педагога и является наиболее эффективным.
        </p>
      </Alert>
      <br></br>
      <h4>{"Рисунок №4. Частота упоминания ценностных категорий. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)}
      </h4>
      <BarChart
        width={1000}
        height={450}
        data={Array.from(this.props.Tests[index].result.hist_personal_value_groups).sort((a,b)=>{
          return b.value-a.value})}
        margin={{
          top: 5, right: 30, left: 50, bottom: 150,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="group_name"
          tick={<CustomizedAxisTick />}
          interval={0}
        >
          <Label value="Ценности" offset={-140} position="insideBottom" />
        </XAxis>
        <YAxis label={{ value: 'Частота упоминания, в %', angle: -90, position: 'insideBottomLeft' }}> 
          <Label></Label>
        </YAxis>
        <Tooltip />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
      <br></br>
      {/*<Alert key={6.1} variant={'primary'}>
        <Alert.Heading>VI. Каковы терминальные ценности (ценности-цели) и инструментальные ценности 
          (ценности-средства достижения целей) в указанном классе (школе)?* 
        </Alert.Heading>
        <p>Ценности-цели (или терминальные ценности) проявляются в описаниях, людей-«идеалов». 
          Ценности-цели определяют жизненно важные выборы человека на далекую перспективу;</p>
        <p>Ценности-«средства» (или инструментальные ценности) проявляются в описаниях других людей 
          «лучше меня». Ценности-средства регулируют текущее поведение респондентов.</p>
        <p>Описания отвергаемых («антиидеальных») образов других людей указывают на свойства или качества, 
          препятствующие достижению ценностей-целей.</p>
      </Alert>*/}
      <Alert key={6.2} variant={'primary'}>
        <Alert.Heading>V. Какова преобладающая оценка социального мира в целом? 
        </Alert.Heading>
      </Alert>
      <h4>{"Таблица №5. Распределение оценочных категорий в восприятии социального мира. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
      <thead>
        <tr align="center">
          <th><p>Оценочная категория</p></th>
          <th><p style={{margin:0}}>Распределение (в %)</p>
            <p style={{margin:0}}>оценок в восприятии</p>
            <p style={{margin:0}}>других людей</p>
            <p>(в целом по группе)</p>
          </th>
          <th><p style={{margin:0}}>Респонденты с</p> 
            <p style={{margin:0}}>преобладанием</p>
            <p style={{margin:0}}>указанной категории в</p> 
            <p style={{margin:0}}>оценках других людей</p>
            <p>(кол-во человек)</p>
          </th>
          <th><p style={{margin:0}}>Респонденты с </p>
            <p style={{margin:0}}>отсутствием указанной </p>
            <p style={{margin:0}}>категории в оценках </p>
            <p style={{margin:0}}>других людей </p>
            <p>(кол-во человек)</p>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          this.props.Tests[index].result.corr_terminal_instrumental_values.map((ctiv,idx)=>{
            return (
              <tr key={idx}>
                <td>{ctiv.valuation_profile_name}</td>
                <td>{ctiv.frequency}</td>
                <td>{ctiv.respondents_number}</td>
                <td>{ctiv.without_respondents_number}</td>
              </tr>
            );
          })
        }
      </tbody>  
      </Table>
      <Alert key={6.3} variant={'primary'}>
        <p>{this.props.onGetTextForTable6A(index)}</p>
        <p>В описании табличных могут быть данных использованы результаты исследования подростков Курганской 
          гимназии №30, проведенного в 1999-2002гг. [Николаева И.А. Социальная ситуация развития личности 
          в образе социального мира подростка. – Курган: Изд-во Курганского гос.ун-та, 2009. – 148с].</p>
        <p style={{margin:0}}>Преобладание «идеалов» в образе социального мира:</p>
        <p style={{margin:0}}>- в возрасте 12-14 лет не было обнаружено как типичный вариант.</p>
        <p style={{margin:0}}>- в возрасте 14-15 лет обнаружено у подростков с преобладанием киногероев в образе социального 
          мира и с ориентацией на удовольствия (гедонизм) в сочетании с низкой самооценкой.</p>
        <p style={{margin:0}}>Минимальные значения «идеалов»:</p>
        <p style={{margin:0}}>- обнаружены в возрасте 12-13 лет у подростков с вербальной агрессией – т.е. с преобладанием 
          негативных вербальных характеристик в адрес других и с отсутствием познавательной мотивации 
          (ценности познания);</p>
        <p>- в возрасте 15-16 лет данная особенность является результатом несформированной ценностной 
          системы: идеалы как таковые вообще не выделяются, также как и другие оценочные категории.</p>
        <p style={{margin:0}}>Преобладание «тех, кто лучше меня»:</p>
        <p style={{margin:0}}>- в возрасте 12-14 лет сочеталось с отсутствием «антиидеалов» и встречалось только у подростков 
          с познавательной и эстетической направленностью и с общим позитивным восприятием социального 
          мира;</p>
        <p style={{margin:0}}>- в возрасте 14-16 лет встречалось в трех вариантах: а) у подростков с отсутствием 
          «антиидеалов» и заниженной самооценкой; б) у «мечтательных» девочек, ориентированных на свой 
          внутренний мир (мысли о кино- и литературных героях) и на женщин - педагогов и родственников. 
          Для этих девочек характерно отсутствием внимания к мальчикам (или вытеснение мыслей о них; в) 
          у подростков, ориентированных на успех и физическое совершенство, с позитивным эмоциональным 
          фоном.</p>
        <p style={{margin:0}}>Минимальные значения «тех, кто лучше меня»:</p>
        <p style={{margin:0}}>- в возрасте 12-14 лет встречаются в двух вариантах: а) у подростков с резко завышенной 
          самооценкой и с отсутствием нормативных признаков в описаниях других людей. (Вероятно, это 
          подростки с «оппозиционным» поведением); б) у подростков с ориентацией на мальчиков-сверстников, 
          с максимальным количеством «антиидеалов» и с игнорированием родителей, родственников и 
          взрослых женщин. Воспринимают других людей по их физическому развитию. (Наиболее вероятно, что 
          эти подростки имеют отсутствие любви и заботы в семье.);</p>
        <p>- в возрасте 14-16 лет как типичный вариант не встречалось.</p>
        <p style={{margin:0}}>Преобладание «равноценных мне» в образе социального мира:</p>
        <p style={{margin:0}}>- в возрасте 12-14 и 14-16 лет не встречалось</p>
        <p style={{margin:0}}>Минимальные значения «равноценных мне»:</p>
        <p style={{margin:0}}>- в возрасте 12-13 лет сочетается с ярко выраженными крайне негативными и максимально 
          позитивными оценками других. Мы трактуем это как несформированность ценностного оценивания: 
          отсутствие абстрактных идеализированных представлений о желаемом, должном, идеальном и 
          «антиидеальном», отсутствие представлений о возможной «равноценности» людей;</p>
        <p>- в возрасте 14-16 лет типично для подростков с недифференцированным социальным восприятием: 
          т.е. подростки не описывают других людей в богатой палитре характеристик ума, красоты, общения, 
          физических данных, доброты, волевых качеств, нормативности и пр. Для этих подростков характерны 
          однозначные оценочные высказывания: хороший/плохой, нормальный/классный/дурак и т.п. 
          (Вероятно несформированное социальное восприятие).</p>
        <p>Преобладание «тех, кто хуже меня» в образе социального мира не изучено.</p>
        <p style={{margin:0}}>Преобладание «антиидеалов» в образе социального мира:</p>
        <p style={{margin:0}}>- в возрасте 12-14 лет встречается у подростков, ориентированных на подростковую субкультуру 
          (мальчиков-сверстников), исключающих родителей и взрослых женщин, символизирующих доброту 
          и заботу;</p>
        <p style={{margin:0}}>- в возрасте 14-16 лет преобладания «антиидеалов» как типичной характеристики не встречалось.</p>
        <p style={{margin:0}}>Минимальные значения (отсутствие) «антиидеалов»:</p>
        <p style={{margin:0}}>- в возрасте 13-14 лет у подростков, ориентировнных на ценности познания и эстетического 
          восприятия действительности («розовые очки»);</p>
        <p>- в возрасте 14-16 лет у подростков с низкой самооценкой.</p>
      </Alert>
      <br></br>
      <Alert key={7.1} variant={'primary'}>
        <Alert.Heading>VI. Какова высота самооценки учащихся данной учебной группы? 
        </Alert.Heading>
      </Alert>
      <h4>{"Таблица №6. Распределение учащихся группы по самооценке. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
      <thead>
      <tr align="center">
        <th colSpan="4">Кол-во учащихся с разной самооценкой (абс. (%))</th>
      </tr>
        <tr align="center">
          <th>высокая</th>
          <th>нормальная</th>
          <th>ниже среднего</th>
          <th>низкая</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{String(this.props.Tests[index].result.hist_self_esteems[0].value)+
            " ("+String(this.props.Tests[index].result.hist_self_esteems[0].percent)+"%)"}</td>
          <td>{String(this.props.Tests[index].result.hist_self_esteems[2].value)+
            " ("+String(this.props.Tests[index].result.hist_self_esteems[2].percent)+"%)"}</td>
          <td>{String(this.props.Tests[index].result.hist_self_esteems[3].value)+
            " ("+String(this.props.Tests[index].result.hist_self_esteems[3].percent)+"%)"}</td>
          <td>{String(this.props.Tests[index].result.hist_self_esteems[1].value)+
            " ("+String(this.props.Tests[index].result.hist_self_esteems[1].percent)+"%)"}</td>
        </tr>
      </tbody>  
      </Table>
      <br></br>
      <h4>{"Рисунок №6. Распределение учащихся группы по высоте самооценки. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)}
      </h4>
      <BarChart
        width={1000}
        height={300}
        data={Array.from(this.props.Tests[index].result.hist_self_esteems).sort((a,b)=>{return b.percent-a.percent})}
        margin={{
          top: 5, right: 30, left: 20, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="self_esteem_type_name">
          <Label value="категории" offset={-10} position="insideBottom" />
        </XAxis>
        <YAxis label={{ value: 'Частота упоминания, в %', angle: -90, position: 'insideBottomLeft' }}> 
          <Label></Label>
        </YAxis>
        <Tooltip />
        <Bar dataKey="percent" fill="#8884d8" />
      </BarChart>
      <br></br>
      <br></br>
      <Alert key={8.1} variant={'primary'}>
        <Alert.Heading>VII. Дополнительная информация. Первичные данные 
        </Alert.Heading>
      </Alert>
      <h4>{"Таблица №7. Обобщенные социально-психологические портреты. Класс: "+
        String(this.props.Tests[index].student_group)+". Школа: "+
        String(this.props.Tests[index].school)+". Количество респондентов: "+
        String(this.props.Tests[index].result.student_count)+", из них юношей: "+
        String(this.props.Tests[index].result.males)+", девушек: "+
        String(this.props.Tests[index].result.females)+". Количество персонажей: "+
        String(this.props.Tests[index].result.person_count)}
      </h4>
      <Table responsive="sm" striped bordered hover size="sm">
      <thead>
        <tr align="center">
          <th>Ценностная оценка</th>
          <th>Социальная категория</th>
          <th>Социально-перцептивные характеристики</th>
          <th>Личностные ценности</th>
        </tr>
      </thead>
      <tbody>
        {
          this.props.Tests[index].result.soc_portraits.map((sp,idx)=>{
            return (
              <tr key={idx}>
                <td>{sp.valuation_profile_name}</td>
                <td>{sp.roles}</td>
                <td>{sp.characteristics}</td>
                <td>{sp.groups}</td>
              </tr>
            );
          })
        }
      </tbody>  
      </Table>
    </div>;
    }
  }

  showTestResult() {
    if (this.props.Tests.length!==0) {
      
    return <div key="showTestResult">
      {this.props.Tests.map((test,index)=>{
        let showTableResult=this.showTableResult(test.id,index)
        return (<div key={index}>
        <Button
          onClick={() => {this.props.onChangeCollapseTest(test.id,!test.collapse)}}
          aria-controls={test.id}
          aria-expanded={test.collapse}
          block
          >
          {String("Результаты теста для класса "+test.student_group+", начало: "+
             this.getDateTime(test.start)+
             " завершение: "+this.getDateTime(test.finish)
           )
          }
        </Button>
        <br></br>
        <Collapse in={test.collapse}>
          <div key={test.id} id={test.id}>
            {showTableResult}
          </div>
        </Collapse>
        <br></br>
        </div>);
      })
      }
    </div>;
    }
    else return null;
  }

  renderListStudentGroups(){
    let list
    if(this.props.User.type==="SchoolPsychologist")
      list=this.props.StudentGroups.filter(t=>t.school_id===this.props.User.school_id)
        .map((studentgroup,idx)=>{
          return (
            <option id={studentgroup.id} key={idx+1} >{studentgroup.name}</option>
          )
        })
    if(this.props.User.type==="Psychologist"||this.props.User.type==="Admin")
      list=this.props.StudentGroups.map((studentgroup,idx)=>{
        return (
          <option id={studentgroup.id} key={idx+1} >{studentgroup.name}</option>
        )
      })
    return list;
  }

  renderTableTests() {
    let tableTests
    let _StudentGroupCF,_Finish,_Start
    if(this.props.User.type==="SchoolPsychologist"||this.props.User.type==="Admin") {
    tableTests=<div key="tableTests" style={{ margin: 'auto', width: 'fit-content' }}>
      <CRUDTable  caption="Активные тесты классов"
       actionsLabel="Действия"
       items={Array.from(this.props.Tests)}
      >
      <Fields>
        <Field
          name="student_group"
          label="Класс"
          render={({field})=>
            <select ref={input=>_StudentGroupCF=input} 
              defaultValue="Выберите класс" {...field} size="1">
              <option key="0" disabled={true} defaultValue="Выберите класс">Выберите класс</option>
              {this.renderListStudentGroups()} 
            </select>
          }
        />
      <Field
        name="start"
        label="Начало теста"
        type="date"
        render={({field})=><input ref={input=>_Start=input}
          type="datetime-local"
          defaultValue={field.value}
          ></input>
        }
      />
      <Field
        name="finish"
        label="Завершение теста"
        type="date"
        render={({field})=><input ref={input=>_Finish=input}
          type="datetime-local"
          defaultValue={field.value}
          ></input>
        }
      />
      </Fields>
      <CreateForm
       title="Параметры теста"
       trigger="Разрешить классу тестирование"
       onSubmit={task => {
        let dt_start=new Date(Number(_Start.value.substring(0,4)),Number(_Start.value.substring(5,7))-1,
          Number(_Start.value.substring(8,10)),Number(_Start.value.substring(11,13)),
          Number(_Start.value.substring(14,16)))
        let dt_finish=new Date(Number(_Finish.value.substring(0,4)),
          Number(_Finish.value.substring(5,7))-1,
          Number(_Finish.value.substring(8,10)),Number(_Finish.value.substring(11,13)),
          Number(_Finish.value.substring(14,16)))
        this.props.onPostFetchTest(process.env.REACT_APP_URL+"/tests",
           {student_group_id:_StudentGroupCF.options[_StudentGroupCF.selectedIndex].id,
                start:dt_start.toISOString(),finish:dt_finish.toISOString()})
        return Promise.resolve(task);
       }}
        submitText="Разрешить тестирование"
        validate={(values) => {
         const errors = {};
         //if (!values.student_group) {errors.title = 'Please, provide task\'s title'; }
         //if (!values.form_date) {errors.description = 'Please, provide task\'s description';}
         //if (!values.school) {errors.description = 'Please, provide task\'s description';}
         return errors;
        }}
        />
      <UpdateForm
        title="Изменение атрибутов теста"
        trigger="Изменить"
        onSubmit={data => {
         this.props.onUpdateFetchTest(process.env.REACT_APP_URL+"/tests/"+data.id.toString(10),
           {id:data.id,student_group_id:_StudentGroupCF.options[_StudentGroupCF.selectedIndex].id,
             start:_Start.value,finish:_Finish.value})
         return Promise.resolve(data);
        }}
        submitText="Изменить"
        validate={(values) => {
         const errors = {};
         if (!values.start) {errors.title = 'Please, provide task\'s title'; }
         return errors;
        }}
      />
      <DeleteForm
        title="Удаление теста"
        message="Вы уверены, что хотите удалить этот тест?"
        trigger="Удалить"
        onSubmit={data => {
        this.props.onDeleteFetchTest(process.env.REACT_APP_URL+"/tests/"+data.id.toString(10),data)
          return Promise.resolve(data);
        }}
        submitText="Удалить"
        validate={(values) => {
          const errors = {};
          if (!values.id) {errors.id = 'Please, provide id';}
          return errors;
        }}
      />
      </CRUDTable>
    </div>
    } else {
    tableTests=<div key="tableTests_else" style={{ margin: 'auto', width: 'fit-content' }}>
      <CRUDTable  caption="Активные тесты классов"
       actionsLabel="Действия"
       items={Array.from(this.props.Tests)}
      >
      <Fields>
        <Field
          name="student_group"
          label="Класс"
          render={({field})=>
            <select ref={input=>_StudentGroupCF=input} 
              defaultValue="Выберите класс" {...field} size="1">
              <option key="0" disabled={true} defaultValue="Выберите класс">Выберите класс</option>
              {this.renderListStudentGroups()} 
            </select>
          }
        />
      <Field
        name="start"
        label="Начало теста"
        type="date"
        render={({field})=><input ref={input=>_Start=input}
          type="datetime-local"
          defaultValue={field.value}
          ></input>
        }
      />
      <Field
        name="finish"
        label="Завершение теста"
        type="date"
        render={({field})=><input ref={input=>_Finish=input}
          type="datetime-local"
          defaultValue={field.value}
          ></input>
        }
      />
      </Fields>
      </CRUDTable>
    </div>
    }
    return tableTests;
  }

  render() {
    let showResultTest=this.showTestResult()
    return <div key="main_div_psychologist">
      <Container><Row><Col>
      {this.renderTableTests()}
      </Col></Row>
      <Row><Col>
        <hr></hr>
        {showResultTest}
      </Col></Row>
      </Container>
    </div>;
   
    }
  }
  
  export default Psychologist