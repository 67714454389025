import C from '../actions/constants'
//преобразователь действия
export const createT = (state={},action) => {
  switch (action.type) {
   
    case C.CHANGE_ZINDEX_PERSON: {
      return {
      
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: action.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
     case C.CHANGE_POSITION: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: action.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_ROLES: {
      return {
        Roles : action.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_SCHOOLS: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: action.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: action.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.POST_FETCH_SCHOOLS: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: action.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: action.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_CITIES: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: action.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_STUDENTGROUPS: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: action.StudentGroups,
        tempStudentGroups: action.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_СHARACTERISTICS: {
      return {
        Roles : state.Roles,
        Сharacteristics : action.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.SET_CURRENT_CITY_ID: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: action.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: action.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.SET_CURRENT_SCHOOL_ID: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: action.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: action.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.SET_CURRENT_STUDENTGROUP_ID: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: action.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: action.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_STUDENTS: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: action.Students,
        tempStudents: action.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_PVG: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: action.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_STUDENT_TEST: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: action.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: action.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    
    case C.GET_FETCH_PERSON: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: action.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: action.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: action.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.SET_CURRENT_PERSON_ID: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: action.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.SET_REGFLAG: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: action.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    case C.GET_FETCH_TESTS: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: action.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
    
    case C.GET_FETCH_USER: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: action.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }
   
    case C.GET_FETCH_PERSON_ROLE: {
      return {
      
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: action.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
       
      }
    }
    case C.GET_FETCH_PERSON_CHAR: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: action.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.DELETE_FETCH_PERSON: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: action.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: action.Persons,
        CurrentPersonId: action.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: action.PersonsRoles,
        PersonsChar: action.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }

    }
    case C.DELETE_FETCH_USER: {
      return {
        Roles : [],
        Сharacteristics : [],
        CurrentZIndex: 1,
        Schools: [],
        Cities: [],
        CurrentCityId: -1,
        CurrentSchoolId: -1,
        CurrentStudentGroupId: -1,
        tempSchools: [],
        StudentGroups: [],
        tempStudentGroups: [],
        Students: [],
        tempStudents: [],
        PVG: [],
        Positivity: [{value:0,name:"нейтральная"},{value:1,name:"положительная"},{value:-1,name:"отрицательная"}],
        Sex: [{value:"male",name:"мужской"},{value:"female",name:"женский"}],
        StudentTest: [],
        Persons: [],
        CurrentPersonId: -1,
        RegFlag: {reg:false,about:true},
        Tests: [],
        User: [],
        PersonsRoles: [],
        PersonsChar: [],
        StartFlag: false,
        ActivePagination: 1,
        ActiveTabPane: "#testing/link1",
        Users: [],
        ShowModalFormsAdmin: {add:false,edit:false,del:false,type:"Admin"},
        CharComplete: {currentChar:"",suggestChars:[]},
        PersonsTab: [],
        PaginationTable:{student:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        PaginationTable2:{studentgroup:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        PaginationTable3:{school:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        Words: [],
        CityTypes: [],
        Districts: [],
        SchoolTypes: [],
        Ages: [{value:"9",name:"9-классники"},{value:"10",name:"10-классники"},{value:"11",name:"11-классники"},{value:"старше",name:"старше"}],
        FiltersDS: [],
        BuildFilterDS: {},
        FiltersCS: [],
        BuildFilterCS: {},
        FiltersTS: [],
        BuildFilterTS: {},
      }
    }

    case C.NEW_START_TEST: {
      return {
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: [],
        Persons: [],
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: [],
        PersonsChar: [],
        StartFlag: false,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: [],
        PaginationTable:{student:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        PaginationTable2:{studentgroup:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        PaginationTable3:{school:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
        Words: [],
        CityTypes: [],
        Districts: [],
        SchoolTypes: [],
        Ages: state.Ages,
        FiltersDS: [],
        BuildFilterDS: {},
        FiltersCS: [],
        BuildFilterCS: {},
        FiltersTS: [],
        BuildFilterTS: {},
      }
    }

    case C.CHANGE_PAGINATION: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: action.ActivePagination,
        ActiveTabPane: action.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_FETCH_USERS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: action.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.SHOW_MODAL_FORMS_ADMIN: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: action.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CHAR_COMPLETE: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: action.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CHANGE_PAGINATION_TABLE: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:action.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CHANGE_PAGINATION_TABLE2: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:action.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CHANGE_PAGINATION_TABLE3: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:action.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_WORDS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: action.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_CITYTYPES: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: action.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_DISTRICTS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: action.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_SCHOOLTYPES: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: action.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_FILTERS_DS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: action.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.BUILD_FILTER_DS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: action.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_FILTERS_CS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: action.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.BUILD_FILTER_CS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: action.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.CRUD_FILTERS_TS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: action.FiltersTS,
        BuildFilterTS: state.BuildFilterTS,
      }
    }

    case C.BUILD_FILTER_TS: {
      return {
       
        Roles : state.Roles,
        Сharacteristics : state.Сharacteristics,
        CurrentZIndex: state.CurrentZIndex,
        Schools: state.Schools,
        Cities: state.Cities,
        CurrentCityId: state.CurrentCityId,
        CurrentSchoolId: state.CurrentSchoolId,
        CurrentStudentGroupId: state.CurrentStudentGroupId,
        tempSchools: state.tempSchools,
        StudentGroups: state.StudentGroups,
        tempStudentGroups: state.tempStudentGroups,
        Students: state.Students,
        tempStudents: state.tempStudents,
        PVG: state.PVG,
        Positivity: state.Positivity,
        Sex: state.Sex,
        StudentTest: state.StudentTest,
        Persons: state.Persons,
        CurrentPersonId: state.CurrentPersonId,
        RegFlag: state.RegFlag,
        Tests: state.Tests,
        User: state.User,
        PersonsRoles: state.PersonsRoles,
        PersonsChar: state.PersonsChar,
        StartFlag: state.StartFlag,
        ActivePagination: state.ActivePagination,
        ActiveTabPane: state.ActiveTabPane,
        Users: state.Users,
        ShowModalFormsAdmin: state.ShowModalFormsAdmin,
        CharComplete: state.CharComplete,
        PersonsTab: state.PersonsTab,
        PaginationTable:state.PaginationTable,
        PaginationTable2:state.PaginationTable2,
        PaginationTable3:state.PaginationTable3,
        Words: state.Words,
        CityTypes: state.CityTypes,
        Districts: state.Districts,
        SchoolTypes: state.SchoolTypes,
        Ages: state.Ages,
        FiltersDS: state.FiltersDS,
        BuildFilterDS: state.BuildFilterDS,
        FiltersCS: state.FiltersCS,
        BuildFilterCS: state.BuildFilterCS,
        FiltersTS: state.FiltersTS,
        BuildFilterTS: action.BuildFilterTS,
      }
    }
  
    default: return state
  }
}

