import {connect} from 'react-redux'
import Registration from '../components/Registration'
//import { Registration } from './pages'

export const NewRegistration = connect (
 
  state=>({
   
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    Answers : state.Answers,
    CurrentZIndex : state.CurrentZIndex,
    Tasks: state.Tasks,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    RegFlag: state.RegFlag,
    Tests: state.Tests,
    User: state.User,
  }),
  
  dispatch => ({
   
    onPostFetchRegistration(url,data) {
      let RegFlag=this.RegFlag.reg
        fetch(url, {
            method: 'POST',
            mode: 'cors',
            credentials: 'same-origin',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify(data),
          })
          .then(response=> {
            if(!response.ok) {
              response.json().then(response=>{alert(response.errors.join("\n"));
                })
            }
            if(response.ok){//console.log(response.statusText);
                alert("Вы успешно зарегистрировались в системе. После закрытия этого окна вы будете перенаправлены на страницу ввода учетных данных")
                RegFlag=true
            }
            dispatch({type : 'SET_REGFLAG', RegFlag: {reg:RegFlag,about:this.RegFlag.about}})
            return response;
          })
      },

      onSetRegFlag(regflag) {
        dispatch({type : 'SET_REGFLAG', RegFlag: regflag})
      },
   
  })
) (Registration)
