import React from 'react';
//import Button from './Button';
//import Draggable from 'react-draggable';
//import CRUDTable, { Fields, Field, CreateForm, UpdateForm, DeleteForm,} from 'react-crud-table';
//import Form from 'react-bootstrap/Form'
//import Button from 'react-bootstrap/Button'
import {Form, Button, Container, Row, Col, Alert} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom'

class Registration extends React.Component {
  constructor(props) {
      super(props)
      this.onMouseDown=this.onMouseDown.bind(this)
      this.onStop=this.onStop.bind(this)
  }
  
  
    componentDidMount() {
     // this.props.onGetFetchRoles("http://psi.poas45.ru/roles");
     // this.props.onGetFetchStudentTest(process.env.REACT_APP_URL+"/student_tests");
     // this.props.onGetFetchPersons(process.env.REACT_APP_URL+"/persons");
    }
   
    onMouseDown(e) {
      //console.log(e.target.style.zIndex);
      //this.props.onChangeZIndexAnswer(e.target.style.zIndex,this.props.Answers);
     // this.props.onChangeZIndexPerson(e.target.style.zIndex);
      //e.target.style.zIndex=1;
    }
    onStop(e, position) {
     // const {x, y} = position;
      //console.log("x="+x.toString(10)+"y="+y.toString(10));
      //console.log(e.target.style.zIndex);
     // this.props.onChangePosition(x/588,1-y/500)
      //раньше эта строка работала //this.handleStop;
    }

  renderAbout(){
    return (
      <Container>
        <Row><Col>
          <Alert key={1} variant={'primary'}>
            <Alert.Heading align="center">Уважаемый пользователь!  
            </Alert.Heading>
            <p align="center">Вам предложено участвовать в качестве респондента на этапе разработки Web-приложения 
              для изучения особенностей восприятия человеком окружающего социального мира.</p>
            <p align="center">Данная версия является пробной.</p>
            <p align="center">Участие в проекте анонимное – имена и фамилии участников нигде не отмечаются. Каждый участник 
              проекта получает индивидуальную характеристику онлайн. Индивидуальная характеристика доступна 
              только самому участнику.
            </p>
            <p align="center">Регистрируемые данные: литер класса (на этапе разработки условный) будет указан изначально 
              (чтобы можно было отличить социально-психологические особенности классов). Также «токены» 
              распределяются с учетом пола, т.к. интересно знать, чем «социальный мир» девушек отличается от 
              «мира» юношей.</p>
            <p align="center"> «Все индивидуальные данные суммируются (обобщаются) в данные по отдельной учебной группе 
              (по классу) или по школе в целом. В таком виде они будут доступны психологу школы 
              (колледжа или вуза). Эти обобщенные данные также могут быть доступны всем заинтересованным 
              лицам. Также можно вместе с классом обсудить какой «мир» в нашем классе сейчас, и каким мы 
              хотели бы, чтобы он был, и что мы можем сделать для этого»</p>
            <p align="center">Вход в проект по токену. Логин и пароль задаете самостоятельно.</p>
            <p align="center">Участие предполагает выполнение трех заданий. Общее время выполнения индивидуально 
              (от 30 мин). Важно выполнить задания до последней даты, которая назначена в проекте, 
              чтобы система составила характеристику.</p>
            <p align="center">Спасибо за помощь в апробации проекта!</p>
          </Alert>
          <Button variant="primary" type="submit"
            onClick={() => {
              this.props.onSetRegFlag({reg:this.props.RegFlag.reg,about:false})
            }}
          >Перейти к форме регистрации
          </Button>
        </Col></Row>
      </Container>
    )
  }

  render() {
    let _Login,_Password,_Token,ErrMessage="";//, RegFlag=true;
    if(this.props.RegFlag.about===true) {return <div>{this.renderAbout()}</div>;}
    else if(this.props.RegFlag.reg===false)
    {
    return <div>
      <Form>
        <Form.Group controlId="formBasicLogin">
          <Form.Label>Введите свой псевдоним</Form.Label>
          <Form.Control ref={input=>_Login=input} type="text" placeholder="Псевдоним" />
          <Form.Text className="text-muted">
            Никому не сообщайте свой псевдоним
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Введите пароль</Form.Label>
          <Form.Control ref={input=>_Password=input} type="password" placeholder="Пароль" />
        </Form.Group>
        <Form.Group controlId="formBasicToken">
          <Form.Label>Введите полученный токен</Form.Label>
          <Form.Control ref={input=>_Token=input} type="password" placeholder="Токен" />
        </Form.Group>
       
      </Form>
      <Button variant="primary" type="submit"
          onClick={() => {
            if(_Login.value==="") ErrMessage+="Необходимо ввести псевдоним\n"
            if(_Password.value==="") ErrMessage+="Необходимо ввести пароль\n"
            if(_Token.value==="") ErrMessage+="Необходимо ввести токен\n"
            if(ErrMessage!=="") {alert(ErrMessage);ErrMessage=""}
            else { this.props.onPostFetchRegistration(process.env.REACT_APP_URL+"/sign_up",
              {login:_Login.value, password:_Password.value, token:_Token.value})
              ErrMessage=""
            }
          }}
        >Зарегистрироваться
        </Button>
    </div>;
    }
    else{
      this.props.onSetRegFlag({reg:false,about:true})
      return <Redirect to="/login" />;
      }
    }
  }
  
  
  export default Registration