import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom'

var authFlag=false

class Authorization extends React.Component {
  constructor(props) {
      super(props)
      //this.onMouseDown=this.onMouseDown.bind(this)
      //this.onStop=this.onStop.bind(this)
  }
  
  componentDidMount() {
     // this.props.onGetFetchRoles("http://psi.poas45.ru/roles");
     // this.props.onGetFetchStudentTest(process.env.REACT_APP_URL+"/student_tests");
     // this.props.onGetFetchPersons(process.env.REACT_APP_URL+"/persons");
  }
   
  render() {
    let _Login,_Password,ErrMessage="";//,authFlag=false;//, RegFlag=true;
    if(this.props.User.length===0||this.props.User.validateToken===false)
    {
      //console.log("authflag: "+String(authFlag))
    return <div>
      <Form>
        <Form.Group controlId="formBasicLogin">
          <Form.Label>Введите свой псевдоним</Form.Label>
          <Form.Control ref={input=>_Login=input} type="text" placeholder="Псевдоним" />
          <Form.Text className="text-muted">
            Никому не сообщайте свой псевдоним
          </Form.Text>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Введите пароль</Form.Label>
          <Form.Control ref={input=>_Password=input} type="password" placeholder="Пароль" />
          <Form.Text className="text-muted">
            Никому не сообщайте свой пароль
          </Form.Text>
        </Form.Group>
      </Form>
      <Button variant="primary" type="submit"
          onClick={() => {
            if(_Login.value==="") ErrMessage+="Необходимо ввести псевдоним\n"
            if(_Password.value==="") ErrMessage+="Необходимо ввести пароль\n"
            if(ErrMessage!=="") {alert(ErrMessage);ErrMessage=""}
            else {
              if (this.props.onPostFetchAuth(process.env.REACT_APP_URL+"/auth/sign_in",
                {login:_Login.value, password:_Password.value})===-1) { 
                ErrMessage=""
                //console.log("-1")
              }
              else {
                ErrMessage=""
                authFlag=true
              }
            }
          }}
        >Войти
        </Button>
    </div>;
    }
    if(this.props.User.length!==0&&authFlag)
    {
      authFlag=false;
      if(this.props.User.type==="Psychologist") return <Redirect to="/psychologist" />;
      if(this.props.User.type==="SchoolPsychologist") return <Redirect to="/psychologist" />;
      if(this.props.User.type==="StudentUser") return <Redirect to="/testing" />;
      if(this.props.User.type==="Admin") return <Redirect to="/admin" />;
      if(this.props.User.type==="SchoolAdmin") return <Redirect to="/directories" />;
      if(this.props.User.type==="SuperPsychologist") return <Redirect to="/newchars" />;
    }
    if(this.props.User.length!==0&&!authFlag)
    {
      return(
        <Button variant="primary" type="submit"
          onClick={() => {
              this.props.onDeleteFetchAuth(process.env.REACT_APP_URL+"/auth/sign_out")
            }
          }
        >Выйти
        </Button> 
      );
    }
    }
  }
  
  
  export default Authorization