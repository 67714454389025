import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Redirect} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import {Modal} from 'react-bootstrap';
import {MDBIcon} from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import '../stylesheets/Classification.css';

    

class Classification extends React.Component {
  constructor(props) {
      super(props)
  }
  
  componentDidMount() {
    this.props.onShowModalFormsAdmin(false,false,false,"Word");
    this.props.onGetFetchWords(process.env.REACT_APP_URL+"/words");
    this.props.onGetFetchPVG(process.env.REACT_APP_URL+"/personal_value_groups");
  }

  editForm() {
    let _Resource,_Value,_Rejected,_PVG,_Positivity,ErrMessage=""

    if(this.props.ShowModalFormsAdmin.edit) {
      return (
      <Modal size="sm" show={this.props.ShowModalFormsAdmin.edit} 
        onHide={()=>this.props.onShowModalFormsAdmin(false,false,false,"Word")}
        >
        <Modal.Header closeButton>
          <Modal.Title>Дополнительная информация для слова</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formListPVG">
            <Form.Label>Эмоциональная категория</Form.Label>
            <Form.Control as="select" ref={input=>_PVG=input}>
            {
              this.props.PVG.map((pvg,idx)=>{
                return <option key={idx} value={pvg.id}>{pvg.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formListPositivity">
            <Form.Label>Позитивность</Form.Label>
            <Form.Control as="select" ref={input=>_Positivity=input}>
            {
              this.props.Positivity.map((positiv,idx)=>{
                return <option key={idx} value={positiv.value}>{positiv.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
          {//возможность добавлять ценность пока закоментирована. может быть когда-нибудь понадобится
            /*<Form.Group controlId="formValue">
            <Form.Label>Заполните ценность</Form.Label>
            <Form.Control ref={input=>_Value=input} type="text" placeholder="Ценность"/>
          </Form.Group>*/}
          <Form.Group controlId="formResource">
            <Form.Label>Заполните ресурс</Form.Label>
            <Form.Control ref={input=>_Resource=input} type="text" placeholder="Ресурс"/>
          </Form.Group>
           <Form.Group controlId="formRejected">
            <Form.Label>Заполните отвергаемое</Form.Label>
            <Form.Control ref={input=>_Rejected=input} type="text" placeholder="Отвергаемое"/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            onClick={()=>this.props.onShowModalFormsAdmin(false,false,false,"Word")
            }> Отмена </Button>
          <Button variant="primary" onClick={()=>{
            this.props.onPostFetchNewChars(process.env.REACT_APP_URL+"/new_characteristics",
              {"word_id": Number(this.props.ShowModalFormsAdmin.id),
              "personal_value_group_id": Number(_PVG.value),
              "positivity": Number(_Positivity.value),
              //"value": _Value.value,
              "resources": _Resource.value,
              "rejected": _Rejected.value})
            this.props.onDeleteWords(this.props.ShowModalFormsAdmin.id)
            this.props.onShowModalFormsAdmin(false,false,false,"Word")
          }}> Классифицировать</Button>
        </Modal.Footer>
      </Modal>
      );
    } else return null;
  }

  getTDAction(word_id) {
      return (
        <td>
        <Container>
          <Row>
            <Col><Button variant="primary" type="submit" id={word_id} onClick={(e)=>{
                this.props.onShowModalFormsAdmin(false,true,false,"Word",word_id)
              }}> 
              <MDBIcon id={word_id} far icon="edit"/>
            </Button></Col>
          </Row>
        </Container>
        </td>
      );
  }


  render() {
           
    return <div key="home">
      <Container>
        <Row><Col>
            <Alert variant="primary">
              <Alert.Heading>Классификация новых слов</Alert.Heading>
            </Alert>
          </Col></Row>
          <Row>
            <Col>
            <Table responsive striped size="lg">
              <thead>
                <tr align="center">
                  <th>#</th>
                  <th>Слово</th>
                  <th>Частота упоминания</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
              {
                this.props.Words.map((word,idx)=>{
                  return (
                    <tr align="center" key={idx} id={word.id}>
                      <td>{idx+1}</td>
                      <td>{word.name}</td>
                      <td>{word.ref_number}</td>
                      {
                        this.getTDAction(word.id)
                      }
                    </tr>
                  );
                })
              }
              </tbody>  
            </Table>
            {/*this.addFormAdmin()*/}
            {this.editForm()}
          </Col></Row>
        </Container>
      </div>;
    }
  }
  
  
  export default Classification