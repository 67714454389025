import {createStore,/*combineReducers,*/applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import {createT} from '../reducers/reducer'

//console.log(localStorage["reduxe-store"])

const stateData = {
 
  Сharacteristics : [],
  Roles: [],
   CurrentZIndex: 1,
    Schools: [],
    Cities: [],
    CurrentCityId: -1,
    CurrentSchoolId: -1,
    CurrentStudentGroupId: -1,
    tempSchools: [],
    StudentGroups: [],
    tempStudentGroups: [],
    Students: [],
    tempStudents: [],
    PVG: [],
    Positivity: [{value:0,name:"нейтральная"},{value:1,name:"положительная"},{value:-1,name:"отрицательная"}],
    Sex: [{value:"male",name:"мужской"},{value:"female",name:"женский"}],
    StartFlag: false,
    StudentTest: [],
    Persons: [],
    CurrentPersonId: -1,
    RegFlag: {reg:false,about:true},
    Tests:[],
    User: [],
    PersonsRoles: [],
    PersonsChar: [],
    ActivePagination: 1,
    ActiveTabPane: "#testing/link1",
    Users: [],
    ShowModalFormsAdmin: {add:false,edit:false,del:false,type:"Admin"},
    CharComplete: {currentChar:"",suggestChars:[]},
    PersonsTab: [],
    PaginationTable:{student:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
    PaginationTable2:{studentgroup:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
    PaginationTable3:{school:{start:0,end:2,itemsPerPage:5,activePage:1,data:[]}},
    Words:[],
    CityTypes: [],
    Districts: [],
    SchoolTypes: [],
    Ages: [{value:"9",name:"9-классники"},{value:"10",name:"10-классники"},{value:"11",name:"11-классники"},{value:"старше",name:"старше"}],
    FiltersDS: [],
    BuildFilterDS: {},
    FiltersCS: [],
    BuildFilterCS: {},
    FiltersTS: [],
    BuildFilterTS: {},
}

const logger= store => next => action => {
  // eslint-disable-next-line
  let result;
  console.groupCollapsed('dispatching', action.type)
  console.log('prev state',store.getState())
  console.log('action',action)
  result=next(action)
  console.log('next state',store.getState())
  console.groupEnd()
}

const saver = store => next => action => {
  let result=next(action)
  if (get_type_browser())localStorage['reduxe-store-test7'] = JSON.stringify(store.getState())
  return result
}

function get_type_browser () {
  // получаем данные userAgent
  var ua = navigator.userAgent;    
  // с помощью регулярок проверяем наличие текста,
  // соответствующие тому или иному браузеру
  if (ua.search(/Chrome/) !== -1) return 1//'Google Chrome';
  if (ua.search(/Firefox/)!== -1) return 1//'Firefox';
  if (ua.search(/Opera/) !== -1) return 1//'Opera';
  if (ua.search(/Safari/)!== -1) return 1//'Safari';
  if (ua.search(/MSIE/)!== -1) return 0//'Internet Explorer';
  if (ua.search(/Trident/)!== -1) return 0//'Internet Explorer';
  // условий может быть и больше.
  // сейчас сделаны проверки только 
  // для популярных браузеров
  return 0//'Не определен';
}
// вместо createT может быть combineReducers
const storeFactory = (initialState=stateData) => composeWithDevTools(applyMiddleware (thunk, logger, saver)) 
  (createStore)(
    createT, 
    (get_type_browser()) ? 
      (localStorage['reduxe-store-test7']) ? 
        JSON.parse(localStorage['reduxe-store-test7'])
        :initialState
     :initialState
)

//const storeFactory = (initialState=stateData) => applyMiddleware (logger, saver) (createStore)(
//  /*combineReducers({*/createM/*,changeCell*//*})*//*(initialState,true)*/,/*(localStorage['reduxe-store']) ? JSON.parse(localStorage['reduxe-store']) :*/ initialState//stateState
//)
export default storeFactory
