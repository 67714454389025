import React from 'react';
import {Redirect} from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Alert from 'react-bootstrap/Alert'
import Tab from 'react-bootstrap/Tab'
import ListGroup from 'react-bootstrap/ListGroup'
import Pagination from 'react-bootstrap/Pagination'
import Form from 'react-bootstrap/Form'
//import Pageheader from 'react-bootstrap/pag';
//import ResponsiveEmbed from 'react-bootstrap/lib/ResponsiveEmbed';
import Draggable from 'react-draggable';
//import CRUDTable, { Fields, Field, CreateForm, UpdateForm, DeleteForm,} from 'react-crud-table';
//import {LineChart, YAxis, LabelList} from 'recharts';
import {AutoComplete} from 'primereact/autocomplete';
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/nova-light/theme.css'
import 'primeicons/primeicons.css'
import '../stylesheets/LoadTest.css';


class LoadTest extends React.Component {
  constructor(props) {
    super(props)
    this.onMouseDown=this.onMouseDown.bind(this)
    this.onStop=this.onStop.bind(this)
    this.onDrag=this.onDrag.bind(this)
  }

  componentDidMount() {

    this.props.onGetFetchRoles(process.env.REACT_APP_URL+"/roles")
    this.props.onGetFetchСharacteristics(process.env.REACT_APP_URL+"/characteristics")
    this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups")
  }
  
  onDrag(e, position) {
    const {x/*, y*/} = position;
    this.props.onChangeColor(x/((784-98)/2))
  }

  onMouseDown(e) {
    this.props.onChangeZIndexPerson(e.target.style.zIndex);
  }

  onStop(e, position) {
    const {x, y} = position;
    this.props.onChangePosition(x/((784-98)/2),1-y/(550-25))
  }

  getDateTime(datetime) {
    let newDateTime=""
    newDateTime=datetime.substring(8,10)+"."+datetime.substring(5,7)+"."+datetime.substring(0,4)+" "+
      datetime.substring(11,16)
    //console.log(newDateTime)
    return newDateTime;
  }

  showResult() {
    let result1
    if(this.props.StudentTest.length!==0&&this.props.StudentTest[0].student_test_result_id!==null) 
    {
      result1=this.props.onGetTextForResult1()
      return (
        <Container><Row><Col>
        <h1>Ваши результаты:</h1>
        <Alert key={1} variant={'primary'}>
          <Alert.Heading>I. Где происходят основные события вашей жизни?</Alert.Heading>
          <p>1.1. Ваша жизнь связана:{result1._1_1}</p>
          <p>1.2. Ваши мысли чаще заняты:{result1._1_2}</p>
          <p>1.3. Области интересов:{result1._1_3}</p>
          <p>1.4. Вне зоны интересов категории: 
            {String(" "+this.props.StudentTest[0].test_result.first_part_result.empty)} </p>
        </Alert>
        <Alert key={2} variant={'secondary'}>
          <Alert.Heading>II. Ценности и цели</Alert.Heading>
          <p>2.1. Ведущие ценности: 
            {" "+String(this.props.StudentTest[0].test_result.second_part_result.leading_values)}</p>
          <p>2.2. Личные ресурсы в достижении целей:
            {" "+String(this.props.StudentTest[0].test_result.second_part_result.personal_resources)}</p>
        </Alert>
        <Alert key={3} variant={'success'}>
          <Alert.Heading>III. Общий взгляд на мир</Alert.Heading>
          <p>3.1. Оптимизм: 
            {" "+String(this.props.StudentTest[0].test_result.third_part_result.optimist)}</p>
          <p>3.2. Самооценка:
            {" "+String(this.props.StudentTest[0].test_result.third_part_result.self_esteem)}</p>
          <p>3.3. Чувство уникальности или общность с другими: 
            {" "+String(this.props.StudentTest[0].test_result.third_part_result.equivalent)}</p>
          <p>3.4. Представления об идеалах:
            {" "+String(this.props.StudentTest[0].test_result.third_part_result.ideals)}</p>
          <p>3.5. Отношение к антиидеалам:
            {" "+String(this.props.StudentTest[0].test_result.third_part_result.antiideals)}</p>
        </Alert>
        <Alert key={4} variant={'info'}>
          <Alert.Heading>Спасибо за участие!</Alert.Heading>
        </Alert>
        </Col></Row>
        <Row><Col>
          <Button variant="primary" type="submit"
            onClick={() => { 
              this.props.onStartNewTest()
            }}
          >Начать новый тест
          </Button>
        </Col></Row>
        </Container>
      )
    }
    else {return <h1>Результата еще нет!!!</h1>;}
  }

  showBeginTest() {
    let _Test
    return (
      
      <Container><Row><Col>
      <label>Выберите тест
        <select 
          ref={input=>_Test=input}
          size="1">
            {this.props.Tests.map((test,idx)=>{
              return (
                <option id={test.id} key={idx+1} >
                  {String("Идентификатор теста: "+test.id+", начало: "+
                   this.getDateTime(test.start)+" завершение: "+this.getDateTime(test.finish))}
                </option>
              )
              })
            }
        </select>
      </label>
      <div>
      <Button variant="primary" type="submit" disabled={this.props.StartFlag}
        onClick={() => {
          //console.log(_Test.options[_Test.selectedIndex].id)
          this.props.onPostPerson({name:"Я",
          x:(392+((/*this.props.Persons.length*/0)%4*98))/((784-98)/2),
          y:1-(Math.floor((/*this.props.Persons.length*/0)/4)*25)/(550-25),
          //x:0.0,y:0.5,
          color:"firebrick"})
          this.props.onPostFetchStudentTest(process.env.REACT_APP_URL+"/student_tests",
            {test_id:Number(_Test.options[_Test.selectedIndex].id),
              student_id:this.props.User.student_id,
              start_dt:new Date().toISOString().substring(0,11)+
                new Date().toLocaleTimeString().substring(0,5)
            })
        }}
        >Начать тестирование
      </Button>
      </div>
      </Col></Row></Container>
      
    );
  }

  suggestChars(event) {
    let results = this.props.Сharacteristics.filter((char) => {
         return char.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
    this.props.onChangeCharComplete({currentChar: this.props.CharComplete.currentChar, 
      suggestChars: results });
  }

  showTabContainer() {

    let items = []
    let listGroup = []
    let i,checkedRole//, idWord
    for (let number = 1; number <= Math.ceil((this.props.PersonsTab.length-1)/10); number++) {
      items.push(
        <Pagination.Item id={number} key={number} active={number === this.props.ActivePagination}
          onClick={(event)=>{
            this.props.onChangePagination(Number(event.target.id))
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
    //for(i=this.props.ActivePagination*10-10+1;i<=this.props.ActivePagination*10;i++) {
    for(i=this.props.ActivePagination*10-10;i<this.props.ActivePagination*10;i++) {
      if(i<this.props.PersonsTab.length-1)
        listGroup.push({...this.props.PersonsTab[i],link:i})
    }
    return (
      <Tab.Container activeKey={this.props.ActiveTabPane}
        onSelect={(e)=>{/*console.log(e.substr(1));*/this.props.onChangeTabPane(String(e))}}>
        <Row>
          <Col sm={3}>
            <Alert variant="primary">
              <Alert.Heading>Персонажи</Alert.Heading>
            </Alert>
            <ListGroup>
              {
                listGroup.map((person,idx)=>{
                  return (
                    <ListGroup.Item key={idx} action href={"#testing/link"+String(person.id-1)}>
                      {person.name}
                    </ListGroup.Item>
                  );
                })
              }
            </ListGroup>
            <br></br>
            <Pagination>{items}</Pagination>
          </Col>
          <Col sm={9}>
            <Tab.Content>
            {
              Array.from(this.props.PersonsTab.filter(t => t.id !==1)).map((person,idx)=>{
                return (
                  <Tab.Pane key={idx} eventKey={"#testing/link"+String(person.id-1/*idx+1*/)}>
                  <Alert variant="primary">
                    <Alert.Heading>Дополнительная информация о персонаже {person.name}</Alert.Heading>
                  </Alert>
                  <Container>
                    <Row>
                      <Col>
                        <Form>
                          <Form.Label>Роли:</Form.Label>
                          <Alert variant="info">
                            <p>Выберите <span style={{color: "maroon", fontWeight: "bold"}}> не менее 3</span> признаков, 
                              которые можно отнести к данному персонажу.
                            </p>
                          </Alert>
                        {this.props.Roles.map((role,idx2)=>{
                          if(this.props.PersonsRoles.find(t=>t.person_id===person.id&&t.role_id===role.id)!==undefined)
                            checkedRole=true
                          else checkedRole=false
                            return (
                              <Form.Check key={idx2} checked={checkedRole} 
                                id={String(""+person.id+":"+role.id)} 
                                type="checkbox" label={role.name} 
                                onChange={(e)=>{
                                //role_id
                                //console.log(e.target.id.substr(e.target.id.indexOf(":")+1))
                                //person_id
                                //console.log(e.target.id.substring(0,e.target.id.indexOf(":")))
                                this.props.onChangeCheckedRole(e.target.checked,
                                  Number(e.target.id.substring(0,e.target.id.indexOf(":"))),
                                  Number(e.target.id.substr(e.target.id.indexOf(":")+1)))
                                }}
                              >
                              </Form.Check>
                            );
                          })
                        }
                        </Form>
                      </Col>
                      <Col>
                        <Form>
                        <Form.Label>Характеристики:</Form.Label>
                        <Alert variant="info">
                          <p>Напишите что-либо об этом человеке (можно одно слово), выбирая из подсказок
                          </p>
                        </Alert>
                        <AutoComplete dropdown={true} value={this.props.CharComplete.currentChar}
                          field="name"
                          onChange={(e) => this.props.onChangeCharComplete({currentChar: e.value,
                            suggestChars:this.props.CharComplete.suggestChars})}
                          onKeyPress={(e)=>{
                            if(e.charCode===13&&this.props.CharComplete.suggestChars.length===0) {
                             // console.log(this.props.CharComplete.currentChar)
                              this.props.onPostFetchWords(process.env.REACT_APP_URL+"/words",
                                {name:this.props.CharComplete.currentChar},person.id)
                              this.props.onChangeCharComplete({currentChar: "",
                                suggestChars:this.props.CharComplete.suggestChars})
                            }
                          }}
                          suggestions={this.props.CharComplete.suggestChars}
                          onSelect={(e)=>{
                            if(this.props.PersonsChar.find(t=>t.person_id===person.id&&
                              t.characteristic_id===e.value.id)===undefined) {
                                this.props.onPostPersonChar({
                                  characteristic_id: e.value.id,
                                  person_id: person.id,
                                  characteristic: e.value.name,
                                  person: this.props.PersonsTab.find(t=>t.id===person.id).name,
                                })
                              this.props.onChangeCharComplete({currentChar: "",
                                suggestChars:this.props.CharComplete.suggestChars})
                            }
                            else alert("Такая характеристика уже добавлена персонажу!")
                          }}
                          completeMethod={this.suggestChars.bind(this)}>
                        </AutoComplete>
                        <hr></hr>
                        {this.props.PersonsChar.filter(t=>t.person_id===person.id).map((person_char,idx2)=>{
                          return (
                            <Form.Check key={idx2} checked={true}
                              id={String("_"+person.id+":"+person_char.characteristic_id)}
                              type="checkbox" label={person_char.characteristic}
                              onChange={(e)=>{
                                //characteristic_id
                                //console.log(e.target.id.substr(e.target.id.indexOf(":")+1))
                                //person_id
                                //console.log(e.target.id.substring(0,e.target.id.indexOf(":")))
                                this.props.onChangeCheckedChar(e.target.checked,
                                  Number(e.target.id.substring(1,e.target.id.indexOf(":"))),
                                  Number(e.target.id.substr(e.target.id.indexOf(":")+1)))
                                }}
                            >
                            </Form.Check>
                          );
                          })
                        }
                        </Form>
                      </Col>
                    </Row>
                  </Container>
                </Tab.Pane>
                );
              })
            }
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  }

  async renderTestResult(){

    let flag=0
    flag=await this.props.onEndTest2(process.env.REACT_APP_URL+"/student_tests/"+
    String(this.props.StudentTest[0].id)/*,process.env.REACT_APP_URL+
    "/student_tests/"+String(this.props.StudentTest[0].id)+"/result"*/)

    //console.log(flag)
    if(flag===1) {
      //console.log("-1-")
      this.props.onGetStudentTestResult(process.env.REACT_APP_URL+
    "/student_tests/"+String(this.props.StudentTest[0].id)+"/result")
    }
  }
  
  render() {
    let _inputPerson
    //let gridWidth = 98,gridHeight = 25,gridRows = 22,gridColumns = 4, x, y
    let i
    let SR=this.showResult()
    let SBT=this.showBeginTest()
    let STC=this.showTabContainer()
   
    if(this.props.User.validateToken===false) return <Redirect to="/login" />;
    if(this.props.Tests.length===0&&!this.props.StartFlag) 
      return <div><h1>В настоящее время для вас нет активных тестов.</h1>
      </div>;
    else
    {
      if(this.props.StudentTest.length!==0&&this.props.StudentTest[0].student_test_result_id!==null)
        return SR;
      else if (!this.props.StartFlag) return <div>{SBT}</div>;
      else
      return <div>
      <Container>
        <Row><Col>
          <Alert key={1} variant={'primary'}>
            <p>Вспомните и запишите всех людей, которые будут «приходить в голову». Это могут быть 
              знакомые и незнакомцы, большие и маленькие, родные и чужие, реальные и вымышленные, 
              известные и неизвестные. Чем больше персонажей вы укажете, тем полнее будет 
              итоговая характеристика (советуем вводить не более 
              50-ти персонажей). 
              Записывать можно именем, прозвищем, буквой и т.д. Главное, чтобы выбранное имя 
              было узнаваемо вами при дальнейшем заполнении ролей и характеристик. 
              После каждого введенного персонажа нажмите Enter, после чего имя 
              будет добавлено в список.</p>
          </Alert>
          <Form>
            <Form.Group controlId="formAddPerson">
              <Form.Label>Имя персонажа</Form.Label>
              <Form.Control ref={input=>_inputPerson=input} 
                type="input" placeholder="Введите имя персонажа"/>
            </Form.Group>
            <Button variant="primary" type="submit"
              onClick={()=>{
                if(_inputPerson.value!==""&&_inputPerson.value!==" "&&_inputPerson.value!=="Я"&&_inputPerson.value!=="я") {
                  this.props.onPostPerson({name:_inputPerson.value,
                    x:(392+((this.props.Persons.length)%4*98))/((784-98)/2),
                    y:1-(Math.floor((this.props.Persons.length)/4)*25)/(550-25),
                    color:"gray"})
                }
                _inputPerson.value=""
              }}
            > Добавить
            </Button>
          </Form>
        </Col></Row>
        <Row><Col><hr></hr></Col></Row>
      </Container>
      <Container>
        <Row><Col><br></br></Col></Row>
        <Row><Col>
            {STC}
        </Col></Row>
        <Row><Col><br></br></Col></Row>
      </Container>
      <Container>
        <Row><Col>
          <Alert key={1} variant={'primary'}>
            <p>Сначала найдите место для «Я» на шкале между «антиидеальным» и «идеальным» и перетащите 
              «Я» в это место на шкале. Затем то же самое сделайте для всех персонажей: поставьте 
              каждого на то место, которое соответствует вашему представлению о нем/о ней. 
              Если вы оцениваете каких-то персонажей одинаково, они будут занимать одно и то же место 
              на шкале. 
            </p>
          </Alert>
        </Col></Row>
        <Row><Form.Label>Идеальное (самое лучшее)</Form.Label></Row>
        <Row noGutters="true">
          <Col>
            <div style={{height: '550px', width: '784px', position: 'relative',
              overflow: 'auto', padding: '0',backgroundColor: 'silver'}}>
              <div key={i} style={{position:"absolute", border:"1px solid #454545", 
                width:392-1, height:550, top:0, left:0, backgroundColor:"SlateGray"}}/>
              <div style={{height: '550px', width: '784px', padding: '0px'}}>
                {
                  this.props.Persons.map((person, rowidx)=>{
                    return (
                    <Draggable bounds="parent" key={rowidx} 
                      position={{x: person.x*(784-98)/2,y:(1-person.y)*(550-25)}} 
                      onStart={this.handleStart} 
                      onDrag={this.onDrag} 
                      onStop={this.onStop} 
                      onMouseDown={this.onMouseDown}>
                      <div className="box" id="box1" 
                        style={{zIndex:person.zIndex,backgroundColor: person.color}}>
                        {person.name.substring(0,8)}
                      </div>
                    </Draggable>
                    )
                  })
                }
              </div>
            </div>
          </Col>
        </Row>
        <Row><Form.Label>Антиидеальное (самое худшее)</Form.Label></Row>
      </Container>
      <hr></hr>
      <Button variant="primary" type="submit"
        onClick={() => {
          //сохранить персонажи и их роли и характеристики  
          //this.props.onEndTest(process.env.REACT_APP_URL+"/persons",process.env.REACT_APP_URL+
          //  "/student_tests/"+String(this.props.StudentTest[0].id)+"/result")
          //new Date().toISOString().substring(0,11)+new Date().toLocaleTimeString().substring(0,5)
          //второй вариант
          //this.props.onEndTest2(process.env.REACT_APP_URL+"/student_tests/"+
          //  String(this.props.StudentTest[0].id),process.env.REACT_APP_URL+
          //  "/student_tests/"+String(this.props.StudentTest[0].id)+"/result")
          //третий вариант
          this.renderTestResult()
        }}
        >Завершить тестирование
      </Button>
    </div>;
    }
  }
}

export default LoadTest
