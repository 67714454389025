import React from 'react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
//import Collapse from 'react-bootstrap/Collapse'
//import Button from 'react-bootstrap/Button'
//import Table from 'react-bootstrap/Table'
import CRUDTable, { Fields, Field, CreateForm, UpdateForm, DeleteForm,} from 'react-crud-table';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
//import {
//  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,Label,
//} from 'recharts';
import '../stylesheets/Psychologist.css';


class Explorer extends React.Component {
  constructor(props) {
      super(props)
    
  }

  componentDidMount() {
    //this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups");
    //this.props.onGetFetchTests(process.env.REACT_APP_URL+"/tests");
    this.props.onGetFetchPVG(process.env.REACT_APP_URL+"/personal_value_groups");
  }
    
  getDateTime(datetime) {
    let newDateTime=""
    newDateTime=datetime.substring(8,10)+"."+datetime.substring(5,7)+"."+datetime.substring(0,4)+" "+
      datetime.substring(11,16)
    return newDateTime;
  }

  render() {
    //let _StudentGroupCF,_Finish,_Start
    let _PVG_CF,_PositivityCF
   
    return <div>
      <Container>
      <Row>
        <Col>
        <div style={{ margin: 'auto', width: 'fit-content' }}>
        <CRUDTable  caption="Справочник характеристик"
          actionsLabel="Действия"
          items={Array.from(this.props.Сharacteristics)}
         >
        <Fields>
          <Field
            name="id"
            label="Id"
            hideInCreateForm
            hideInUpdateForm
          />
          <Field
            name="name"
            label="Название"
            type="text"
            placeholder="Введите название характеристики"
          />
          <Field
            name="positivity_str"
            label="Позитивность"
            //type="text"
            //placeholder="Введите позитивность"
            render={({field})=><select ref={input=>_PositivityCF=input} 
            defaultValue="Выберите позитивность" {...field}size="1">
            <option key="0" disabled={true} defaultValue="Выберите позитивность">Выберите позитивность</option>
                {this.props.Positivity.map((positivity,idx)=>{
                  return (
                  <option id={positivity.value} key={idx+1} >{positivity.name}</option>
                  )
                  })
                } 
                </select>}
          />
          <Field
            name="pvg"
            label="Группа"
            render={({field})=><select ref={input=>_PVG_CF=input} defaultValue="Выберите группу" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите группу">Выберите группу</option>
                  {this.props.PVG.map((pvg,idx)=>{
                    return (
                    <option id={pvg.id} key={idx+1} >{pvg.name}</option>
                    )
                    })
                  } 
                  </select>}
          />
        </Fields>
        <CreateForm
          title="Добавление характеристики"
          //message="Create a new task!"
          trigger="Добавить характеристику"
          onSubmit={task => {
             this.props.onPostFetchСharacteristic(process.env.REACT_APP_URL+"/characteristics",
             {personal_value_group_id:_PVG_CF.options[_PVG_CF.selectedIndex].id,name:task.name,
              positivity:_PositivityCF.options[_PositivityCF.selectedIndex].id})
            return Promise.resolve(task);
          }}
          submitText="Добавить"
          validate={(values) => {
            const errors = {};
            if (!values.name) {errors.title = 'Please, provide task\'s title'; }
            if (!values.positivity_str) {errors.description = 'Please, provide task\'s description';}
            if (!values.pvg) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
        <UpdateForm
          title="Изменение атрибутов характеристики"
          trigger="Изменить"
          onSubmit={data => {
            this.props.onUpdateFetchСharacteristic(process.env.REACT_APP_URL+"/characteristics/"+data.id.toString(10),
            {personal_value_group_id:_PVG_CF.options[_PVG_CF.selectedIndex].id,name:data.name,
              positivity:_PositivityCF.options[_PositivityCF.selectedIndex].id})
            return Promise.resolve(data);
          }}
          submitText="Изменить"
          validate={(values) => {
            const errors = {};
            if (!values.name) {errors.title = 'Please, provide task\'s title'; }
            if (!values.positivity_str) {errors.description = 'Please, provide task\'s description';}
            if (!values.pvg) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
       <DeleteForm
         title="Удаление характеристики"
         message="Вы уверены, что хотите удалить эту характеристику?"
         trigger="Удалить"
         onSubmit={data => {//service.delete(task)
          //console.log(data);
          this.props.onDeleteFetchСharacteristic(process.env.REACT_APP_URL+"/characteristics/"+data.id.toString(10),data);
          return Promise.resolve(data);
         }}
         submitText="Удалить"
         validate={(values) => {
           const errors = {};
           if (!values.id) {errors.id = 'Please, provide id';}
           return errors;
         }}
       />
       </CRUDTable>
        </div>
        </Col>
      </Row>
      </Container>
    </div>;
   
    }
  }
  
  export default Explorer