import React from 'react';
import '../stylesheets/menus.scss'
import '../stylesheets/pages.scss'
import {NewEditDirectories} from './container2'
import {NewLoadTest} from './container'
import {NewRegistration} from './container3'
import {NewPsychologist} from './container4'
import {NewAuthorization} from './container5'
import {NewMainMenu} from './container6'
import {NewExplorer} from './container7'
import {NewAdmin} from './container8'
import {NewHome} from './container9'
import {NewClassification} from './container10'
import {NewNewChars} from './container11'
import {NewStat} from './container12'


const PageTemplate = ({children}) =>
<div className="page">
  <NewMainMenu></NewMainMenu>
  {children}
</div>

export const Stat = () =>
<PageTemplate>
<section className="stat">
  <NewStat></NewStat>
</section>
</PageTemplate>

export const NewChars = () =>
<PageTemplate>
<section className="newchars">
  <NewNewChars></NewNewChars>
</section>
</PageTemplate>

export const Classification = () =>
<PageTemplate>
<section className="classification">
  <NewClassification></NewClassification>
</section>
</PageTemplate>

export const Home = () =>
<PageTemplate>
<section className="home">
  <NewHome></NewHome>
</section>
</PageTemplate>

export const Directories = () =>
<PageTemplate>
<section className="directories">
<NewEditDirectories></NewEditDirectories>
</section>
</PageTemplate>

export const About = () =>
<PageTemplate>
<section className="about">
<h1>[Информация о проекте]</h1>
</section>
</PageTemplate>

export const Testing = () =>
<PageTemplate>
<section className="testing">
<NewLoadTest></NewLoadTest>
</section>
</PageTemplate>

export const Contact = () => 
<PageTemplate>
<section className="contact">
  <h1>[Информация о заказчике]</h1>
</section>
</PageTemplate>

export const Login = () =>
<PageTemplate>
<section className="login">
  <NewAuthorization></NewAuthorization>
</section>
</PageTemplate>

export const Explorer = () =>
<PageTemplate>
<section className="explorer">
  {/*<h1>[Исследователь]</h1>*/}
  <NewExplorer></NewExplorer>
</section>
</PageTemplate>

export const Admin = () =>
<PageTemplate>
<section className="admin">
  <NewAdmin></NewAdmin>
</section>
</PageTemplate>

export const Psychologist = () =>
<PageTemplate>
<section className="psychologist">
<NewPsychologist></NewPsychologist>
</section>
</PageTemplate>

export const Registration = () =>
<PageTemplate>
<section className="registration">
  <NewRegistration></NewRegistration>
</section>
</PageTemplate>

/*export const RegAbout = () =>
<PageTemplate>
<section className="registration">
  <RegistrationAbout></RegistrationAbout>
</section>
</PageTemplate>*/


export const W404 = ({location}) =>
<div className="w404">
<h1>Resource not found at '{location.pathname}'</h1>
</div>