import React from 'react';
import CRUDTable, { Fields, Field, CreateForm, UpdateForm, DeleteForm/*, Pagination*/} from 'react-crud-table';
import '../stylesheets/EditDirectories.css';
import {Form, Button, Row, Col, Container, Alert, Pagination} from 'react-bootstrap';


class EditDirectories extends React.Component {

  
  componentDidMount() {
    this.Initloads()
  }

  async Initloads() {
    await this.props.onGetFetchCityTypes(process.env.REACT_APP_URL+"/city_types")
    await this.props.onGetFetchDistricts(process.env.REACT_APP_URL+"/districts")
    await this.props.onGetFetchSchoolTypes(process.env.REACT_APP_URL+"/school_types")
    await this.props.onGetFetchCities(process.env.REACT_APP_URL+"/cities")
  }

  renderSchools(){
    let cities_schools=null
    if(this.props.User.type==="Admin") {
      cities_schools=<Container>
      <Row><Col>
        <Form.Group as={Row} controlId="formListCity">
          <Form.Label column sm={3}>Выберите город:</Form.Label>
          <Col sm={9}>               
            <Form.Control as="select" defaultValue={this.props.CurrentCityId}
              onChange={(e)=>{
                this.props.onSetCurrentCityId(Number(e.target.value))
              }}
            >
              <option key="0" value={-1}>Все города</option>
              {this.props.Cities.map((city,idx)=>{
                return <option key={idx+1} value={city.id}>{String(city.name)}
                </option>
                })
              }
            </Form.Control>
          </Col>
        </Form.Group>
      </Col></Row>
      <Row><Col>
      <div style={{ margin: 'auto', width: 'fit-content' }}>
        <CRUDTable  caption="Справочник школ"
          actionsLabel="Действия"
          //items={Array.from(this.props.tempSchools)}
          items={this.props.PaginationTable3.school.data.slice()}
         >
        <Fields>
          <Field
            name="id"
            label="Id"
            hideInCreateForm
            hideInUpdateForm
          />
          <Field
            name="name"
            label="Название"
            type="text"
            placeholder="Введите название"
          />
          <Field
            name="city"
            label="Город"
            render={({field})=><select defaultValue="Выберите город" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите город">Выберите город</option>
                  {this.props.Cities.map((city,idx)=>{
                  return (<option key={idx+1}>{city.name}</option>)
                })
                } </select>}
          />
          <Field
            name="schoolTypes"
            label="Тип"
            render={({field})=><select defaultValue="Выберите тип" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите тип">Выберите тип</option>
                  {this.props.SchoolTypes.map((schoolType,idx)=>{
                  return (<option key={idx+1}>{schoolType.name}</option>)
                })
                } </select>}
          />
        </Fields>
        <CreateForm
          title="Добавление школы"
          trigger="Добавить школу"
          onSubmit={task => {
            this.props.onPostFetchSchools(process.env.REACT_APP_URL+"/schools",
              {name:task.name,city_id:this.props.Cities.find(t => t.name === task.city).id,
                school_type_id:this.props.SchoolTypes.find(t => t.name === task.schoolTypes).id})
            return Promise.resolve(task);
          }}
          submitText="Добавить"
          validate={(values) => {
            const errors = {};
            if (!values.name) {errors.title = 'Please, provide task\'s title'; }
            if (!values.city) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
        <UpdateForm
          title="Изменение школы"
          trigger="Изменить"
          onSubmit={data => {
            this.props.onUpdateFetchSchools(process.env.REACT_APP_URL+"/schools/"+data.id.toString(10),
              {name:data.name,city_id:this.props.Cities.find(t => t.name === data.city).id,
                school_type_id:this.props.SchoolTypes.find(t => t.name === data.schoolTypes).id
              });
            return Promise.resolve(data);
          }}
          submitText="Изменить"
          validate={(values) => {
            const errors = {};
            if (!values.name) {errors.title = 'Please, provide task\'s title';}
            if (!values.city) {errors.description = 'Please, provide task\'s description'; }
            return errors;
          }}
        />
       <DeleteForm
         title="Удаление школы"
         message="Вы уверены, что хотите удалить эту школу?"
         trigger="Удалить"
         onSubmit={data => {
          this.props.onDeleteFetchSchools(process.env.REACT_APP_URL+"/schools/"+
            data.id.toString(10),data);
          return Promise.resolve(data);
         }}
         submitText="Удалить"
         validate={(values) => {
           const errors = {};
           if (!values.id) {errors.id = 'Please, provide id';}
           return errors;
         }}
       />
       </CRUDTable>
      <br></br>
      <Pagination>{this.renderPaginationItem3()}</Pagination>
      </div>
      </Col></Row>
      <Row><Col>
        <hr></hr>
      </Col></Row>
      <Row><Col>
  
        <Form.Group as={Row} controlId="formListSchool">
          <Form.Label column sm={3}>Выберите школу:</Form.Label>
          <Col sm={9}>               
            <Form.Control as="select"
              onChange={(e)=>{
                this.props.onSetCurrentSchoolId(Number(e.target.value))
              }}
            >
              {this.props.tempSchools.map((school,idx)=>{
                return <option key={idx} value={school.id}>{String(school.name)}
                </option>
                })
              }
            </Form.Control>
          </Col>
        </Form.Group>
      </Col></Row>
      </Container>
    }
    return (cities_schools);
  }

  async onAddStudentsToGroup(countMan,countWoman,group_id){
    let i,freeName={name:"",index:1}
    for(i=0;i<(countMan+countWoman);i++)
    {
      freeName=this.props.getFreeName(freeName)
      if(i<countMan) {
        await this.props.onPostFetchStudent(process.env.REACT_APP_URL+"/students",
        {student_group_id:group_id, name:freeName.name, sex:"male"})
      }
      else await this.props.onPostFetchStudent(process.env.REACT_APP_URL+"/students",
        {student_group_id:group_id, name:freeName.name, sex:"female"})
      freeName.index=freeName.index+1
    }
  }

  renderPaginationItem(){
    let number, items=[], itemsPerPage
    itemsPerPage=this.props.PaginationTable.student.itemsPerPage
    for(number=1;number <= Math.ceil(this.props.tempStudents.length/itemsPerPage); number++) {
      items.push(
        <Pagination.Item id={number} key={number} 
          active={number===this.props.PaginationTable.student.activePage}
          onClick={(event)=>{
            let start = (Number(event.target.id) - 1) * itemsPerPage;
            let end = start + itemsPerPage;
            this.props.onChangePaginationTable(start,end,Number(event.target.id),this.props.tempStudents,"change")
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  renderPaginationItem2(){
    let number, items=[], itemsPerPage
    itemsPerPage=this.props.PaginationTable2.studentgroup.itemsPerPage
    for(number=1;number <= Math.ceil(this.props.tempStudentGroups.length/itemsPerPage); number++) {
      items.push(
        <Pagination.Item id={number} key={number} 
          active={number===this.props.PaginationTable2.studentgroup.activePage}
          onClick={(event)=>{
            let start = (Number(event.target.id) - 1) * itemsPerPage;
            let end = start + itemsPerPage;
            this.props.onChangePaginationTable2(start,end,Number(event.target.id),
              this.props.tempStudentGroups,"change")
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  renderPaginationItem3(){
    let number, items=[], itemsPerPage
    itemsPerPage=this.props.PaginationTable3.school.itemsPerPage
    for(number=1;number <= Math.ceil(this.props.tempSchools.length/itemsPerPage); number++) {
      items.push(
        <Pagination.Item id={number} key={number} 
          active={number===this.props.PaginationTable3.school.activePage}
          onClick={(event)=>{
            let start = (Number(event.target.id) - 1) * itemsPerPage;
            let end = start + itemsPerPage;
            this.props.onChangePaginationTable3(start,end,Number(event.target.id),
              this.props.tempSchools,"change")
          }}
        >
          {number}
        </Pagination.Item>,
      );
    }
    return items;
  }

  render() {
      let _SchoolCF,_StudentGroup2,_StudentGroupCF,_SexCF
      let _DateC
      let ErrMessage="",_CountM,_CountW
      return <div>
        {this.renderSchools()}
        <Container><Row><Col>
        <div style={{ margin: 'auto', width: 'fit-content' }}>
        <CRUDTable  caption="Список классов"
          actionsLabel="Действия"
          items={this.props.PaginationTable2.studentgroup.data.slice()}
         >
        <Fields>
          <Field
            name="id"
            label="Id"
            hideInCreateForm
            hideInUpdateForm
          />
          <Field
            name="letter"
            label="Буква"
            type="text"
            placeholder="Введите букву"
          />
          <Field
            name="form_date"
            label="Дата поступления"
            //type="text"
            //placeholder="Введите дату поступления"
            type="date"
            render={({field})=><input ref={input=>_DateC=input}
              type="date"
              defaultValue={field.value}
              ></input>}
          />
           <Field
            name="name"
            label="Класс"
            type="text"
            hideInCreateForm
            hideInUpdateForm
          />
          <Field
            name="school"
            label="Школа"
            render={({field})=><select ref={input=>_SchoolCF=input} defaultValue="Выберите школу" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите школу">Выберите школу</option>
                  {this.props.tempSchools.map((school,idx)=>{
                    return (
                    <option id={school.id} key={idx+1} >{school.name}</option>
                    )
                    })
                  } 
                  </select>}
          />
        </Fields>
        <CreateForm
          title="Добавление класса"
          trigger="Добавить класс"
          onSubmit={task => {
            console.log(_DateC.value)
            //console.log(task)
             this.props.onPostFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups",
             {school_id:_SchoolCF.options[_SchoolCF.selectedIndex].id,letter:task.letter,
               form_date:_DateC.value/*task.form_date*/})
            return Promise.resolve(task);
          }}
          submitText="Добавить"
          validate={(values) => {
            const errors = {};
            //if (!values.letter) {errors.title = 'Please, provide task\'s title'; }
            //if (!values.form_date) {errors.description = 'Please, provide task\'s description';}
            //if (!values.school) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
        <UpdateForm
          title="Изменение атрибутов класса"
          trigger="Изменить"
          onSubmit={data => {
            this.props.onUpdateFetchStudentGroup(process.env.REACT_APP_URL+"/student_groups/"+
              data.id.toString(10), {school_id:_SchoolCF.options[_SchoolCF.selectedIndex].id,
              letter:data.letter,form_date:_DateC.value/*data.form_date*/})
            return Promise.resolve(data);
          }}
          submitText="Изменить"
          validate={(values) => {
            const errors = {};
            if (!values.letter) {errors.title = 'Please, provide task\'s title'; }
            if (!values.form_date) {errors.description = 'Please, provide task\'s description';}
            if (!values.school) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
       <DeleteForm
         title="Удаление класса"
         message="Вы уверены, что хотите удалить этот класс?"
         trigger="Удалить"
         onSubmit={data => {
          this.props.onDeleteFetchStudentGroup(process.env.REACT_APP_URL+"/student_groups/"+data.id.toString(10),data);
          return Promise.resolve(data);
         }}
         submitText="Удалить"
         validate={(values) => {
           const errors = {};
           if (!values.id) {errors.id = 'Please, provide id';}
           return errors;
         }}
       />
       </CRUDTable>
        <br></br>
        <Pagination>{this.renderPaginationItem2()}</Pagination>
        </div>
        </Col></Row>
        <Row><Col>
          <hr></hr>
        </Col></Row>
        </Container>
      <Container>
        <Row><Col>
          <Form.Group as={Row} controlId="formListClass">
            <Form.Label column sm={3}>Выберите класс:</Form.Label>
            <Col sm={9}>               
              <Form.Control as="select" ref={input=>_StudentGroup2=input}
                onChange={(e)=>{
                  this.props.onSetCurrentStudentGroupId(Number(e.target.value))
                }}
              >
                {this.props.tempStudentGroups.map((studentgroup,idx)=>{
                  return <option key={idx} value={studentgroup.id}>{String(studentgroup.name)}
                  </option>
                  })
                }
              </Form.Control>
            </Col>
          </Form.Group>
          <Row><Col>
            <Alert key={1} variant={'primary'}>
              <p>Задайте количество мальчиков и девочек в учебной группе. Каждый раз при нажатии 
                на кнопку "Добавить учеников в класс" будет выполняться добавление.
              </p>
            </Alert>
          </Col></Row>
          <Row><Col>
            <Form.Control placeholder="Кол-во мальчиков" ref={input=>_CountM=input}
            />
          </Col>
          <Col>
            <Form.Control placeholder="Кол-во девочек" ref={input=>_CountW=input}
            />
          </Col></Row>
          <br></br>
          <Row><Col>
            <Button variant="primary" type="submit" 
              onClick={(e)=>{
                let countM,countW
                if((_CountM.value===""&&_CountW.value==="")||(_CountM.value==="0"&&_CountW.value==="0")||
                   (_CountM.value===""&&_CountW.value==="0")||(_CountM.value==="0"&&_CountW.value==="")) 
                     ErrMessage+="Хотя бы одно поле ввода должно быть заполнено\n"
                
                if(ErrMessage!=="") {alert(ErrMessage);ErrMessage=""}
                else {
                  if(_CountM.value==="") countM=0; else countM=Number(_CountM.value)
                  if(_CountW.value==="") countW=0; else countW=Number(_CountW.value)
                  this.onAddStudentsToGroup(countM,countW,Number(_StudentGroup2.value))
                }
                ErrMessage=""
              }}> Добавить учеников в класс
            </Button>
          </Col></Row>
          <br></br>
        </Col></Row>
        <Row><Col>
      <div style={{ margin: 'auto', width: 'fit-content' }}>
        <CRUDTable  caption="Список «токенов» для класса"
          actionsLabel="Действия"
          items={this.props.PaginationTable.student.data.slice()}
         >
        <Fields>
          <Field name="id" label="Id" hideInCreateForm hideInUpdateForm/>
          <Field name="name" label="Имя" type="text" placeholder="Введите имя"/>
           <Field
            name="sex_str"
            label="Пол"
            render={({field})=>
            <select ref={input=>_SexCF=input} defaultValue="Выберите пол" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите пол">Выберите пол</option>
              {this.props.Sex.map((sex,idx)=>{
                return (
                  <option id={sex.value} key={idx+1} >{sex.name}</option>
                  )
                })
              } 
            </select>}
          />
           <Field name="token" label="Токен" type="text" hideInCreateForm hideInUpdateForm/>
          <Field
            name="studentgroup"
            label="Класс"
            render={({field})=><select ref={input=>_StudentGroupCF=input} defaultValue="Выберите класс" {...field}size="1">
              <option key="0" disabled={true} defaultValue="Выберите класс">Выберите класс</option>
                  {this.props.tempStudentGroups.map((studentgroup,idx)=>{
                    return (
                    <option id={studentgroup.id} key={idx+1} >{studentgroup.name}</option>
                    )
                    })
                  } 
                  </select>}
          />
        </Fields>
        {/*<Pagination 
          itemsPerPage={2}
          defaultActivePage={1}
          totalOfItems={this.props.tempStudents.length}
          //fetchTotalOfItems={payload => {console.log(payload)
          //  return Promise.resolve(this.props.tempStudents.length);}}
        >
        </Pagination>*/}
        <CreateForm
          title="Добавление ученика"
          //message="Create a new task!"
          trigger="Добавить ученика"
          onSubmit={task => {
            //this.createStudent(task,_StudentGroupCF.options[_StudentGroupCF.selectedIndex].id,
            //  _SexCF.options[_SexCF.selectedIndex].id)
            this.props.onPostFetchStudent(process.env.REACT_APP_URL+"/students",
              {student_group_id:_StudentGroupCF.options[_StudentGroupCF.selectedIndex].id,name:task.name,
              sex:_SexCF.options[_SexCF.selectedIndex].id})
            return Promise.resolve(task);
          }}
          submitText="Добавить"
          validate={(values) => {
            const errors = {};
            //if (!values.name) {errors.title = 'Please, provide task\'s title'; }
            //if (!values.sex_str) {errors.title = 'Please, provide task\'s title'; }
            //if (!values.studentgroup) {errors.description = 'Please, provide task\'s description';}
            //if (!values.school) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
        <UpdateForm
          title="Изменение атрибутов ученика"
          trigger="Изменить"
          onSubmit={data => {
            this.props.onUpdateFetchStudent(process.env.REACT_APP_URL+"/students/"+data.id.toString(10),
            {student_group_id:_StudentGroupCF.options[_StudentGroupCF.selectedIndex].id,name:data.name,
              sex:_SexCF.options[_SexCF.selectedIndex].id
            })
            return Promise.resolve(data);
          }}
          submitText="Изменить"
          validate={(values) => {
            const errors = {};
            //if (!values.name) {errors.title = 'Please, provide task\'s title'; }
            //if (!values.sex) {errors.title = 'Please, provide task\'s title'; }
            //if (!values.form_date) {errors.description = 'Please, provide task\'s description';}
            //if (!values.studentgroup) {errors.description = 'Please, provide task\'s description';}
            return errors;
          }}
        />
       <DeleteForm
         title="Удаление ученика"
         message="Вы уверены, что хотите удалить этого ученика?"
         trigger="Удалить"
         onSubmit={data => {
          this.props.onDeleteFetchStudent(process.env.REACT_APP_URL+"/students/"+data.id.toString(10),data);
          return Promise.resolve(data);
         }}
         submitText="Удалить"
         validate={(values) => {
           const errors = {};
           if (!values.id) {errors.id = 'Please, provide id';}
           return errors;
         }}
       />
       </CRUDTable>
       <br></br>
       <Pagination>{this.renderPaginationItem()}</Pagination>
      </div>
      </Col></Row></Container>
      </div>;
  }
}

export default EditDirectories