import {connect} from 'react-redux'
import EditDirectories from '../components/EditDirectories'


export const NewEditDirectories = connect (
   
    state=>({
      SchoolName : state.SchoolName,
      ClassName : state.ClassName,
      UserName : state.UserName,
      Roles : state.Roles,
      Сharacteristics : state.Сharacteristics,
      Answers : state.Answers,
      CurrentZIndex : state.CurrentZIndex,
      Tasks: state.Tasks,
      Schools: state.Schools,
      Cities: state.Cities,
      CurrentCityId:  state.CurrentCityId,
      CurrentSchoolId:  state.CurrentSchoolId,
      CurrentStudentGroupId: state.CurrentStudentGroupId,
      tempSchools: state.tempSchools,
      StudentGroups: state.StudentGroups,
      tempStudentGroups: state.tempStudentGroups,
      Students: state.Students,
      tempStudents: state.tempStudents,
      PVG: state.PVG,
      Positivity: state.Positivity,
      Sex: state.Sex,
      StudentTest: state.StudentTest,
      Persons: state.Persons,
      CurrentPersonId: state.CurrentPersonId,
      RegFlag: state.RegFlag,
      Tests: state.Tests,
      User: state.User,
      PaginationTable: state.PaginationTable,
      PaginationTable2: state.PaginationTable2,
      PaginationTable3: state.PaginationTable3,
      ActivePagination: state.ActivePagination,
      CityTypes: state.CityTypes,
      Districts: state.Districts,
      SchoolTypes: state.SchoolTypes,
    }),
    dispatch => ({

      onGetFetchSchools(url,cities) {
        let newSchools, tempSchools, i
        fetch(url,{
          headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(schools=> {
          newSchools=[]
          tempSchools=[]
          //console.log(this.SchoolTypes)
          for (i=0;i<schools.length;i++) {
            newSchools.push(schools[i])
            tempSchools.push({id:schools[i].id,name:schools[i].name,city_id:schools[i].city_id,
              city:cities.find(t=>t.id===schools[i].city_id).name,
              school_type_id: schools[i].school_type_id,
              schoolTypes:this.SchoolTypes.find(t=>t.id===schools[i].school_type_id)===undefined? null:
                this.SchoolTypes.find(t=>t.id===schools[i].school_type_id).name
            })
          }
          //дополнительная фильтрация для школьного администратора
          if(this.User.type==="SchoolAdmin"){
            newSchools=newSchools.filter(t => t.id ===this.User.school_id)
            tempSchools=tempSchools.filter(t => t.id ===this.User.school_id)
          }
          if (this.CurrentCityId!==-1) 
            tempSchools=tempSchools.filter(t => t.city_id ===this.CurrentCityId)
          dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:tempSchools})
          this.onChangePaginationTable3(0,this.PaginationTable3.school.itemsPerPage,1,
            tempSchools,"get")
          if(tempSchools.length!==0) this.onSetCurrentSchoolId(tempSchools[0].id)
          else this.onSetCurrentSchoolId(Number(-1))
          this.onGetFetchStudentGroups(process.env.REACT_APP_URL+"/student_groups",tempSchools)
        })
        }
        })
      },

      onPostFetchSchools(url,data) {
        let newSchools, tempSchools, i
        fetch(url, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(school=> {
          newSchools=[]
          tempSchools=[]
          for (i=0;i<this.Schools.length;i++) {
            newSchools.push(this.Schools[i])
            tempSchools.push({id:this.Schools[i].id,name:this.Schools[i].name,
              city_id:this.Schools[i].city_id,
              city:this.Cities.find(t=>t.id===this.Schools[i].city_id).name,
              school_type_id: this.Schools[i].school_type_id,
              schoolTypes:this.SchoolTypes.find(t=>t.id===this.Schools[i].school_type_id)===undefined? null:
                this.SchoolTypes.find(t=>t.id===this.Schools[i].school_type_id).name})
          }
          newSchools.push(school)
          tempSchools.push({id:school.id,name:school.name,
            city_id:school.city_id,city:this.Cities.find(t=>t.id===school.city_id).name,
            school_type_id: school.school_type_id,
            schoolTypes:this.SchoolTypes.find(t=>t.id===school.school_type_id)===undefined?null:
              this.SchoolTypes.find(t=>t.id===school.school_type_id).name
          })
          if (this.CurrentCityId!==-1) tempSchools=tempSchools.filter(t => t.city_id ===this.CurrentCityId)
          dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:tempSchools})
          this.onChangePaginationTable3(this.PaginationTable3.school.start,
            this.PaginationTable3.school.end,this.PaginationTable3.school.activePage,
            tempSchools,"add")
        })
        }
        })
      },

      onUpdateFetchSchools(url,data) {
        let newSchools, tempSchools, i
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(school=> {
          newSchools=[]
          tempSchools=[]
          for (i=0;i<this.Schools.length;i++){
            newSchools.push(this.Schools[i])
            tempSchools.push({id:this.Schools[i].id,name:this.Schools[i].name,
              city_id:this.Schools[i].city_id,
              city:this.Cities.find(t=>t.id===this.Schools[i].city_id).name,
              school_type_id: this.Schools[i].school_type_id,
              schoolTypes:this.SchoolTypes.find(t=>t.id===this.Schools[i].school_type_id)===undefined? null:
                this.SchoolTypes.find(t=>t.id===this.Schools[i].school_type_id).name
            })
            if(newSchools[i].id===school.id) 
            {tempSchools[i].name=newSchools[i].name=school.name;
              tempSchools[i].city_id=newSchools[i].city_id=school.city_id;
              tempSchools[i].city=this.Cities.find(t=>t.id===tempSchools[i].city_id).name;
              tempSchools[i].school_type_id=school.school_type_id;
              tempSchools[i].schoolTypes=this.SchoolTypes.find(t=>t.id===school.school_type_id)===undefined? 
                null:this.SchoolTypes.find(t=>t.id===school.school_type_id).name;
            }
          }
          if (this.CurrentCityId!==-1) tempSchools=tempSchools.filter(t => t.city_id ===this.CurrentCityId)
          dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:tempSchools})
          this.onChangePaginationTable3(this.PaginationTable3.school.start,
            this.PaginationTable3.school.end,
            this.PaginationTable3.school.activePage,tempSchools,"get")
        })
        }
        })
      },

      onDeleteFetchSchools(url,data) {
        let newSchools, tempSchools, i
        fetch(url, {
          method: 'DELETE',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          newSchools=[]
          tempSchools=[]
          for (i=0;i<this.Schools.length;i++){
            newSchools.push(this.Schools[i])
            tempSchools.push({id:this.Schools[i].id,name:this.Schools[i].name,
              city_id:this.Schools[i].city_id,
              city:this.Cities.find(t=>t.id===this.Schools[i].city_id).name})
          }
          newSchools=newSchools.filter(t => t.id !==data.id)
          tempSchools=tempSchools.filter(t => t.id !==data.id)
          if (this.CurrentCityId!==-1) tempSchools=tempSchools.filter(t => t.city_id ===this.CurrentCityId)
          dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:tempSchools})
          this.onChangePaginationTable3(this.PaginationTable3.school.start,
            this.PaginationTable3.school.end,this.PaginationTable3.school.activePage,
            tempSchools,"delete")
          return response;
          }
        })
      },

      async onGetFetchCities(url) {
        let newCities, i
        fetch(url,{
          headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(cities=> {
          newCities=new Array(cities.length)
          for (i=0;i<cities.length;i++)
          newCities[i]=cities[i]
          dispatch({type : 'GET_FETCH_CITIES', Cities: newCities})
          this.onGetFetchSchools(process.env.REACT_APP_URL+"/schools",newCities);
        })
        }
        })
      },

      onGetFetchStudentGroups(url,schools) {
        let newStudentGroups,tempStudentGroups, i
        fetch(url,{
          headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(studentGroups=> {
          newStudentGroups=[]
          tempStudentGroups=[]
          for (i=0;i<studentGroups.length;i++) {
            newStudentGroups.push(studentGroups[i]);
            if(schools.length!==0)
              if(schools.find(t=>t.id===studentGroups[i].school_id))
                tempStudentGroups.push({id:studentGroups[i].id,school_id:studentGroups[i].school_id,
                  letter:studentGroups[i].letter,form_date:studentGroups[i].form_date,
                  name:studentGroups[i].name, 
                  school:schools.find(t=>t.id===studentGroups[i].school_id).name});
          }
          if (schools.length!==0) 
            {tempStudentGroups=tempStudentGroups.filter(t => t.school_id ===schools[0].id)}
          dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
            tempStudentGroups:tempStudentGroups})
          if (tempStudentGroups.length!==0) this.onSetCurrentStudentGroupId(tempStudentGroups[0].id)
          else this.onSetCurrentStudentGroupId(Number(-1))
          this.onChangePaginationTable2(0,this.PaginationTable2.studentgroup.itemsPerPage,1,
            tempStudentGroups,"get")
          this.onGetFetchStudents(process.env.REACT_APP_URL+"/students",tempStudentGroups)
        })
        }
        })
      },

      onPostFetchStudentGroups(url,data) {
        let newStudentGroups, tempStudentGroups, i
        fetch(url, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(studentGroup=> {
          newStudentGroups=[]
          tempStudentGroups=[]
          for (i=0;i<this.StudentGroups.length;i++) {
            newStudentGroups.push(this.StudentGroups[i]);
            if(this.Schools.find(t=>t.id===this.StudentGroups[i].school_id))
            tempStudentGroups.push({id:this.StudentGroups[i].id,
              school_id:this.StudentGroups[i].school_id,
              letter:this.StudentGroups[i].letter,form_date:this.StudentGroups[i].form_date,
              name:this.StudentGroups[i].name, 
              school:this.Schools.find(t=>t.id===this.StudentGroups[i].school_id).name});
          }
          newStudentGroups.push(studentGroup)
          tempStudentGroups.push({id:studentGroup.id,school_id:studentGroup.school_id,
            letter:studentGroup.letter,form_date:studentGroup.form_date,
            name:studentGroup.name, 
            school:this.Schools.find(t=>t.id===studentGroup.school_id).name})
          if (this.Schools[0]) {
            tempStudentGroups=tempStudentGroups.filter(t => t.school_id ===this.CurrentSchoolId)}
          dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
            tempStudentGroups:tempStudentGroups})
          this.onChangePaginationTable2(this.PaginationTable2.studentgroup.start,
            this.PaginationTable2.studentgroup.end,this.PaginationTable2.studentgroup.activePage,
            tempStudentGroups,"add")
        })
        }
        })
      },

      onUpdateFetchStudentGroup(url,data) {
        let newStudentGroups, tempStudentGroups, i
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          response.json().then(studentGroup=> {
          newStudentGroups=[]
          tempStudentGroups=[]
          for (i=0;i<this.StudentGroups.length;i++) {
            newStudentGroups.push(this.StudentGroups[i]);
            if(this.Schools.find(t=>t.id===this.StudentGroups[i].school_id))
            tempStudentGroups.push({id:this.StudentGroups[i].id,
              school_id:this.StudentGroups[i].school_id,
              letter:this.StudentGroups[i].letter,form_date:this.StudentGroups[i].form_date,
              name:this.StudentGroups[i].name, 
              school:this.Schools.find(t=>t.id===this.StudentGroups[i].school_id).name});
              if(newStudentGroups[i].id===studentGroup.id) 
              {tempStudentGroups[i].name=newStudentGroups[i].name=studentGroup.name;
                tempStudentGroups[i].school_id=newStudentGroups[i].school_id=studentGroup.school_id;
                tempStudentGroups[i].letter=newStudentGroups[i].letter=studentGroup.letter;
                tempStudentGroups[i].form_date=newStudentGroups[i].form_date=studentGroup.form_date;
                tempStudentGroups[i].school=this.Schools.find(t=>t.id===studentGroup.school_id).name
              }
            }
          if (this.Schools[0]) 
            {tempStudentGroups=tempStudentGroups.filter(t => t.school_id ===this.CurrentSchoolId)}
          dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
            tempStudentGroups:tempStudentGroups})
          this.onChangePaginationTable2(this.PaginationTable2.studentgroup.start,
            this.PaginationTable2.studentgroup.end,
            this.PaginationTable2.studentgroup.activePage,tempStudentGroups,"get")
          })
          }
        })
      },

      onDeleteFetchStudentGroup(url,data) {
        let newStudentGroups, tempStudentGroups, i
        fetch(url, {
          method: 'DELETE',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
          newStudentGroups=[]
          tempStudentGroups=[]
          for (i=0;i<this.StudentGroups.length;i++) {
            newStudentGroups.push(this.StudentGroups[i]);
            if(this.Schools.find(t=>t.id===this.StudentGroups[i].school_id))
            tempStudentGroups.push({id:this.StudentGroups[i].id,
              school_id:this.StudentGroups[i].school_id,
              letter:this.StudentGroups[i].letter,form_date:this.StudentGroups[i].form_date,
              name:this.StudentGroups[i].name, 
              school:this.Schools.find(t=>t.id===this.StudentGroups[i].school_id).name});
          }
          newStudentGroups=newStudentGroups.filter(t => t.id !==data.id)
          tempStudentGroups=tempStudentGroups.filter(t => t.id !==data.id)
          if (this.Schools[0]) 
            {tempStudentGroups=tempStudentGroups.filter(t => t.school_id ===this.CurrentSchoolId)}
          dispatch({type : 'GET_FETCH_STUDENTGROUPS', StudentGroups: newStudentGroups,
            tempStudentGroups:tempStudentGroups})
          this.onChangePaginationTable2(this.PaginationTable2.studentgroup.start,
            this.PaginationTable2.studentgroup.end,this.PaginationTable2.studentgroup.activePage,
            tempStudentGroups,"delete")
          return response;
          }
        })
      },

      onGetFetchStudents(url,studentgroups) {
        let newStudents,tempStudents,i
        fetch(url,{
          headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
            response.json().then(students=> {
              newStudents=[]
              tempStudents=[]
              for (i=0;i<students.length;i++) {
                newStudents.push(students[i]);
                if(studentgroups.length!==-1)
                if(studentgroups.find(t=>t.id===students[i].student_group_id))
                  tempStudents.push({id:students[i].id,student_group_id:students[i].student_group_id,
                    name:students[i].name, sex: students[i].sex,token: students[i].token,
                    sex_str: this.Sex.find(t=>t.value===students[i].sex).name,
                    studentgroup:studentgroups.find(t=>t.id===students[i].student_group_id).name});
              }
              if (studentgroups.length!==-1) 
                {tempStudents=tempStudents.filter(t => t.student_group_id ===studentgroups[0].id)}
              dispatch({type : 'GET_FETCH_STUDENTS', Students: newStudents,tempStudents:tempStudents})
              this.onChangePaginationTable(0,this.PaginationTable.student.itemsPerPage,1,
                tempStudents,"get")
            })
          }
        })
      },

      getFreeName(freeName){
        let newName=freeName, flag=true, tempStudents=[]
        while(flag){
          tempStudents=this.tempStudents.filter(t => t.name.indexOf("Ученик"+String(newName.index))!==-1)
          if(tempStudents.length===0) {newName.name="Ученик"+String(newName.index);flag=false}
          else newName.index=newName.index+1
        }
        return newName
      },

      async onPostFetchStudent(url,data) {
        let newStudents, tempStudents, i
        newStudents=[]
        tempStudents=[]
        let json, student
        let response=await fetch(url, {
          method: 'POST',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        if(!response.ok) {
          json= await response.json()
          alert(json.errors.join("\n"))
        } else {
          student=await response.json()
        
          for (i=0;i<this.Students.length;i++)
            {
              newStudents.push(this.Students[i]);
              if(this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id))
              tempStudents.push({id:this.Students[i].id,student_group_id:this.Students[i].student_group_id,
                name:this.Students[i].name, sex:this.Students[i].sex,token: this.Students[i].token,
                sex_str: this.Sex.find(t=>t.value===this.Students[i].sex).name,
                studentgroup:this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id).name});
            }
          newStudents.push(student)
          tempStudents.push({id:student.id,student_group_id:student.student_group_id,
            name:student.name, sex: student.sex,token: student.token,
            sex_str: this.Sex.find(t=>t.value===student.sex).name,
            studentgroup:this.StudentGroups.find(t=>t.id===student.student_group_id).name});
          if (this.StudentGroups[0]) 
            {tempStudents=tempStudents.filter(t => t.student_group_id ===this.CurrentStudentGroupId)}
          dispatch({type : 'GET_FETCH_STUDENTS', Students: newStudents,tempStudents:tempStudents})
          this.onChangePaginationTable(this.PaginationTable.student.start,
            this.PaginationTable.student.end,this.PaginationTable.student.activePage,
            tempStudents,"add")
          return Promise.resolve(1)
        }
        return Promise.resolve(1)
      },

      onUpdateFetchStudent(url,data) {
        let newStudents, tempStudents, i
        fetch(url, {
          method: 'PUT',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
          body: JSON.stringify(data),
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"));})
          } else {
         response.json().then(student=> {
          newStudents=[]
          tempStudents=[]
          for (i=0;i<this.Students.length;i++) {
            newStudents.push(this.Students[i]);
            if(this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id))
              tempStudents.push({id:this.Students[i].id,
                student_group_id:this.Students[i].student_group_id,
                name:this.Students[i].name, sex: this.Students[i].sex,token: this.Students[i].token,
                studentgroup:this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id).name});
              if(newStudents[i].id===student.id) 
              {tempStudents[i].name=newStudents[i].name=student.name;
                tempStudents[i].sex=newStudents[i].sex=student.sex;
                tempStudents[i].sex_str=this.Sex.find(t=>t.value===student.sex).name;
                tempStudents[i].student_group_id=newStudents[i].student_group_id=student.student_group_id;
                tempStudents[i].studentgroup=this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id).name;
              }
          }
          if(this.StudentGroups[0]) 
          {tempStudents=tempStudents.filter(t => t.student_group_id ===this.CurrentStudentGroupId)}
          dispatch({type : 'GET_FETCH_STUDENTS', Students: newStudents,tempStudents:tempStudents})
          this.onChangePaginationTable(this.PaginationTable.student.start,
            this.PaginationTable.student.end,
            this.PaginationTable.student.activePage,tempStudents,"get")
        })
        }
        })
      },

      onDeleteFetchStudent(url,data) {
        let newStudents, tempStudents, i
        fetch(url, {
          method: 'DELETE',
          mode: 'cors',
          credentials: 'same-origin',
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
        })
        .then(response=> {
          if(!response.ok) {
            response.json().then(response=>{alert(response.errors.join("\n"))})
          } else{
          newStudents=[]
          tempStudents=[]
          for (i=0;i<this.Students.length;i++)
          {
            newStudents.push(this.Students[i]);
            if(this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id))
            tempStudents.push({id:this.Students[i].id,student_group_id:this.Students[i].student_group_id,
              name:this.Students[i].name,
              sex: this.Students[i].sex,token: this.Students[i].token,
              sex_str: this.Sex.find(t=>t.value===this.Students[i].sex).name,
              studentgroup:this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id).name});
          }
          newStudents=newStudents.filter(t => t.id !==data.id)
          tempStudents=tempStudents.filter(t => t.id !==data.id)
          if (this.StudentGroups[0]) 
            {tempStudents=tempStudents.filter(t => t.student_group_id ===this.CurrentStudentGroupId)}
          dispatch({type : 'GET_FETCH_STUDENTS', Students: newStudents,tempStudents:tempStudents})
          this.onChangePaginationTable(this.PaginationTable.student.start,
            this.PaginationTable.student.end,this.PaginationTable.student.activePage,
            tempStudents,"delete")
          return response;
        }
        })
      },

      onSetCurrentCityId(CityId) {
        let newSchools,i
        newSchools=[]
        for (i=0;i<this.Schools.length;i++)
          newSchools.push({id:this.Schools[i].id,name:this.Schools[i].name,city_id:this.Schools[i].city_id,city:this.Cities.find(t=>t.id===this.Schools[i].city_id).name})
        if (CityId!==-1) newSchools=newSchools.filter(t => t.city_id ===CityId)
        dispatch({type : 'SET_CURRENT_CITY_ID', CurrentCityId: CityId,tempSchools:newSchools})
        this.onChangePaginationTable3(0,this.PaginationTable3.school.itemsPerPage,1,
          newSchools,"get")
        if (newSchools.length!==0) this.onSetCurrentSchoolId(Number(newSchools[0].id))
        else this.onSetCurrentSchoolId(Number(-1))
      },

      onSetCurrentSchoolId(SchoolId) {
        var newStudentGroups
        newStudentGroups=[]
        if (SchoolId!==-1)
          for (var i=0;i<this.StudentGroups.length;i++) {
            newStudentGroups.push({id:this.StudentGroups[i].id,school_id:this.StudentGroups[i].school_id,
              letter:this.StudentGroups[i].letter,form_date:this.StudentGroups[i].form_date,
              name:this.StudentGroups[i].name,
              school:this.Schools.find(t=>t.id===this.StudentGroups[i].school_id).name})
          }
        if (SchoolId!==-1) newStudentGroups=newStudentGroups.filter(t => t.school_id ===SchoolId)
        dispatch({type : 'SET_CURRENT_SCHOOL_ID', CurrentSchoolId: SchoolId,
          tempStudentGroups:newStudentGroups})
        this.onChangePaginationTable2(0,this.PaginationTable2.studentgroup.itemsPerPage,1,
          newStudentGroups,"get")
        if(newStudentGroups.length!==0)this.onSetCurrentStudentGroupId(Number(newStudentGroups[0].id))
        else this.onSetCurrentStudentGroupId(Number(-1))
      },

      onSetCurrentStudentGroupId(StudentGroupId) {
        var newStudents
        newStudents=[]
        if(StudentGroupId!==-1)
          for (var i=0;i<this.Students.length;i++)
            newStudents.push({id:this.Students[i].id,student_group_id:this.Students[i].student_group_id,
            name:this.Students[i].name, sex: this.Students[i].sex, token: this.Students[i].token,
            sex_str: this.Sex.find(t=>t.value===this.Students[i].sex).name,
            studentgroup:this.StudentGroups.find(t=>t.id===this.Students[i].student_group_id).name})
        if (StudentGroupId!==-1) newStudents=newStudents.filter(t => t.student_group_id ===StudentGroupId)
        dispatch({type : 'SET_CURRENT_STUDENTGROUP_ID', CurrentStudentGroupId: StudentGroupId,
          tempStudents:newStudents})
        this.onChangePaginationTable(0,this.PaginationTable.student.itemsPerPage,1,newStudents,"get")
      },

      onChangePaginationTable(_start,_end, activePage, nextData, action)
      {
        let newPT
        if(action==="delete") {
          if(activePage>Math.ceil(nextData.length/this.PaginationTable.student.itemsPerPage))
          activePage=Math.ceil(nextData.length/this.PaginationTable.student.itemsPerPage)
            _start = (activePage - 1) * this.PaginationTable.student.itemsPerPage
            _end = _start + this.PaginationTable.student.itemsPerPage;
        }
        if(action==="add") {
          if(activePage===Math.ceil(nextData.length/this.PaginationTable.student.itemsPerPage))
            _start = (activePage - 1) * this.PaginationTable.student.itemsPerPage
            _end = _start + this.PaginationTable.student.itemsPerPage;
        }
        newPT={student:{start:_start,end:_end, 
          data:nextData.slice(_start,_end), 
          itemsPerPage:this.PaginationTable.student.itemsPerPage, 
          activePage:activePage}}
        dispatch({type:'CHANGE_PAGINATION_TABLE', PaginationTable: newPT})
      },

      onChangePaginationTable2(_start,_end, activePage, nextData, action)
      {
        let newPT
        if(action==="delete") {
          if(activePage>Math.ceil(nextData.length/this.PaginationTable2.studentgroup.itemsPerPage))
          activePage=Math.ceil(nextData.length/this.PaginationTable2.studentgroup.itemsPerPage)
            _start = (activePage - 1) * this.PaginationTable2.studentgroup.itemsPerPage
            _end = _start + this.PaginationTable2.studentgroup.itemsPerPage;
        }
        if(action==="add") {
          if(activePage===Math.ceil(nextData.length/this.PaginationTable2.studentgroup.itemsPerPage))
            _start = (activePage - 1) * this.PaginationTable2.studentgroup.itemsPerPage
            _end = _start + this.PaginationTable2.studentgroup.itemsPerPage;
        }
        newPT={studentgroup:{start:_start,end:_end, 
          data:nextData.slice(_start,_end), 
          itemsPerPage:this.PaginationTable2.studentgroup.itemsPerPage, 
          activePage:activePage}}
        dispatch({type:'CHANGE_PAGINATION_TABLE2', PaginationTable2: newPT})
      },

      onChangePaginationTable3(_start,_end, activePage, nextData, action)
      {
        let newPT
        if(action==="delete") {
          if(activePage>Math.ceil(nextData.length/this.PaginationTable3.school.itemsPerPage))
          activePage=Math.ceil(nextData.length/this.PaginationTable3.school.itemsPerPage)
            _start = (activePage - 1) * this.PaginationTable3.school.itemsPerPage
            _end = _start + this.PaginationTable3.school.itemsPerPage;
        }
        if(action==="add") {
          if(activePage===Math.ceil(nextData.length/this.PaginationTable3.school.itemsPerPage))
            _start = (activePage - 1) * this.PaginationTable3.school.itemsPerPage
            _end = _start + this.PaginationTable3.school.itemsPerPage;
        }
        newPT={school:{start:_start,end:_end, 
          data:nextData.slice(_start,_end), 
          itemsPerPage:this.PaginationTable3.school.itemsPerPage, 
          activePage:activePage}}
        dispatch({type:'CHANGE_PAGINATION_TABLE3', PaginationTable3: newPT})
      },

      async onGetFetchCityTypes(url) {
        let json,cityTypes
        cityTypes= []
        let response=await fetch(url, {
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
          },
        })
        if(!response.ok) {
          json= await response.json()
          alert(json.errors.join("\n"))
          return -1
        }
        else {
          cityTypes= await response.json()
          dispatch({type : 'CRUD_CITYTYPES', CityTypes: cityTypes})
        }
      },

      async onGetFetchDistricts(url) {
        let json,districts
        districts= []
        let response=await fetch(url, {
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
          },
        })
        if(!response.ok) {
          json= await response.json()
          alert(json.errors.join("\n"))
          return -1
        }
        else {
          districts= await response.json()
          dispatch({type : 'CRUD_DISTRICTS', Districts: districts})
        }
      },

      async onGetFetchSchoolTypes(url) {
        let json,schoolTypes
        schoolTypes= []
        let response=await fetch(url, {
          headers: {'Content-Type': 'application/json',
            'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
          },
        })
        if(!response.ok) {
          json= await response.json()
          alert(json.errors.join("\n"))
          return -1
        }
        else {
          schoolTypes= await response.json()
          dispatch({type : 'CRUD_SCHOOLTYPES', SchoolTypes: schoolTypes})
        }
      },

    })
) (EditDirectories)