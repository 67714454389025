import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import 'bootstrap/dist/css/bootstrap.min.css';
import Collapse from 'react-bootstrap/Collapse'
import {Redirect} from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import {Modal} from 'react-bootstrap';
import {MDBIcon} from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import '../stylesheets/Classification.css';

    

class NewChars extends React.Component {
  constructor(props) {
      super(props)
  }
  
  componentDidMount() {
    this.props.onGetFetchWords(process.env.REACT_APP_URL+"/words");
    this.props.onGetFetchPVG(process.env.REACT_APP_URL+"/personal_value_groups");
  }

  editForm() {
    let _Resource,_Value,_Rejected,_PVG,_Positivity,ErrMessage=""

    if(this.props.ShowModalFormsAdmin.edit) {
      return (
      <Modal size="sm" show={this.props.ShowModalFormsAdmin.edit} 
        onHide={()=>this.props.onShowModalFormsAdmin(false,false,false,"Word")}
        >
        <Modal.Header closeButton>
          <Modal.Title>Дополнительная информация для слова</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formListPVG">
            <Form.Label>Эмоциональная категория</Form.Label>
            <Form.Control as="select" ref={input=>_PVG=input}>
            {
              this.props.PVG.map((pvg,idx)=>{
                return <option key={idx} value={pvg.id}>{pvg.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formListPositivity">
            <Form.Label>Позитивность</Form.Label>
            <Form.Control as="select" ref={input=>_Positivity=input}>
            {
              this.props.Positivity.map((positiv,idx)=>{
                return <option key={idx} value={positiv.value}>{positiv.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formValue">
            <Form.Label>Заполните ценность</Form.Label>
            <Form.Control ref={input=>_Value=input} type="text" placeholder="Ценность"/>
            </Form.Group>
          <Form.Group controlId="formResource">
            <Form.Label>Заполните ресурс</Form.Label>
            <Form.Control ref={input=>_Resource=input} type="text" placeholder="Ресурс"/>
          </Form.Group>
           <Form.Group controlId="formRejected">
            <Form.Label>Заполните отвергаемое</Form.Label>
            <Form.Control ref={input=>_Rejected=input} type="text" placeholder="Отвергаемое"/>
            </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
            onClick={()=>this.props.onShowModalFormsAdmin(false,false,false,"Word")
            }> Отмена </Button>
          <Button variant="primary" onClick={()=>{
            this.props.onPostFetchNewChars(process.env.REACT_APP_URL+"/new_characteristics",
              {"word_id": Number(this.props.ShowModalFormsAdmin.id),
              "personal_value_group_id": Number(_PVG.value),
              "positivity": Number(_Positivity.value),
              "value": _Value.value,
              "resources": _Resource.value,
              "rejected": _Rejected.value})
            this.props.onDeleteWords(this.props.ShowModalFormsAdmin.id)
            this.props.onShowModalFormsAdmin(false,false,false,"Word")
          }}> Классифицировать</Button>
        </Modal.Footer>
      </Modal>
      );
    } else return null;
  }

  getTDAction(word_id) {
      return (
        <td>
        <Container>
          <Row>
            <Col><Button variant="primary" type="submit" id={word_id} onClick={(e)=>{
                this.props.onShowModalFormsAdmin(false,true,false,"Word",word_id)
              }}> 
              <MDBIcon id={word_id} far icon="edit"/>
            </Button></Col>
          </Row>
        </Container>
        </td>
      );
  }

  showWordDetail(word_id,index) {
    let _Resource,_Value,_Rejected,_PVG,_Positivity
    _Resource=[]
    _Value=[]
    _Rejected=[]
    _PVG=[]
    _Positivity=[]
    //console.log(this.props.Words[index].result)
    if(this.props.Words[index].result===null||this.props.Words[index].result===undefined) 
      return <p>Данные пока отсутствуют</p>;
    else {
    return <div key={word_id}>
      <Container>
        <br></br>
        {this.props.Words[index].result.map((res,index1)=>{
          return (<div key={res.id}>
          <Row><Col>
          <Alert variant="primary">
            <p>{String("Эксперт № ")+String(index1+1)}</p>
          </Alert>
        </Col></Row>
          <Row><Col>
          <Form.Group controlId={String("formListPVG")+String(res.id)}>
            <Form.Label>Эмоциональная категория</Form.Label>
            <Form.Control as="select" ref={input=>_PVG[index1]=input}
              defaultValue={this.props.Words[index].result[index1].personal_value_group_id}
            >
            {
              this.props.PVG.map((pvg,idx)=>{
                return <option key={idx} value={pvg.id}>{pvg.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId={String("formListPositivity")+String(res.id)}>
            <Form.Label>Позитивность</Form.Label>
            <Form.Control as="select" ref={input=>_Positivity[index1]=input}
              defaultValue={this.props.Words[index].result[index1].positivity}
            >
            {
              this.props.Positivity.map((positiv,idx)=>{
                return <option key={idx} value={positiv.value}>{positiv.name}
                </option>
              })
            }
            </Form.Control>
          </Form.Group>
        </Col></Row>
          <Row>{/*<Col>
          //возможность добавлять ценность пока закоментирована. может быть когда-нибудь понадобится
            <Form.Group controlId={String("formValue")+String(res.id)}>
            <Form.Label>Ценность</Form.Label>
            <Form.Control ref={input=>_Value[index1]=input} type="text" placeholder="Ценность"
              defaultValue={this.props.Words[index].result[index1].value}
            />
          </Form.Group>
        </Col>*/}
        <Col>
          <Form.Group controlId={String("formResource")+String(res.id)}>
            <Form.Label>Ресурс</Form.Label>
            <Form.Control ref={input=>_Resource[index1]=input} type="text" placeholder="Ресурс"
              defaultValue={this.props.Words[index].result[index1].resources}
            />
          </Form.Group>
        </Col>
        <Col>
           <Form.Group controlId={String("formRejected")+String(res.id)}>
            <Form.Label>Отвергаемое</Form.Label>
            <Form.Control ref={input=>_Rejected[index1]=input} type="text" placeholder="Отвергаемое"
              defaultValue={this.props.Words[index].result[index1].rejected}
            />
          </Form.Group>
        </Col>
        </Row>
        <Row><Col>
          <Button variant="primary" key={index1}
            onClick={()=>{              
              this.props.onPostFetchСharacteristic(process.env.REACT_APP_URL+"/characteristics",
                {personal_value_group_id:_PVG[index1].value,name:this.props.Words[index].name,
                  //value:_Value[index1].value,
                  resources:_Resource[index1].value,
                  rejected:_Rejected[index1].value,
                  positivity:_Positivity[index1].value},word_id)
            }}>
              Утвердить
          </Button>
        </Col></Row>
        <br></br>
        </div>
          );
          })
        }
      </Container>
    </div>;
    }
  }

  showWordsResult() {
    if (this.props.Words.length!==0) {
      
    return <div key="showWordsResult">
      {this.props.Words.map((word,index)=>{
        let WordDetail=this.showWordDetail(word.id,index)
        return (<div key={index}>
        <Button
          onClick={() => {this.props.onChangeCollapseWord(word.id,!word.collapse)}}
          aria-controls={word.id}
          aria-expanded={word.collapse}
          >
          {String("Подробная информация для слова: ")}
          <span style={{color: "maroon", fontWeight: "bold"}}>{word.name}</span>
          {String(". Частота упоминания равна: ")}
          <span style={{color: "maroon", fontWeight: "bold"}}>{word.ref_number}</span>
        </Button>
        <br></br>
        <Collapse in={word.collapse}>
          <div key={word.id} id={word.id}>
            {WordDetail}
          </div>
        </Collapse>
        <br></br>
        </div>);
      })
      }
    </div>;
    }
    else return null;
  }

  render() {
    let showResultWords=this.showWordsResult()   
    return <div key="home">
      <Container>
        <Row><Col>
            <Alert variant="primary">
              <Alert.Heading>Утверждение новых характеристик</Alert.Heading>
              <p>Выберите для утверждения один из предложенных вариантов классификации слова или 
                  отредактируйте наиболее подходящий вариант.
              </p>
            </Alert>
          </Col></Row>
          <Row>
            <Col>
            {showResultWords}
          </Col></Row>
        </Container>
      </div>;
    }
  }
  
  
  export default NewChars