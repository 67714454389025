import React from 'react';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Collapse from 'react-bootstrap/Collapse'
import Table from 'react-bootstrap/Table'
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import '../stylesheets/Stat.css';
import {ActionCableProvider, ActionCable, ActionCableConsumer} from 'actioncable-client-react';
//import { ActionCable } from "actioncable";
//import { ActionCableProvider, ActionCableConsumer } from 'react-actioncable-provider';
//import classNames from 'classnames';
import {Calendar} from 'primereact/calendar';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import cable from './Cable'



//let web= new WebSocket('ws://176.96.80.210:3000/cable')

//const cable2 = this.ActionCable.createConsumer("ws://"+process.env.REACT_APP_URL.substr(7)+"/cable");

class Stat extends React.Component/*PureComponent*/ {
  constructor(props) {
      super(props)
      this.handleReceived=this.handleReceived.bind(this)
      this.handleReceived2=this.handleReceived2.bind(this)
      this.handleReceived3=this.handleReceived3.bind(this)
  }
  
  componentDidMount() {
    this.Initloads()
  }

  async Initloads() {
    let startDate=new Date()
    let endDate=new Date()
    let sex, age, ct, district, st, school, city, student_group
    startDate.setMonth(startDate.getMonth()-3)
    startDate.setHours(0,0,0,0)
    endDate.setHours(0,0,0,0)

    await this.props.onGetFetchPVG(process.env.REACT_APP_URL+"/personal_value_groups")
    await this.props.onGetFetchCityTypes(process.env.REACT_APP_URL+"/city_types")
    await this.props.onGetFetchDistricts(process.env.REACT_APP_URL+"/districts")
    await this.props.onGetFetchCities(process.env.REACT_APP_URL+"/cities")
    await this.props.onGetFetchSchoolTypes(process.env.REACT_APP_URL+"/school_types")
    await this.props.onGetFetchSchools(process.env.REACT_APP_URL+"/schools");
    if(this.props.Schools.length!==0) await this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+
      "/student_groups"/*?filterrific[with_school_id]="+String(this.props.Schools[0].id)*/)

    await this.props.onGetFetchFiltersDS(process.env.REACT_APP_URL+
      "/statistics?filterrific[with_type]=descriptive&filterrific[with_user_id]="+
      String(this.props.User.id))
    await this.props.onGetFetchFiltersCS(process.env.REACT_APP_URL+
      "/statistics?filterrific[with_type]=corr&filterrific[with_user_id]="+
      String(this.props.User.id))
    await this.props.onGetFetchFiltersTS(process.env.REACT_APP_URL+
      "/statistics?filterrific[with_type]=tests&filterrific[with_user_id]="+
      String(this.props.User.id))
    if(this.props.Sex.length!==0) sex=this.props.Sex[0].value; else sex=null
    if(this.props.Ages.length!==0) age=this.props.Ages[0].value; else age=null
    if(this.props.CityTypes.length!==0) ct=this.props.CityTypes[0].id; else ct=null
    if(this.props.Districts.length!==0) district=this.props.Districts[0].id; else district=null
    if(this.props.SchoolTypes.length!==0) st=this.props.SchoolTypes[0].id; else st=null
    if(this.props.Schools.length!==0) school=this.props.Schools[0].id; else school=null
    if(this.props.Cities.length!==0) city=this.props.Cities[0].id; else city=null
    if(this.props.StudentGroups.length!==0) student_group=this.props.StudentGroups[0].id; else student_group=null
    await this.props.onUpdateBuildFilter(
    { checkedDate:true,
      checkedSex:false,
      checkedAge:false,
      checkedCityTypes:false,
      checkedDistricts:false,
      checkedSchoolTypes:false,
      checkedSchool:false,
      checkedStudentGroup: false,
      checkedCity:false,
      with_start_dt: startDate,
      with_end_dt: endDate,
      with_gender: sex,
      with_city_id: city,
      with_district_id: district,
      with_school_type_id: st,
      with_school_id: school,
      with_student_group_id: student_group,
      with_age: age,
      with_city_type_id: ct
    })
    await this.props.onUpdateBuildFilter2(
      { with_start_dt: startDate,
        with_end_dt: endDate,
        with_alpha: 0.95,
      })
    //console.log("1")
    await this.props.onUpdateBuildFilter3(
      { checkedDate:true,
        checkedSex:false,
        checkedAge:false,
        checkedCityTypes:false,
        checkedDistricts:false,
        checkedSchoolTypes:false,
        checkedSchool:false,
        checkedCity:false,
        with_start_dt: startDate,
        with_end_dt: endDate,
        with_gender: sex,
        with_city_id: city,
        with_district_id: district,
        with_school_type_id: st,
        with_school_id: school,
        with_student_group_id: student_group,
        with_alpha: 0.95,
        with_age: age,
        with_city_type_id: ct
      })
    //console.log("2")
  }

  handleReceived(response){
   
    console.log(response)
    //проверка на наличие ошибок в данных по веб-сокету
    if(response.stat_task.err!=="") alert(response.stat_task.err)
    //else
    this.props.onUpdateFiltersDS(response)
  }

  handleReceived2(response){
   
    console.log(response)
    //проверка на наличие ошибок в данных по веб-сокету
    if(response.stat_task.err!=="") alert(response.stat_task.err)
    //else
    this.props.onUpdateFiltersTS(response)
  }

  handleReceived3(response){
   
    console.log(response)
    //проверка на наличие ошибок в данных по веб-сокету
    if(response.stat_task.err!=="") alert(response.stat_task.err)
    //else
    //console.log(this.props.FiltersCS)
    this.props.onUpdateFiltersCS(response)
    //this.props.temp(response)
  }

  renderFilter(){
    let tempObj
    return (<div key="filter">
      <Alert variant="primary">
        <p>Компоненты фильтра</p>
      </Alert>
      <Row><Col xs={2}>
        <Form.Check key={'date'} //checked={true}
          defaultChecked={this.props.BuildFilterDS.checkedDate} 
          id={'date'} 
          type="checkbox" label={'Дата'}
          onChange={(e)=>{
            //console.log(e.target.checked)
            tempObj=this.props.BuildFilterDS
            tempObj.checkedDate=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col xs={2}> <Form.Label>начало:</Form.Label> </Col>
      <Col md="auto">
        <Calendar id="start_date"  value={new Date(this.props.BuildFilterDS.with_start_dt)}
          dateFormat="dd.mm.yy"
          //locale={"ru"}
          showIcon
          onChange={(e) => {
            tempObj=this.props.BuildFilterDS
            tempObj.with_start_dt=e.value
            this.props.onUpdateBuildFilter(tempObj)
          }}>
        </Calendar>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row>
        <Col xs={2}></Col>
        <Col xs={2}><Form.Label>конец:</Form.Label></Col>
        <Col md="auto">
          <Calendar id="end_date"  value={new Date(this.props.BuildFilterDS.with_end_dt)}
            dateFormat="dd.mm.yy"
            showIcon
            onChange={(e) => {
              tempObj=this.props.BuildFilterDS
              tempObj.with_end_dt=e.value
              this.props.onUpdateBuildFilter(tempObj)
            }}>
          </Calendar>
        </Col>
        <br></br>
        <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'sex'} defaultChecked={this.props.BuildFilterDS.checkedSex} 
          id={'sex'} 
          type="checkbox" label={'Пол'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedSex=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_Sex=input}
            defaultValue={this.props.BuildFilterDS.with_gender}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterDS
              tempObj.with_gender=e.target.value
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.Sex.map((sex,idx)=>{
              return <option key={idx} value={sex.value}>{sex.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'age'} defaultChecked={this.props.BuildFilterDS.checkedAge} 
          id={'age'} 
          type="checkbox" label={'Возраст'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedAge=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_Ages=input}
            defaultValue={this.props.BuildFilterDS.with_age}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterDS
              tempObj.with_age=e.target.value
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.Ages.map((age,idx)=>{
              return <option key={idx} value={age.value}>{age.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'city'} defaultChecked={this.props.BuildFilterDS.checkedCity} 
          id={'city'} 
          type="checkbox" label={'Населенный пункт'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedCity=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterDS.with_city_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterDS
              tempObj.with_city_id=Number(e.target.value)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.Cities.map((city,idx)=>{
              return <option key={idx} value={city.id}>{city.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'city_types'} defaultChecked={this.props.BuildFilterDS.checkedCityTypes} 
          id={'city_types'} 
          type="checkbox" label={'Тип насел. пункта'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedCityTypes=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_CityTypes=input}
            defaultValue={this.props.BuildFilterDS.with_city_type_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterDS
              tempObj.with_city_type_id=Number(e.target.value)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.CityTypes.map((ct,idx)=>{
              return <option key={idx} value={ct.id}>{ct.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'districts'} defaultChecked={this.props.BuildFilterDS.checkedDistricts} 
          id={'districts'} 
          type="checkbox" label={'Район'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedDistricts=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_Districts=input}
            defaultValue={this.props.BuildFilterDS.with_district_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterDS
              tempObj.with_district_id=Number(e.target.value)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.Districts.map((distr,idx)=>{
              return <option key={idx} value={distr.id}>{distr.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'school_types'} defaultChecked={this.props.BuildFilterDS.checkedSchoolTypes} 
          id={'school_types'} 
          type="checkbox" label={'Тип учеб. заведения'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedSchoolTypes=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_SchoolTypes=input}
            defaultValue={this.props.BuildFilterDS.with_school_type_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterDS
              tempObj.with_school_type_id=Number(e.target.value)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.SchoolTypes.map((st,idx)=>{
              return <option key={idx} value={st.id}>{st.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'schools'} defaultChecked={this.props.BuildFilterDS.checkedSchool} 
          id={'schools'} 
          type="checkbox" label={'Школа'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedSchool=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select" //ref={input=>_School=input}
            defaultValue={this.props.BuildFilterDS.with_school_id}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterDS
              tempObj.with_school_id=Number(e.target.value)
              //console.log(tempObj)
              //this.props.onGetFetchStudentGroups(process.env.REACT_APP_URL+
              //  "/student_groups?filterrific[with_school_id]="+e.target.value)
              //console.log(this.props.StudentGroups)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.Schools.map((school,idx)=>{
              return <option key={idx} value={school.id}>{school.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'student_groups'} defaultChecked={this.props.BuildFilterDS.checkedStudentGroup} 
          id={'student_groups'} 
          type="checkbox" label={'Класс'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterDS
            tempObj.checkedStudentGroup=e.target.checked
            this.props.onUpdateBuildFilter(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterDS.with_student_group_id}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterDS
              tempObj.with_student_group_id=Number(e.target.value)
              //console.log(tempObj)
              this.props.onUpdateBuildFilter(tempObj)
            }}
          >
          {
            this.props.StudentGroups.map((SG,idx)=>{
              return <option key={idx} value={SG.id}>{SG.name+" | "+this.props.Schools.find(t => t.id===SG.school_id).name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col>
        <Button variant="primary" key={'button_filter'}
          onClick={(e)=>{
            let sdt=null, edt=null, sex=null, district=null, st=null, school=null, city=null
            let age=null, ct=null, isChecked=false, studentGroup=null
           
            if(this.props.BuildFilterDS.checkedDate) {
              isChecked=true
              sdt=new Date(this.props.BuildFilterDS.with_start_dt)
              edt=new Date(this.props.BuildFilterDS.with_end_dt)
              sdt=sdt.toLocaleDateString().substr(6,4)+"-"+
                sdt.toLocaleDateString().substr(3,2)+"-"+
                sdt.toLocaleDateString().substr(0,2)
              edt=edt.toLocaleDateString().substr(6,4)+"-"+
                edt.toLocaleDateString().substr(3,2)+"-"+
                edt.toLocaleDateString().substr(0,2)
            }
            if(this.props.BuildFilterDS.checkedSex) {
              isChecked=true
              sex=this.props.BuildFilterDS.with_gender
            }
            if(this.props.BuildFilterDS.checkedAge) {
              isChecked=true
              age=this.props.BuildFilterDS.with_age
            }
            if(this.props.BuildFilterDS.checkedCityTypes) {
              isChecked=true
              ct=this.props.BuildFilterDS.with_city_type_id
            }
            if(this.props.BuildFilterDS.checkedDistricts) {
              isChecked=true
              district=this.props.BuildFilterDS.with_district_id
            }
            if(this.props.BuildFilterDS.checkedSchoolTypes) {
              isChecked=true
              st=this.props.BuildFilterDS.with_school_type_id
            }
            if(this.props.BuildFilterDS.checkedSchool) {
              isChecked=true
              school=this.props.BuildFilterDS.with_school_id
            }
            if(this.props.BuildFilterDS.checkedCity) {
              isChecked=true
              city=this.props.BuildFilterDS.with_city_id
            }
            if(this.props.BuildFilterDS.checkedStudentGroup) {
              isChecked=true
              studentGroup=this.props.BuildFilterDS.with_student_group_id
            }
            
            if(!isChecked) alert("Необходимо выбрать хотя бы один из компонентов фильта")
            else { 
              this.props.onPostFetchFiltersDS(process.env.REACT_APP_URL+
                "/statistics/descriptive_statistics",
                {with_start_dt: sdt,
                 with_end_dt: edt,
                 with_gender: sex,
                 with_city_id: city,
                 with_district_id: district,
                 with_school_type_id: st,
                 with_school_id: school,
                 with_age: age,
                 with_city_type_id: ct,
                 with_student_group_id: studentGroup
                })
            }
          }}
        > Добавить фильтр
        </Button>
        <br></br>
        <br></br>
      </Col></Row>
    </div>
    );
  }

  renderFilter2(){
    let tempObj
    return (<div key="filter2">
      <Alert variant="primary">
        <p>Компоненты фильтра</p>
      </Alert>
      <Row>
      <Col xs={3}> <Form.Label>Начальная дата:</Form.Label> </Col>
      <Col md="auto">
        <Calendar id="start_date"  value={new Date(this.props.BuildFilterTS.with_start_dt)}
          dateFormat="dd.mm.yy"
          showIcon
          onChange={(e) => {
            tempObj=this.props.BuildFilterTS
            tempObj.with_start_dt=e.value
            this.props.onUpdateBuildFilter2(tempObj)
          }}>
        </Calendar>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row>
        <Col xs={3}><Form.Label>Конечная дата:</Form.Label></Col>
        <Col md="auto">
          <Calendar id="end_date"  value={new Date(this.props.BuildFilterTS.with_end_dt)}
            dateFormat="dd.mm.yy"
            showIcon
            onChange={(e) => {
              tempObj=this.props.BuildFilterTS
              tempObj.with_end_dt=e.value
              this.props.onUpdateBuildFilter2(tempObj)
            }}>
          </Calendar>
        </Col>
        <br></br>
        <br></br>
      </Row>
      <Row>
        <Col md="auto"><Form.Label>Уровень значимости:</Form.Label></Col>
        <Col md="auto">
          <Form.Control type="text"
            //defaultValue={String(Number(1-this.props.BuildFilterTS.with_alpha).toFixed(2))}
            //defaultValue={Number(1-this.props.BuildFilterTS.with_alpha).toFixed(2)}
            defaultValue={0.05}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterTS
              tempObj.with_alpha=1-Number(e.target.value)
              this.props.onUpdateBuildFilter2(tempObj)
            }}
          >
          </Form.Control>
        </Col>
      <br></br>
      <br></br>
      </Row>
      
      <Row><Col>
        <Button variant="primary" key={'button_filter2'}
          onClick={(e)=>{
            let sdt=null, edt=null, alpha=null
           
              sdt=new Date(this.props.BuildFilterTS.with_start_dt)
              edt=new Date(this.props.BuildFilterTS.with_end_dt)
              sdt=sdt.toLocaleDateString().substr(6,4)+"-"+
                sdt.toLocaleDateString().substr(3,2)+"-"+
                sdt.toLocaleDateString().substr(0,2)
              edt=edt.toLocaleDateString().substr(6,4)+"-"+
                edt.toLocaleDateString().substr(3,2)+"-"+
                edt.toLocaleDateString().substr(0,2)
          
              alpha=this.props.BuildFilterTS.with_alpha
              //console.log(Number.isNaN(alpha))
              if(Number.isNaN(alpha)||alpha>1||alpha<0) alert("Введено некорректное значение уровня значимости")
              else {//console.log(alpha)
                this.props.onPostFetchFiltersTS(process.env.REACT_APP_URL+
                  "/statistics/test_statistics",
                  {with_start_dt: sdt,
                   with_end_dt: edt,
                   with_alpha: alpha
                  })
              }
            }
          }
        > Добавить фильтр
        </Button>
        <br></br>
        <br></br>
      </Col></Row>
    </div>
    );
  }


  renderFilter3(){
    let tempObj
    return (<div key="filter3">
      <Alert variant="primary">
        <p>Компоненты фильтра</p>
      </Alert>
      <Row><Col xs={2}>
        <Form.Check key={'date3'}
          defaultChecked={this.props.BuildFilterCS.checkedDate} 
          id={'date3'} 
          type="checkbox" label={'Дата'}
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedDate=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col xs={2}> <Form.Label>начало:</Form.Label> </Col>
      <Col md="auto">
        <Calendar id="start_date3"  value={new Date(this.props.BuildFilterCS.with_start_dt)}
          dateFormat="dd.mm.yy"
          showIcon
          onChange={(e) => {
            tempObj=this.props.BuildFilterCS
            tempObj.with_start_dt=e.value
            this.props.onUpdateBuildFilter3(tempObj)
          }}>
        </Calendar>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row>
        <Col xs={2}></Col>
        <Col xs={2}><Form.Label>конец:</Form.Label></Col>
        <Col md="auto">
          <Calendar id="end_date3"  value={new Date(this.props.BuildFilterCS.with_end_dt)}
            dateFormat="dd.mm.yy"
            showIcon
            onChange={(e) => {
              tempObj=this.props.BuildFilterCS
              tempObj.with_end_dt=e.value
              this.props.onUpdateBuildFilter3(tempObj)
            }}>
          </Calendar>
        </Col>
        <br></br>
        <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'sex3'} defaultChecked={this.props.BuildFilterCS.checkedSex} 
          id={'sex3'} 
          type="checkbox" label={'Пол'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedSex=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_gender}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_gender=e.target.value
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.Sex.map((sex,idx)=>{
              return <option key={idx} value={sex.value}>{sex.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'age3'} defaultChecked={this.props.BuildFilterCS.checkedAge} 
          id={'age3'} 
          type="checkbox" label={'Возраст'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedAge=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_age}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_age=e.target.value
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.Ages.map((age,idx)=>{
              return <option key={idx} value={age.value}>{age.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'city3'} defaultChecked={this.props.BuildFilterCS.checkedCity} 
          id={'city3'} 
          type="checkbox" label={'Населенный пункт'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedCity=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_city_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_city_id=Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.Cities.map((city,idx)=>{
              return <option key={idx} value={city.id}>{city.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'city_types3'} defaultChecked={this.props.BuildFilterCS.checkedCityTypes} 
          id={'city_types3'} 
          type="checkbox" label={'Тип насел. пункта'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedCityTypes=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_city_type_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_city_type_id=Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.CityTypes.map((ct,idx)=>{
              return <option key={idx} value={ct.id}>{ct.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'districts3'} defaultChecked={this.props.BuildFilterCS.checkedDistricts} 
          id={'districts3'} 
          type="checkbox" label={'Район'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedDistricts=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_district_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_district_id=Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.Districts.map((distr,idx)=>{
              return <option key={idx} value={distr.id}>{distr.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'school_types3'} defaultChecked={this.props.BuildFilterCS.checkedSchoolTypes} 
          id={'school_types3'} 
          type="checkbox" label={'Тип учеб. заведения'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedSchoolTypes=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_school_type_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_school_type_id=Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.SchoolTypes.map((st,idx)=>{
              return <option key={idx} value={st.id}>{st.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'schools3'} defaultChecked={this.props.BuildFilterCS.checkedSchool} 
          id={'schools3'} 
          type="checkbox" label={'Школа'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedSchool=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_school_id}
            onChange={(e)=>{
              tempObj=this.props.BuildFilterCS
              tempObj.with_school_id=Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.Schools.map((school,idx)=>{
              return <option key={idx} value={school.id}>{school.name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row><Col md="auto">
        <Form.Check key={'student_groups3'} defaultChecked={this.props.BuildFilterCS.checkedStudentGroup} 
          id={'student_groups3'} 
          type="checkbox" label={'Класс'} 
          onChange={(e)=>{
            tempObj=this.props.BuildFilterCS
            tempObj.checkedStudentGroup=e.target.checked
            this.props.onUpdateBuildFilter3(tempObj)
          }}
        >
        </Form.Check>
      </Col>
      <Col>
          <Form.Control as="select"
            defaultValue={this.props.BuildFilterCS.with_student_group_id}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterCS
              tempObj.with_student_group_id=Number(e.target.value)
              //console.log(tempObj)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          {
            this.props.StudentGroups.map((SG,idx)=>{
              return <option key={idx} value={SG.id}>{SG.name+" | "+this.props.Schools.find(t => t.id===SG.school_id).name}
              </option>
            })
          }
          </Form.Control>
      </Col>
      <br></br>
      <br></br>
      </Row>
      <Row>
        <Col md="auto"><Form.Label>Уровень значимости:</Form.Label></Col>
        <Col md="auto">
          <Form.Control type="text"
            //defaultValue={Number(1-this.props.BuildFilterCS.with_alpha).toFixed(2)}
            defaultValue={0.05}
            onChange={(e)=>{
              //console.log(e.target.value)
              tempObj=this.props.BuildFilterCS
              tempObj.with_alpha=1-Number(e.target.value)
              this.props.onUpdateBuildFilter3(tempObj)
            }}
          >
          </Form.Control>
        </Col>
      <br></br>
      <br></br>
      </Row>

      <Row><Col>
        <Button variant="primary" key={'button_filter3'}
          onClick={(e)=>{
            let sdt=null, edt=null, sex=null, district=null, st=null, school=null, city=null
            let age=null, ct=null, isChecked=false, studentGroup=null, alpha=null
           
            if(this.props.BuildFilterCS.checkedDate) {
              isChecked=true
              sdt=new Date(this.props.BuildFilterCS.with_start_dt)
              edt=new Date(this.props.BuildFilterCS.with_end_dt)
              sdt=sdt.toLocaleDateString().substr(6,4)+"-"+
                sdt.toLocaleDateString().substr(3,2)+"-"+
                sdt.toLocaleDateString().substr(0,2)
              edt=edt.toLocaleDateString().substr(6,4)+"-"+
                edt.toLocaleDateString().substr(3,2)+"-"+
                edt.toLocaleDateString().substr(0,2)
            }
            if(this.props.BuildFilterCS.checkedSex) {
              isChecked=true
              sex=this.props.BuildFilterCS.with_gender
            }
            if(this.props.BuildFilterCS.checkedAge) {
              isChecked=true
              age=this.props.BuildFilterCS.with_age
            }
            if(this.props.BuildFilterCS.checkedCityTypes) {
              isChecked=true
              ct=this.props.BuildFilterCS.with_city_type_id
            }
            if(this.props.BuildFilterCS.checkedDistricts) {
              isChecked=true
              district=this.props.BuildFilterCS.with_district_id
            }
            if(this.props.BuildFilterCS.checkedSchoolTypes) {
              isChecked=true
              st=this.props.BuildFilterCS.with_school_type_id
            }
            if(this.props.BuildFilterCS.checkedSchool) {
              isChecked=true
              school=this.props.BuildFilterCS.with_school_id
            }
            if(this.props.BuildFilterCS.checkedCity) {
              isChecked=true
              city=this.props.BuildFilterCS.with_city_id
            }

            if(this.props.BuildFilterCS.checkedStudentGroup) {
              isChecked=true
              studentGroup=this.props.BuildFilterCS.with_student_group_id
            }

            alpha=this.props.BuildFilterCS.with_alpha
           
            if(!isChecked) alert("Необходимо выбрать хотя бы один из компонентов фильта")
            else { 
              if(Number.isNaN(alpha)||alpha>1||alpha<0) alert("Введено некорректное значение уровня значимости")
              else {
                this.props.onPostFetchFiltersCS(process.env.REACT_APP_URL+
                  "/statistics/corr_statistics",
                  {with_start_dt: sdt,
                   with_end_dt: edt,
                   with_gender: sex,
                   with_city_id: city,
                   with_district_id: district,
                   with_school_type_id: st,
                   with_school_id: school,
                   with_age: age,
                   with_city_type_id: ct,
                   with_student_group_id: studentGroup,
                   with_alpha: alpha
                  })
              }
            }
          }}
        > Добавить фильтр
        </Button>
        <br></br>
        <br></br>
      </Col></Row>
    </div>
    );
  }

  showDetailFiltersDS(fds, index){
    if(fds.status!=="finished") 
      return <p>Данные отсутствуют</p>;
    else {
      return <div key={String("detail_"+fds.code)}>
        <br></br>
         {/*1 таблица */}
        <h4>{"Таблица 1. Статистика персонажей в выборке ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Среднее</th>
            <th>Стандартное отклонение</th>
            <th>Минимум</th>
            <th>Максимум</th>
            <th>Мода</th>
            <th>Медиана</th>
          </tr>
        </thead>
        <tbody>
          <tr key="respondent">
            <td>Кол-во персонажей на респондента</td>
            <td>{fds.stat.persons.mean.toFixed(2)}</td>
            <td>{fds.stat.persons.std.toFixed(2)}</td>
            <td>{fds.stat.persons.min.toFixed(2)}</td>
            <td>{fds.stat.persons.max.toFixed(2)}</td>
            <td>{fds.stat.persons.mode.toFixed(2)}</td>
            <td>{fds.stat.persons.median.toFixed(2)}</td>
          </tr>
          <tr key="respondent_time">
            <td>Время работы респондента</td>
            <td>{fds.stat.times.mean.toFixed(2)}</td>
            <td>{fds.stat.times.std.toFixed(2)}</td>
            <td>{fds.stat.times.min.toFixed(2)}</td>
            <td>{fds.stat.times.max.toFixed(2)}</td>
            <td>{fds.stat.times.mode.toFixed(2)}</td>
            <td>{fds.stat.times.median.toFixed(2)}</td>
          </tr>
        </tbody>
        </Table>
         {/*2 таблица */}
        <h4>{"Таблица 2. Социальные/институциональные категории других людей в сознании респондентов ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Наименование социальной роли (группы, института)</th>
            <th>% от общего кол-ва персонажей</th>
            <th>кол-во упоминаний в среднем на респондента</th>
            <th>Стандартное отклонение</th>
            <th>Медиана</th>
            <th>Мода</th>
            <th>Минимум</th>
            <th>Максимум</th>
            <th>% респондентов, не указавших категорию</th>
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.stat_by_role.map((sbr,idx)=>{
            return (
              <tr key={idx}>
                <td>{sbr.role_name}</td>
                <td>{sbr.percent.toFixed(3)}</td>
                <td>{sbr.mean.toFixed(5)}</td>
                <td>{sbr.std.toFixed(5)}</td>
                <td>{sbr.median.toFixed(5)}</td>
                <td>{sbr.mode.toFixed(5)}</td>
                <td>{sbr.min.toFixed(5)}</td>
                <td>{sbr.max.toFixed(5)}</td>
                <td>{sbr.not_indicate_number.toFixed(2)}</td>
              </tr>
            );
          })
        }
        </tbody>
        </Table>
         {/*3 таблица */}
        <h4>{"Таблица 3. Содержание эмоциональных категорий в описании персонажей ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Наименование эмоциональных категорий</th>
            <th>% от общего кол-ва признаков</th>
            <th>кол-во упоминаний в среднем на респондента</th>
            <th>Стандартное отклонение</th>
            <th>Медиана</th>
            <th>Мода</th>
            <th>Минимум</th>
            <th>Максимум</th>
            <th>% респондентов, не указавших категорию</th>
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.stat_by_personal_value_group.map((pvg,idx)=>{
            return (
              <tr key={idx}>
                <td>{pvg.role_name}</td>
                <td>{pvg.percent.toFixed(3)}</td>
                <td>{pvg.mean.toFixed(5)}</td>
                <td>{pvg.std.toFixed(5)}</td>
                <td>{pvg.median.toFixed(5)}</td>
                <td>{pvg.mode.toFixed(5)}</td>
                <td>{pvg.min.toFixed(5)}</td>
                <td>{pvg.max.toFixed(5)}</td>
                <td>{pvg.not_indicate_number.toFixed(2)}</td>
              </tr>
            );
          })
        }
        </tbody>
        </Table>
         {/*4 таблица */}
        <h4>{"Таблица 4. Распределение категорий ценностной оценки персонажей ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Наименование категории ценностной оценки</th>
            <th>% от общего кол-ва персонажей</th>
            <th>в среднем на респондента</th>
            <th>Стандартное отклонение</th>
            <th>Медиана</th>
            <th>Мода</th>
            <th>Минимум</th>
            <th>Максимум</th>
            <th>% респондентов с преобладанием данной категории</th>
            <th>% респондентов, не указавших категорию</th>
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.stat_by_group.map((group,idx)=>{
            return (
              <tr key={idx}>
                <td>{group.name}</td>
                <td>{group.percent.toFixed(3)}</td>
                <td>{group.mean.toFixed(5)}</td>
                <td>{group.std.toFixed(5)}</td>
                <td>{group.median.toFixed(5)}</td>
                <td>{group.mode.toFixed(5)}</td>
                <td>{group.min.toFixed(5)}</td>
                <td>{group.max.toFixed(5)}</td>
                <td>{group.mean_count_per_student.toFixed(2)}</td>
                <td>{group.not_indicate_number.toFixed(2)}</td>
              </tr>
            );
          })
        }
        </tbody>
        </Table>
         {/*5 таблица */}
        <h4>{"Таблица 5. Эмоциональные характеристики ролевых (институциональных) категорий ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th rowSpan="2"><p>Наименование социальной</p><p> роли (группы, института)</p></th>
            <th colSpan={this.props.PVG.length}>
              % эмоциональной категории к общему кол-ву признаков для данной роли (группы, института)
            </th>
          </tr>
          <tr>
            {this.props.PVG.map((pvg,idx)=>{
              return (
                <th height="290px" key={idx}><p className="vertical">{pvg.name}</p></th>
              );
            })
            }
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.stat_by_role_and_personal_value_group.map((rpvg,idx)=>{
            return (
              <tr key={idx}>
                <td>{rpvg[0].role}</td>
                {this.props.PVG.map((pvg,idx2)=>{
                  return (
                    <td key={idx2}>{rpvg[idx2].value.toFixed(2)}</td>
                  );
                })}
              </tr>
            );
          })
        }
        </tbody>
        </Table>
        {/*6 таблица */}
        <h4>{"Таблица 6. Ценностная оценка ролевых/институциональных категорий ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th rowSpan="2"><p>Наименование социальной</p><p> роли (группы, института)</p></th>
            <th colSpan="6">
              % категории ценностной оценки к общему кол-ву признаков для данной роли (группы, института)
            </th>
          </tr>
          <tr>
            <th><p>«идеалы»</p></th>
            <th><p>«лучше меня»</p></th>
            <th><p>«равноценные мне»</p></th>
            <th><p>«хуже меня»</p></th>
            <th><p>«антиидеалы»</p></th>
            <th><p>«выше среднего»</p></th>
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.stat_by_role_and_voluation.map((rv,idx)=>{
            return (
              <tr key={idx}>
                <td>{rv[0].role_name}</td>
                <td>{rv[0].value.toFixed(2)}</td>
                <td>{rv[1].value.toFixed(2)}</td>
                <td>{rv[2].value.toFixed(2)}</td>
                <td>{rv[3].value.toFixed(2)}</td>
                <td>{rv[4].value.toFixed(2)}</td>
                <td>{rv[5].value.toFixed(2)}</td>
              </tr>
            );
          })
        }
        </tbody>
        </Table>
        {/*7 таблица */}
        <h4>{"Таблица 7. Социально-психологические портреты каждой оценочной категории ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+". Количество признаков: "+String(fds.stat.chars_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Оценочная категория</th>
            <th>Кто? (ролевой. социальный/институциональный состав, %)</th>
            <th>Какие? (эмоциональные признаки, %)</th>
            <th>Какие ценности воплощают? (ценностные категории,%)</th>
          </tr>
        </thead>
        <tbody>
        {
          fds.stat.social_portrait.map((sp,idx)=>{
            return (
              <tr key={idx}>
                <td>{sp.valuation_profile_name}</td>
                <td>{sp.roles}</td>
                <td>{sp.characteristics}</td>
                <td>{sp.groups}</td>
              </tr>
            );
          })
        }
        </tbody>
        </Table>
        {/*8 таблица */}
        <h4>{"Таблица 8. Самооценка респондентов ("+
          String(this.getParamFilterToString(fds.filter))+". Количество респондентов: "+
          String(fds.stat.respondents_number)+". Количество персонажей: "+
          String(fds.stat.persons_number)+")"}
        </h4>
        <Table responsive="sm" striped bordered hover size="sm">
        <thead>
          <tr>
            <th colSpan="4">Уровни самооценки (% выборки)</th>
            <th rowSpan="2">Среднее</th>
            <th rowSpan="2">Стандартное отклонение</th>
            <th rowSpan="2">Медиана</th>
            <th rowSpan="2">Мода</th>
            <th rowSpan="2">Минимум</th>
            <th rowSpan="2">Максимум</th>
          </tr>
          <tr>
            <th>высокая</th>
            <th>норма</th>
            <th>сниженная</th>
            <th>низкая</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{fds.stat.stat_by_self_esteem.first_part[0].percent.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.first_part[2].percent.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.first_part[3].percent.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.first_part[1].percent.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.mean.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.std.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.median.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.mode.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.min.toFixed(2)}</td>
            <td>{fds.stat.stat_by_self_esteem.second_part.max.toFixed(2)}</td>
          </tr>
        </tbody>
        </Table>
      </div>;
    }
  }

  showDetailFiltersTS(fts, index){
    if(fts.status!=="finished") 
      return <p>Данные отсутствуют</p>;
    else {
      if(Object.entries(fts.stat).length===0){
        return <p>По запросу данные отсутствуют</p>;}
      else {
        return <div key={String("detail_"+fts.code)} className="datatable-doc-demo">
          <DataTable value={fts.stat["Cамооценка"]}
           header={<div className="table-header">Cамооценка</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="list" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Количество персонажей"]}
           header={<div className="table-header">Количество персонажей</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="list" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Количество признаков"]}
           header={<div className="table-header">Количество признаков</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="list" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Ценностная оценка в восприятии социальных категорий"]}
           header={<div className="table-header">Ценностная оценка в восприятии социальных категорий</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="name" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
            <Column field="list" header="Сравниваемые выборки" sortable filter filterPlaceholder="Поиск по сравниваемым выборкам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Частота каждой категории ценностной оценки"]}
           header={<div className="table-header">Частота каждой категории ценностной оценки</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="name" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
            <Column field="list" header="Сравниваемые выборки" sortable filter filterPlaceholder="Поиск по сравниваемым выборкам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Частота каждой категории эмоционайльной направленности"]}
           header={<div className="table-header">Частота каждой категории эмоциональной направленности</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="name" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
            <Column field="list" header="Сравниваемые выборки" sortable filter filterPlaceholder="Поиск по сравниваемым выборкам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Частота каждой социальной категории"]}
           header={<div className="table-header">Частота каждой социальной категории</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="name" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
            <Column field="list" header="Сравниваемые выборки" sortable filter filterPlaceholder="Поиск по сравниваемым выборкам"></Column>
          </DataTable>
          <br></br>
          <DataTable value={fts.stat["Эмоциональная направленность в восприятии социальных категорий"]}
           header={<div className="table-header">Эмоциональная направленность в восприятии социальных категорий</div>}
           className="p-datatable-customers"
           paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="name" header="Сравниваемые характеристики" sortable filter filterPlaceholder="Поиск по сравниваемым характеристикам"></Column>
            <Column field="list" header="Сравниваемые выборки" sortable filter filterPlaceholder="Поиск по сравниваемым выборкам"></Column>
          </DataTable>
        </div>;
      }
    }
  }

  showDetailFiltersCS(fcs, index){
    if(fcs.status!=="finished") 
      return <p>Данные отсутствуют</p>;
    else {
      return <div key={String("detail_"+fcs.code)} className="datatable-doc-demo">
          <DataTable value={fcs.stat.values}
            header={<div className="table-header">{String("Критическое значение: "+String(fcs.stat.critical_value.toFixed(2))+", Объем: "+
              String(fcs.stat.n))}</div>}
            className="p-datatable-customers"
            paginator rows={10} emptyMessage="Записи отсутствуют" currentPageReportTemplate="Показано с {first} по {last} из {totalRecords} записей"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" rowsPerPageOptions={[10,25,50]}
          >
            <Column field="first_factor" header="Первый фактор" sortable filter filterPlaceholder="Поиск по 1-му фактору"></Column>
            <Column field="second_factor" header="Второй фактор" sortable filter filterPlaceholder="Поиск по 2-му фактору"></Column>
            <Column field="corr_value" header="Коэффициент корреляции" sortable filter filterMatchMode="gte" filterPlaceholder="Минимум"></Column>
          </DataTable>
      </div>;
    }
  }

  getParamFilterToString(filter){
    let param="Параметры: "
    if(filter.with_start_dt!==null&&filter.with_end_dt!==null) 
      param+="период=["+String(filter.with_start_dt.substr(8,2)+"."+filter.with_start_dt.substr(5,2)+"."+
        filter.with_start_dt.substr(0,4))+", "+String(filter.with_end_dt.substr(8,2)+"."+
        filter.with_end_dt.substr(5,2)+"."+filter.with_end_dt.substr(0,4))+"], "
    if(filter.with_gender!==null) 
      param+="пол="+this.props.Sex.find(t => t.value===filter.with_gender).name+", "
    if(filter.with_age!==null) 
      param+="возраст="+this.props.Ages.find(t => t.value===filter.with_age).name+", "
    if(filter.with_city_id!==null) {
      //console.log(this.props.Cities.find(t => t.id===filter.with_city_id))
      param+="насел. пункт="+this.props.Cities.find(t => t.id===filter.with_city_id).name+", "
    }
    if(filter.with_city_type_id!==null) 
      param+="тип насел. пункта="+
        this.props.CityTypes.find(t => t.id===filter.with_city_type_id).name+", "
    if(filter.with_district_id!==null) 
      param+="район="+
        this.props.Districts.find(t => t.id===filter.with_district_id).name+", "
    if(filter.with_school_type_id!==null) 
      param+="тип учеб. заведения="+
        this.props.SchoolTypes.find(t => t.id===filter.with_school_type_id).name+", "
    if(filter.with_school_id!==null) 
      param+="школа="+
        this.props.Schools.find(t => t.id===filter.with_school_id).name+", "
    if(filter.with_student_group_id!==null){ 
      param+="класс="+
        this.props.StudentGroups.find(t => t.id===filter.with_student_group_id).name+", "
    }
    param=param.substr(0,param.length-2)
    return param
  }

  getParamFilterToString2(filter){
    let param="Параметры: "
    if(filter.with_start_dt!==null&&filter.with_end_dt!==null) 
      param+="период=["+String(filter.with_start_dt.substr(8,2)+"."+filter.with_start_dt.substr(5,2)+"."+
        filter.with_start_dt.substr(0,4))+", "+String(filter.with_end_dt.substr(8,2)+"."+
        filter.with_end_dt.substr(5,2)+"."+filter.with_end_dt.substr(0,4))+"], "
    if(filter.with_alpha!==null) 
      param+="уровень значимости="+String(Number(1-filter.with_alpha).toFixed(2))+", "
    
    param=param.substr(0,param.length-2)
    return param
  }

  getParamFilterToString3(filter){
    let param="Параметры: "
    if(filter.with_start_dt!==null&&filter.with_end_dt!==null) 
      param+="период=["+String(filter.with_start_dt.substr(8,2)+"."+filter.with_start_dt.substr(5,2)+"."+
        filter.with_start_dt.substr(0,4))+", "+String(filter.with_end_dt.substr(8,2)+"."+
        filter.with_end_dt.substr(5,2)+"."+filter.with_end_dt.substr(0,4))+"], "
    if(filter.with_gender!==null) 
      param+="пол="+this.props.Sex.find(t => t.value===filter.with_gender).name+", "
    if(filter.with_age!==null) 
      param+="возраст="+this.props.Ages.find(t => t.value===filter.with_age).name+", "
    if(filter.with_city_id!==null) {
      //console.log(this.props.Cities.find(t => t.id===filter.with_city_id))
      param+="насел. пункт="+this.props.Cities.find(t => t.id===filter.with_city_id).name+", "
    }
    if(filter.with_city_type_id!==null) 
      param+="тип насел. пункта="+
        this.props.CityTypes.find(t => t.id===filter.with_city_type_id).name+", "
    if(filter.with_district_id!==null) 
      param+="район="+
        this.props.Districts.find(t => t.id===filter.with_district_id).name+", "
    if(filter.with_school_type_id!==null) 
      param+="тип учеб. заведения="+
        this.props.SchoolTypes.find(t => t.id===filter.with_school_type_id).name+", "
    if(filter.with_school_id!==null) 
      param+="школа="+
        this.props.Schools.find(t => t.id===filter.with_school_id).name+", "
    if(filter.with_student_group_id!==null){ 
      param+="класс="+
        this.props.StudentGroups.find(t => t.id===filter.with_student_group_id).name+", "
    }
    if(filter.with_alpha!==null) param+="уровень значимости="+String(Number(1-filter.with_alpha).toFixed(2))+", "

    param=param.substr(0,param.length-2)
    return param
  }

  translateFilterDSStatus(status){
    let transStatus
    switch(status){
      case "finished": 
        transStatus="выполнен";
        break;
      case "created": 
        transStatus="создан";
        break;
      case "canceled": 
        transStatus="отменен";
        break;
      case "launched": 
        transStatus="в работе";
        break;
      default:
        transStatus=status;
        break;
    }
    return transStatus
  }

  getColorFilterDSStatus(status){
    let colorStatus
    switch(status){
      case "finished": 
        colorStatus="success";
        break;
      case "created": 
        colorStatus="primary";
        break;
      case "canceled": 
        colorStatus="danger";
        break;
      case "launched": 
        colorStatus="secondary";
        break;
      default:
        colorStatus="primary";
        break;
    }
    return colorStatus
  }

  renderFiltersDS(){
    if (this.props.FiltersDS.length!==0) {
      
      return <div key="showFiltersDS">
        {this.props.FiltersDS.map((fds,index)=>{
          let paramFilter=this.getParamFilterToString(fds.filter)
          let status=this.translateFilterDSStatus(fds.status)
          let colorStatus=this.getColorFilterDSStatus(fds.status)
          let DetailFiltersDS=this.showDetailFiltersDS(fds,index)
          return (<div key={index}>
          <Button
            onClick={() => {this.props.onChangeCollapseFilterDS(fds.code,!fds.collapse)}}
            aria-controls={fds.code}
            aria-expanded={fds.collapse}
            block
            variant={colorStatus}//"success"
            >
            {String("Фильтр № ")+String(index+1)+". Статус: "+status+". "+paramFilter}
            {/*<span style={{color: "maroon", fontWeight: "bold"}}>{word.name}</span>*/}
          </Button>
          <br></br>
          <Collapse in={fds.collapse}>
            <div key={fds.code} id={fds.code}>
              {DetailFiltersDS}
            </div>
          </Collapse>
          <br></br>
          </div>);
        })
        }
      </div>;
      }
      else return null;
  }

  renderFiltersTS(){
    if (this.props.FiltersTS.length!==0) {
      
      return <div key="showFiltersTS">
        {this.props.FiltersTS.map((fts,index)=>{
          let paramFilter=this.getParamFilterToString2(fts.filter)
          let status=this.translateFilterDSStatus(fts.status)
          let colorStatus=this.getColorFilterDSStatus(fts.status)
          let DetailFiltersTS=this.showDetailFiltersTS(fts,index)
          return (<div key={index}>
          <Button
            onClick={() => {this.props.onChangeCollapseFilterTS(fts.code,!fts.collapse)}}
            aria-controls={fts.code}
            aria-expanded={fts.collapse}
            block
            variant={colorStatus}//"success"
            >
            {String("Фильтр № ")+String(index+1)+". Статус: "+status+". "+paramFilter}
            {/*<span style={{color: "maroon", fontWeight: "bold"}}>{word.name}</span>*/}
          </Button>
          <br></br>
          <Collapse in={fts.collapse}>
            <div key={fts.code} id={fts.code}>
              {DetailFiltersTS}
            </div>
          </Collapse>
          <br></br>
          </div>);
        })
        }
      </div>;
      }
      else return null;
  }

  renderFiltersCS(){
    if (this.props.FiltersCS.length!==0) {
      
      return <div key="showFiltersCS">
        {this.props.FiltersCS.map((fcs,index)=>{
          let paramFilter=this.getParamFilterToString3(fcs.filter)
          let status=this.translateFilterDSStatus(fcs.status)
          let colorStatus=this.getColorFilterDSStatus(fcs.status)
          let DetailFiltersCS=this.showDetailFiltersCS(fcs,index)
          return (<div key={index}>
          <Button
            onClick={() => {this.props.onChangeCollapseFilterCS(fcs.code,!fcs.collapse)}}
            aria-controls={fcs.code}
            aria-expanded={fcs.collapse}
            block
            variant={colorStatus}
            >
            {String("Фильтр № ")+String(index+1)+". Статус: "+status+". "+paramFilter}
          </Button>
          <br></br>
          <Collapse in={fcs.collapse}>
            {/*<div key={fcs.code} id={fcs.code}>
              {DetailFiltersCS}
          </div>*/}
            <Container key={fcs.code} id={fcs.code}>
              {DetailFiltersCS}
            </Container>
          </Collapse>
          <br></br>
          </div>);
        })
        }
      </div>;
      }
      else return null;
  }


  render() {
    let FiltersDS=this.renderFiltersDS()
    let FiltersCS=this.renderFiltersCS()
    let FiltersTS=this.renderFiltersTS()
    return <div key="stat">
      <ActionCableProvider 
        cable={cable}
      >
      <Container>
        <Row><Col>
          <Alert variant="primary">
            <Alert.Heading>I. Описательная статистика</Alert.Heading>
          </Alert>
          {<ActionCable
            //channel={{channel:'DescriptiveStatChannel'}}
            channel={'DescriptiveStatChannel'}
            onReceived={this.handleReceived}
           />
           }
        </Col></Row>
        {this.renderFilter()}
        <Row><Col>
          <Alert variant="primary">
            <p>Результаты описательной статистики</p>
          </Alert>
        </Col></Row>
        <Row><Col>
          {FiltersDS}
        </Col></Row>
        <Row><Col>
          <Alert variant="primary">
            <Alert.Heading>II. Межгрупповые различия на основе критерия согласия Колмогорова-Смирнова по алтернативной гипотезе. </Alert.Heading>
          </Alert>
          {<ActionCable
            channel={'TestStatChannel'}
            onReceived={this.handleReceived2}
           />
           }
        </Col></Row>
        {this.renderFilter2()}
        <Row><Col>
          <Alert variant="primary">
            <p>Результаты статистики межгрупповых различий</p>
          </Alert>
        </Col></Row>
        <Row><Col>
          {FiltersTS}
        </Col></Row>
        <Row><Col>
          <Alert variant="primary">
            <Alert.Heading>III. Корреляционная статистика. Критерий Пирсона. </Alert.Heading>
          </Alert>
          {<ActionCable
            channel={'CorrStatChannel'}
            onReceived={this.handleReceived3}
           />
           }
        </Col></Row>
        {this.renderFilter3()}
        <Row><Col>
          <Alert variant="primary">
            <p>Результаты корреляционной статистики</p>
          </Alert>
        </Col></Row>
        <Row><Col>
          {FiltersCS}
        </Col></Row>
      </Container>
      </ActionCableProvider>
    </div>;
    }
  }
  
  
  export default Stat
