import {connect} from 'react-redux'
import Home from '../components/Home'


export const NewHome = connect (
  
  state=>({
    Roles : state.Roles,
    Сharacteristics : state.Сharacteristics,
    CurrentZIndex : state.CurrentZIndex,
    Schools: state.Schools,
    Cities: state.Cities,
    CurrentCityId:  state.CurrentCityId,
    CurrentSchoolId:  state.CurrentSchoolId,
    CurrentStudentGroupId: state.CurrentStudentGroupId,
    tempSchools: state.tempSchools,
    StudentGroups: state.StudentGroups,
    tempStudentGroups: state.tempStudentGroups,
    Students: state.Students,
    tempStudents: state.tempStudents,
    PVG: state.PVG,
    Positivity: state.Positivity,
    Sex: state.Sex,
    StartFlag: state.StartFlag,
    StudentTest: state.StudentTest,
    Persons: state.Persons,
    CurrentPersonId: state.CurrentPersonId,
    User: state.User,
    Tests: state.Tests,
    PersonsRoles: state.PersonsRoles,
    PersonsChar: state.PersonsChar,
    ActivePagination: state.ActivePagination,
    ActiveTabPane: state.ActiveTabPane,
    Users: state.Users,
    ShowModalFormsAdmin: state.ShowModalFormsAdmin,

  }),
  dispatch => ({

    /*async onGetFetchUsers(url) {
      let json,users
      users= []
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        users= await response.json()
        users=users.filter(t=>t.type!=="StudentUser")
        dispatch({type : 'CRUD_FETCH_USERS', Users: users})
        return 1
      }
    },*/

    /*nPostFetchUsers(url,data) {
      let newUsers,i
      newUsers= []
      fetch(url, {
        method: 'POST',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          return -1
        } else {
        response.json().then(user=> {
        for(i=0;i<this.Users.length;i++)newUsers.push(this.Users[i])
        newUsers.push(user)
        dispatch({type : 'CRUD_FETCH_USERS', Users: newUsers})
        return 1
      })
      }
      })
    },*/

    /*onUpdateFetchUsers(url,data) {
      let newUsers,i
      newUsers= []
      fetch(url, {
        method: 'PUT',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
        },
        body: JSON.stringify(data),
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          return -1
        } else {
        response.json().then(user=> {
        for(i=0;i<this.Users.length;i++) {
          newUsers.push(this.Users[i])
          if(newUsers[i].id===user.id){
            newUsers[i].login=user.login
            newUsers[i].type=user.type
            if(user.type==="SchoolPsychologist"||user.type==="SchoolAdmin")
              newUsers[i].school_id=user.school_id
          }
        }
        dispatch({type : 'CRUD_FETCH_USERS', Users: newUsers})
        return 1
      })
      }
      })
    },*/

   /*onDeleteFetchUsers(url,user_id) {
      let newUsers,i
      newUsers= []
      fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        credentials: 'same-origin',
        headers: {'Content-Type': 'application/json',
        'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      .then(response=> {
        if(!response.ok) {
          response.json().then(response=>{alert(response.errors.join("\n"));})
          return -1
        } 
        else {
          for (i=0;i<this.Users.length;i++) newUsers.push(this.Users[i])
          newUsers=newUsers.filter(t => t.id !==user_id)
          dispatch({type : 'CRUD_FETCH_USERS', Users: newUsers})
          return 1
        }
      })
    },*/

    /*onShowModalFormsAdmin(add,edit,del,type,id) {
      dispatch({type : 'SHOW_MODAL_FORMS_ADMIN', ShowModalFormsAdmin: 
        {add:add,edit:edit,del:del,type:type,id:id}})
    },*/

    /*async onGetFetchCities(url) {
      let newCities=[]
      let json,cities,i
      let response=await fetch(url, {
        headers: {'Content-Type': 'application/json',
          'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,
           },
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        cities= await response.json()
        for(i=0;i<cities.length;i++)
          newCities.push(cities[i])
        dispatch({type : 'GET_FETCH_CITIES', Cities: newCities})
        return Promise.resolve(newCities)
      }
    },*/

    /*async onGetFetchSchools(url,cities) {
      let newSchools=[], tempSchools=[], i
      let schools, json 
      let response= await fetch(url,{
        headers: {'access-token':this.User.accessToken,'uid':this.User.uid,'client':this.User.client,},
      })
      if(!response.ok) {
        json= await response.json()
        alert(json.errors.join("\n"))
        return -1
      }
      else {
        schools= await response.json()
        for(i=0;i<schools.length;i++)
        {
          newSchools.push(schools[i])
          tempSchools.push({id:schools[i].id,name:schools[i].name,city_id:schools[i].city_id,
            city:cities.find(t=>t.id===schools[i].city_id).name})
        }
        tempSchools.sort((a,b)=>{
          if (a.city>b.city) return 1;
          if (a.city===b.city) return 0;
          if (a.city<b.city) return -1;
        })
        dispatch({type : 'GET_FETCH_SCHOOLS', Schools: newSchools,tempSchools:tempSchools})
     
        return 1
      }
    },*/

    /*async onInitialData(url1,url2){
        let cities=await this.onGetFetchCities(url1)
        await this.onGetFetchSchools(url2,cities)
    },*/
   
  })
) (Home)
